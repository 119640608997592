/* eslint-disable quote-props */
import { makeStyles, Theme } from '@material-ui/core';
import { Gutters } from '../../../styles/gutters';

export const HeaderHeight = '80px';

export const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    boxShadow:
      '0px 4px 10px rgba(0, 0, 0, 0.15), inset 0px 0px 0px rgba(0, 0, 0, 0.1)',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  headerBar: {
    ...Gutters(theme),
    minWidth: '960px',
    maxWidth: '1500px',
    marginRight: 'auto',
    marginLeft: 'auto',
    height: HeaderHeight,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: 'none',
  },
  tabContainer: {
    height: '100%',
    display: 'flex',
  },
  headerTabText: {
    color: theme.palette.text.primary,
    fontSize: '14px',
  },
  headerTab: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '5px',
    marginRight: '16px',
    paddingLeft: '5px',
    paddingTop: '4px',
    borderBottom: `4px solid transparent`,
  },
  activeHeaderTab: {
    borderBottom: `4px solid ${theme.palette.primary.main}`,
  },
  logo: {
    height: '72%',
    paddingRight: '47px',
  },
  logoLink: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  signIn: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightBold,
  },
  userMenuArrowIcon: {
    verticalAlign: 'bottom',
  },
  userMenucontainer: {
    width: '270px',
    // paddingTop: '32px',
    // paddingBottom: '32px',
    // paddingLeft: '32px',
    // paddingRight: '32px',
    boxShadow:
      '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)',
    borderRadius: '2px',
  },
  userMenuMargin: {
    marginTop: '9px',
  },
}));
