import { StyleSheet } from '@react-pdf/renderer';
import { pixelsToPoints } from './helpers';
import * as Colors from '@pxblue/colors';

export const makeClasses = () => StyleSheet.create({
    typography: {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      color: Colors.gray[800],
      fontSize: pixelsToPoints(16),
    },
    title: {
      marginTop: pixelsToPoints(32),
      marginBottom: pixelsToPoints(32),
    },
    containerTopChoice: {
      borderColor: Colors.black[300],
      borderWidth: pixelsToPoints(3),
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      borderRadius: 3,
      width: pixelsToPoints(1170),
      marginBottom: pixelsToPoints(32),
    },
    containerRunnerUp: {
      borderColor: Colors.gray[200],
      borderWidth: pixelsToPoints(1),
    },
    image: {
      width: pixelsToPoints(370),
      margin: 'auto',
    },
    imageNoTransmission: {
      width: pixelsToPoints(370),
      margin: 'auto',
      paddingTop: pixelsToPoints(32),
      paddingBottom: pixelsToPoints(32),
    },
    imageContainer: {
      height: '100%',
      flexDirection: 'column',
    },
    logo: {
      width: pixelsToPoints(311),
    },
    pageMargins: {
      paddingRight: pixelsToPoints(135),
      paddingLeft: pixelsToPoints(135),
      paddingTop: pixelsToPoints(67),
      paddingBottom: pixelsToPoints(96),
    },
    contentContainer: {
      paddingTop: pixelsToPoints(32),
      paddingBottom: pixelsToPoints(32),
      paddingLeft: pixelsToPoints(28),
      paddingRight: pixelsToPoints(100),
      width: pixelsToPoints(800),
    },
    transmissionTitle: {
      fontWeight: 600,
      fontSize: pixelsToPoints(24),
      lineHeight: 1.5,
      color: Colors.black[600],
      marginBottom: pixelsToPoints(16),
    },
    matchType: {
      fontWeight: 700,
      fontSize: pixelsToPoints(10),
      lineHeight: 1.75,
      letterSpacing: pixelsToPoints(1),
      textTransform: 'uppercase',
      color: Colors.gray[800],
    },
    learnMore: {
      marginTop: pixelsToPoints(32),
    },
    hr: {
      borderWidth: 0.5,
      height: 0,
      width: pixelsToPoints(1170),
      borderColor: Colors.gray[100],
      marginTop: pixelsToPoints(25),
    },
    header: {
      display: 'flex',
      width: pixelsToPoints(1170),
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    fileDetails: {
      display: 'flex',
      flexDirection: 'row',
    },
    fileDetail: {
      fontSize: pixelsToPoints(14),
      color: Colors.black[600],
      paddingLeft: pixelsToPoints(16),
    },
    responsesContainer: {
      display: 'flex',
      flexDirection: 'row',
      borderRadius: 3,
      width: pixelsToPoints(1170),
      marginBottom: pixelsToPoints(32),
      borderColor: Colors.gray[200],
      borderWidth: pixelsToPoints(1),
    },
    responsesContentContainer: {
      paddingTop: pixelsToPoints(32),
      paddingLeft: pixelsToPoints(52),
    },
    responsesTitle: {
      fontWeight: 600,
      fontSize: pixelsToPoints(18),
      color: Colors.black[600],
      paddingBottom: pixelsToPoints(16),
    },
    responsesDetails: {
      display: 'flex',
      flexDirection: 'row',
    },
    responsesColumn: {
      paddingRight: pixelsToPoints(32),
      width: pixelsToPoints(280),
    },
    detailHeader: {
      fontWeight: 600,
      color: Colors.black[600],
      paddingBottom: pixelsToPoints(8),
    },
    detailBody: {
      paddingBottom: pixelsToPoints(32),
    },
    detailListItem: {
      paddingBottom: pixelsToPoints(8),
    },
    inRowSurveyItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      paddingBottom: pixelsToPoints(8),
    },
    footerContainer: {
      borderRadius: 3,
      width: pixelsToPoints(770),
      borderColor: Colors.gray[200],
      borderWidth: pixelsToPoints(1),
      paddingBottom: pixelsToPoints(32),
      paddingTop: pixelsToPoints(32),
      paddingLeft: pixelsToPoints(100),
      paddingRight: pixelsToPoints(100),
      alignSelf: 'center',
      textAlign: 'center',
    },
    footerText: {
      paddingBottom: pixelsToPoints(16),
    },
    specTitleView: {
      paddingTop: '32px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
      paddingBottom: '32px',
    },
    specTitleSmallText: {
      fontWeight: 600,
      color: Colors.black[700],
      fontSize: pixelsToPoints(18),
      paddingRight: pixelsToPoints(8),
      paddingBottom: pixelsToPoints(4),
    },
    specTitleLargeText: {
      fontWeight: 600,
      color: Colors.black[700],
      fontSize: pixelsToPoints(32),
    },
    notesContainer: {
      width: pixelsToPoints(737),
      color: Colors.black[700],
      paddingTop: pixelsToPoints(8),
      paddingBottom: pixelsToPoints(32),
      display: 'flex',
      flexDirection: 'column',
    },
  });
