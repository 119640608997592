import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    subtitle: {
      marginTop: '32px',
    },
    image: {
      marginTop: '10px',
    },
    description: {
      marginTop: '4px',
    },
    vehicleType: {
      marginTop: '10px',
      marginBottom: '50px',
    },
    table: {
      width: '100%',
    },
    tableData: {
      width: '100px',
    },
  };
});
