import React, { useContext, useState, useEffect } from 'react';
import { EatonLinkButton, EatonButton } from '../button';
import { PDFGenerator } from '../pdf-generator';
import {
  TransmissionDisplay,
  MatchType,
  TransmissionResult,
} from '../transmission-result';
import { SavedSurveyResult } from '../../domain/saved-result';
import { useStyles } from './styles';
import { Survey } from '../../domain/survey';
import { UnitContext } from '../unit-switcher';
import { debounce } from 'lodash';
import { DefaultPage } from '../default-page';
import {
  Typography,
  FormControl,
  Input,
  InputAdornment,
} from '@material-ui/core';
import { SurveyResponses, Drawer } from '../survey-responses';
import { RichTextEditor } from '../rich-text-editor';
import { PureSurveyFooter } from '../pure-survey-footer';
import { PencilIcon } from '../../icons/pencil-icon';
import { useAuthDispatch } from '../reusable-components/components/AuthContext';

type TransmissionResult = {
  topChoice: TransmissionDisplay;
  runnerUp: TransmissionDisplay;
};

export const SavedSpecView = (props: {
  savedSpec: SavedSurveyResult;
  _inTest?: boolean;
}) => {
  const classes = useStyles();
  const unitContext = useContext(UnitContext);
  const { authenticatedRequest } = useAuthDispatch();

  const result: TransmissionResult = JSON.parse(props.savedSpec.result);
  const survey: Survey = JSON.parse(props.savedSpec.survey);

  const [originalFields, setOriginalFields] = useState<{
    title: string;
    notes: string | undefined;
  }>({ title: props.savedSpec.title, notes: props.savedSpec.notes });

  const [specTitle, setSpecTitle] = useState<string>(props.savedSpec.title);
  const [pdfTitle, setPdfTitle] = useState<string>(specTitle);
  const debounceTitle = debounce(setPdfTitle, 2000, { trailing: true });

  const [specNotes, setSpecNotes] = useState<string>(
    props.savedSpec.notes || '',
  );
  const [pdfNotes, setPdfNotes] = useState<string>(specNotes);
  const debounceNotes = debounce(setPdfNotes, 2000, { trailing: true });

  const [pdfLoading, setPdfLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<boolean>(false);

  const canSave =
    (specTitle !== originalFields.title ||
      specNotes !== originalFields.notes) &&
    specTitle.trim().length > 0;

  useEffect(() => {
    if (pdfNotes === specNotes && pdfTitle === specTitle) {
      setPdfLoading(false);
    } else {
      setPdfLoading(true);
    }
  }, [specNotes, specTitle, pdfTitle, pdfNotes]);

  const saveSpec = async () => {
    setSaving(true);
    try {
      const response = await authenticatedRequest<SavedSurveyResult>({
        method: 'PUT',
        url: `/api/surveyresult/${props.savedSpec.id}`,
        data: {
          notes: specNotes,
          title: specTitle,
        },
      });
      if (response.status < 300) {
        setSaving(false);
        setOriginalFields({ title: specTitle, notes: specNotes });
      } else {
        setSaving(false);
        setSubmitError(true);
      }
    } catch {
      setSaving(false);
      setSubmitError(true);
    }
  };

  const footerLhs = (
    <EatonLinkButton
      variant="outlined"
      color="secondary"
      to="/spec"
      data-testid="cancel-button"
    >
      Cancel
    </EatonLinkButton>
  );
  const footerRhs = (
    <>
      <EatonButton className={classes.pdfButton}>
        <a
          href="https://www.eatoncummins.com/us/en-us/products/transmissions.html"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.transmissionButtonLink}
        >
          View Transmission Portfolio
        </a>
      </EatonButton>
      {!props._inTest && (
        <PDFGenerator
          surveyDate={new Date(props.savedSpec.createdAtUTC)}
          survey={survey}
          topChoice={result.topChoice}
          runnerUp={result.runnerUp}
          className={classes.pdfButton}
          unit={unitContext.units}
          title={pdfTitle}
          notes={pdfNotes}
          loading={pdfLoading || saving}
        />
      )}
      <EatonButton
        onClick={saveSpec}
        loadingSpinner={saving}
        disabled={saving || !canSave}
        data-testid="save-spec-finish"
      >
        Save
      </EatonButton>
    </>
  );

  return (
    <div>
      <DefaultPage>
        <div>
          <div>
            <Typography variant="h1">
              <FormControl className={classes.saveSpecTitleField}>
                <Input
                  id="spec-title"
                  data-testid="spec-title"
                  value={specTitle}
                  onChange={(event) => {
                    setSpecTitle(event.target.value);
                    debounceTitle(event.target.value);
                  }}
                  onBlur={(event) => {
                    setSpecTitle(event.target.value.trim());
                    debounceTitle(event.target.value.trim());
                  }}
                  classes={{ input: classes.inputText }}
                  endAdornment={
                    <InputAdornment position="end">
                      <PencilIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Typography>
            <Typography variant="h3" className={classes.saveSpecSubHeader}>
              {result.topChoice && result.runnerUp
                ? 'Transmission Recommendations'
                : 'Transmission Recommendation'}
            </Typography>
          </div>
          {result.topChoice && (
            <div className={classes.transmissionResult}>
              <TransmissionResult
                matchType={MatchType.TopMatch}
                transmission={result.topChoice}
              />
            </div>
          )}
          {result.runnerUp && (
            <div className={classes.transmissionResult}>
              <Drawer header="Runner Up" startExpanded={false}>
                <TransmissionResult
                  matchType={MatchType.RunnerUp}
                  transmission={result.runnerUp}
                  hideBorder={true}
                />
              </Drawer>
            </div>
          )}
          <div className={classes.surveyResponses}>
            {
              <SurveyResponses
                survey={survey}
                unit={unitContext.units}
                startExpanded={false}
              />
            }
          </div>
        </div>
        <RichTextEditor
          data-testid="spec-notes-field"
          className={classes.saveSpecRichTextEditorContainer}
          editorClassName={classes.saveSpecRichTextEditorEditorField}
          value={specNotes}
          onChange={(value) => {
            setSpecNotes(value);
            debounceNotes(value);
          }}
        />
        {submitError && (
          <div className={classes.errorText}>
            <Typography>
              An error occured during the save. Please try again.
            </Typography>
          </div>
        )}
      </DefaultPage>
      <PureSurveyFooter lhs={footerLhs} rhs={footerRhs} />
    </div>
  );
};
