import React from 'react';

export const HeroBg: React.SFC = () => (
  <svg
    // width="100%"
    // height="200"
    viewBox="0 0 1460 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMinYMin meet"
  >
    <g filter="url(#filter0_di)">
      <path
        d="M 0,0
           L 1460, 0
           V 90
           Z"
        fill="url(#paint0_linear)"
      />
    </g>
    <defs>
      <filter
        id="filter0_di"
        x="0"
        y="0"
        width="1460"
        height="100"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.835294 0 0 0 0 0.847059 0 0 0 0 0.854902 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow" />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="0"
        y1="0"
        x2="1460"
        y2="100"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D5D8DA" />
        <stop offset="1" stopColor="#F7F8F8" />
      </linearGradient>
    </defs>
  </svg>
);
