import React from 'react';
import { Typography } from '@material-ui/core';
import { EatonLinkButton } from '../button';
import { useStyles } from './styles';
import { HeroBg } from './hero-bg';

export const AppSplash = () => {
  const classes = useStyles();
  const heroHeaderText = 'Spec the right transmission.';
  const heroSubtitleText = `Each fleet has its own set of goals and challenges,
    and the right transmission spec can make a big difference. Eaton Cummins
    Automated Transmission Technologies has a broad portfolio of transmissions
    to help you get the job done. By answering a short series of questions about
    your truck and your route, we'll give you a couple of recommendations for 
    heavy-duty automated transmissions that best fit your fleet's unique needs.`;
  return (
    <div className={classes.mainSplashContainer}>
      <div
        style={{
          background: 'linear-gradient(94.49deg, #D5D8DA 1.63%, #F7F8F8 100%)',
        }}
      >
        <div className={classes.heroContentContainer}>
          <div className={classes.heroImageContainer}>
            <img
              src="../../../../../enduranthero.png"
              alt="Ultrashift Plus Transmission"
            />
          </div>
          <div className={classes.heroTextContainer}>
            <Typography variant="h1" className={classes.heroHeader}>
              {heroHeaderText}
            </Typography>
            <Typography variant="subtitle1" className={classes.heroSubtitle}>
              {heroSubtitleText}
            </Typography>
            <EatonLinkButton to="/survey" data-testid="create-spec-button">
              Create Spec
            </EatonLinkButton>
          </div>
        </div>
      </div>
      <HeroBg />
    </div>
  );
};
