import React from 'react';
import { EatonLinkButton } from '../../components/button';
import { Typography } from '@material-ui/core';

export const ErrorPage = () => {
  return (
    <div style={{ marginTop: '100px', textAlign: 'center' }}>
      <Typography variant="h2" style={{ marginBottom: '32px' }}>
        Oops! Something went wrong.
      </Typography>
      <EatonLinkButton to="/">
        Take me back home
      </EatonLinkButton>
    </div>
  );
};
