import { makeStyles, Theme } from '@material-ui/core';
import * as Colors from '@pxblue/colors';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    containerTopChoice: {
      display: 'flex',
      // width: '1170px',
      height: '100%',
      background: Colors.white[200],
      boxSizing: 'border-box',
      boxShadow: '0px 1px 10px rgba(77, 163, 212, 0.5)',
      border: `1px solid ${theme.palette.grey[100]}`,
      borderRadius: '2px',
    },
    containerRunnerUp: {
      display: 'flex',
      // width: '1170px',
      height: '100%',
      background: theme.palette.common.white,
      boxSizing: 'border-box',
      boxShadow: 'none',
      borderRadius: '2px',
    },
    runnerUpBorderBox: {
      border: `1px solid ${theme.palette.grey[100]}`,
    },
    runnerUpBorderRight: {
      borderRight: `1px solid ${theme.palette.grey[100]}`,
    },
    imageContainerTopChoice: {
      flex: 'none',
      background: theme.palette.common.white,
      width: '370px',
      height: 'auto',
      position: 'relative',
    },
    imageContainerRunnerUp: {
      flex: 'none',
      background: theme.palette.common.white,
      width: '370px',
      height: 'auto',
      position: 'relative',
    },
    image: {
      maxWidth: '100%',
      maxHeight: '100%',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: 'auto',
    },
    imagePadding: {
      paddingLeft: '42px',
      paddingRight: '42px',
      paddingTop: '21px',
      paddingBottom: '21px',
    },
    contentContainer: {
      width: '100%',
      height: '100%',
      paddingRight: '100px',
      marginLeft: '30px',
      marginTop: '32px',
      wordBreak: 'break-word',
    },
    contentLabel: {
      marginBottom: '6px',
    },
    contentName: {
      marginBottom: '16px',
    },
    contentDescription: {
      marginBottom: '32px',
      overflow: 'hidden',
    },
    contentButton: {
      height: '44px',
      marginBottom: '32px',
    },
  };
});
