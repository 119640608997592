import { makeStyles, Theme } from '@material-ui/core';
import * as Colors from '@pxblue/colors';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    tableContainer: { textAlign: 'center' },
    loading: {
      marginTop: '32px',
      width: '100%',
      alignContent: 'center',
    },
    nameCell: {
      width: '50%',
    },
    transmissionCell: {
      width: '30%',
    },
    editedCell: {
      minWidth: '200px',
    },
    row: {
      '&:hover': { background: '#F0F9FF' },
    },
    specCell: {
      maxWidth: '0',
      whiteSpace: 'nowrap',
      padding: '0',
    },
    specCellLink: {
      textDecoration: 'none',
      color: theme.palette.text.primary,
    },
    specCellContents: {
      height: '100%',
      width: '100%',
      padding: '16px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    editedCellContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    chevron: {
      marginTop: '-8px',
      marginBottom: '-8px',
      marginRight: '-8px',
    },
    headerCell: {
      paddingBottom: '0',
      paddingRight: '16px',
      paddingLeft: '16px',
      whiteSpace: 'nowrap',
    },
    root: {
      width: '100%',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    forwardBackIcon: {
      background: Colors.blue[50],
      padding: '2px',
      marginLeft: '24px',
    },
    actionButton: {
      marginTop: '32px',
    },
    noSpecs: {
      paddingTop: '32px',
      textAlign: 'left',
    },
  };
});
