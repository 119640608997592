import React, { useState, useEffect } from 'react';
import { Terrain } from '../../../domain/survey';
import {
  FormControl,
  FormLabel,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { useStyles } from './styles';
import { TerrainToLabelMappings } from '../../../domain/image-mappings';
import { CheckboxQuestion } from '../checkbox-question';
import { cssClassName } from '..';
import { ImageOption } from '../image-question';

type Props = {
  terrains: Terrain[] | null;
  setValue: (value: Terrain[] | null) => void;
};

type State = {
  flat: boolean;
  hilly: boolean;
  mountainPass: boolean;
  mountainSelection:
    | Terrain.FrequentMountain
    | Terrain.OccasionalMountain
    | null;
  steepGrade: boolean;
  steepGradeConfirmed: boolean;
};

export const TerrainQuestion = (props: {
  terrains: Terrain[] | null;
  setValue: (terrains: Terrain[] | null) => void;
}) => {
  const classes = useStyles();

  const [state, setState] = useState<State>({
    flat: !!props.terrains?.includes(Terrain.Flat),
    hilly: !!props.terrains?.includes(Terrain.HillyRolling),
    mountainPass: !!(
      props.terrains?.includes(Terrain.OccasionalMountain) ||
      props.terrains?.includes(Terrain.FrequentMountain)
    ),
    mountainSelection: props.terrains?.includes(Terrain.OccasionalMountain)
      ? Terrain.OccasionalMountain
      : props.terrains?.includes(Terrain.FrequentMountain)
      ? Terrain.FrequentMountain
      : null,
    steepGrade: !!props.terrains?.includes(Terrain.SteepGrade),
    steepGradeConfirmed: !!props.terrains?.includes(Terrain.SteepGrade),
  });

  useEffect(() => {
    let terrains: Terrain[] = [];
    if (state.flat) terrains = terrains.concat(Terrain.Flat);
    if (state.hilly) terrains = terrains.concat(Terrain.HillyRolling);
    if (state.mountainSelection !== null) {
      terrains = terrains.concat(state.mountainSelection);
    }
    if (state.steepGrade) terrains = terrains.concat(Terrain.SteepGrade);

    // If the constraints are met, set the survey values
    if (
      terrains.length !== 0 &&
      (!state.mountainPass || state.mountainSelection !== null) &&
      (!state.steepGrade || state.steepGradeConfirmed)
    ) {
      props.setValue(terrains);
    } else {
      props.setValue(null);
    }
    // Otherwise, set survey values to null
  }, [state]);

  const mountainPassQuestion = (
    <div className={classes.confirmationDialogContainer}>
      <Typography variant="subtitle2" className={classes.subtitle}>
        Mountain Pass
      </Typography>
      <Typography variant="h3" className={classes.mountainDialogHeader}>
        Tell us more about your intended mountain pass driving.
      </Typography>
      <RadioGroup
        value={state.mountainSelection || ''}
        onChange={(event, value) => {
          setState({
            ...state,
            mountainSelection: value as
              | Terrain.FrequentMountain
              | Terrain.OccasionalMountain,
          });
        }}
      >
        <FormControlLabel
          data-testid={cssClassName(Terrain.OccasionalMountain)}
          className={`${cssClassName(Terrain.OccasionalMountain)} ${
            classes.mountainOption
          }`}
          value={Terrain.OccasionalMountain}
          control={<Radio />}
          label={
            <div className={classes.mountainLabel}>
              Occasional driving in the mountains at 6% or smaller grades.
              <br />
              <i>
                Driving on mountain grades is not generally a common route for
                this vehicle.
              </i>
            </div>
          }
        />
        <FormControlLabel
          data-testid={cssClassName(Terrain.FrequentMountain)}
          className={`${cssClassName(Terrain.FrequentMountain)} ${
            classes.mountainOption
          }`}
          value={Terrain.FrequentMountain}
          control={<Radio />}
          label={
            <div className={classes.mountainLabel}>
              Frequent driving in the mountains at 6% or larger grades.
              <br />
              <i>
                Driving routes on mountain grades happens regularly for this
                vehicle.
              </i>
            </div>
          }
        />
      </RadioGroup>
    </div>
  );

  const steepGradeConfirmation = (
    <div className={classes.confirmationDialogContainer}>
      <Typography variant="subtitle2" className={classes.subtitle}>
        Steep Grade
      </Typography>
      <Typography variant="h3" className={classes.confirmationDialogHeader}>
        Confirm your use case.
      </Typography>
      <Typography className={classes.confirmationDialogBody}>
        {`Your selection indicates the intended vehicle will be operating on a grade of 20% or larger. This is
 a steep grade and will greatly limit your results.`}
      </Typography>
      <Typography className={classes.confirmationDialogBodyBold}>
        Confirm your use case has reasonable alignment to this description.
      </Typography>

      <FormControlLabel
        data-testid={'confirm-high-grade'}
        className={'confirm-high-grade'}
        value={Terrain.SteepGrade}
        checked={state.steepGradeConfirmed}
        control={<Checkbox />}
        label={'My use case aligns to the description above.'}
        onChange={(event, checked) => {
          setState({ ...state, steepGradeConfirmed: checked });
        }}
      />
    </div>
  );

  return (
    <>
      <Typography className={classes.contentLabel}>
        Select all that apply.
      </Typography>
      <div className={classes.terrainOptionContainer}>
        <ImageOption
          title={TerrainToLabelMappings[Terrain.Flat].title}
          icon={TerrainToLabelMappings[Terrain.Flat].icon}
          subtitle={TerrainToLabelMappings[Terrain.Flat].subtitle}
          checked={state.flat}
          onClick={() => {
            setState({ ...state, flat: !state.flat });
          }}
        />

        <ImageOption
          title={TerrainToLabelMappings[Terrain.HillyRolling].title}
          icon={TerrainToLabelMappings[Terrain.HillyRolling].icon}
          subtitle={TerrainToLabelMappings[Terrain.HillyRolling].subtitle}
          checked={state.hilly}
          onClick={() => {
            setState({ ...state, hilly: !state.hilly });
          }}
        />
        <ImageOption
          title="Mountain Pass"
          icon={TerrainToLabelMappings[Terrain.OccasionalMountain].icon}
          subtitle="over 6% grade"
          checked={state.mountainPass}
          onClick={() => {
            if (!state.mountainPass) {
              setState({
                ...state,
                mountainSelection: null,
                mountainPass: true,
              });
            } else {
              setState({
                ...state,
                mountainPass: false,
                mountainSelection: null,
              });
            }
          }}
        />
        <ImageOption
          title={TerrainToLabelMappings[Terrain.SteepGrade].title}
          icon={TerrainToLabelMappings[Terrain.SteepGrade].icon}
          subtitle={TerrainToLabelMappings[Terrain.SteepGrade].subtitle}
          checked={state.steepGrade}
          onClick={() => {
            if (!state.steepGrade) {
              setState({
                ...state,
                steepGrade: true,
                steepGradeConfirmed: false,
              });
            } else {
              setState({
                ...state,
                steepGrade: false,
                steepGradeConfirmed: false,
              });
            }
          }}
        />
      </div>
      {state.mountainPass && mountainPassQuestion}

      {state.steepGrade && steepGradeConfirmation}
    </>
  );
};
