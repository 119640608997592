import React, { useState } from 'react';
import { NullableSurvey } from '..';
import {
  PreferenceRanking,
  PreferenceRankingToLabelMappings,
} from '../../../domain/survey';
import { filter, sortBy, isNull, pickBy, times } from 'lodash';
import {
  DropResult,
  DraggableLocation,
  DragDropContext,
  Droppable,
  DroppableProvided,
  Draggable,
  DraggableProvided,
} from 'react-beautiful-dnd';
import { Avatar, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { DragHandle, LeftArrow } from './icons';

export function DragAndDropQuestion(props: {
  name: string;
  surveyValues: NullableSurvey<PreferenceRanking>;
  setSurveyValues: (value: NullableSurvey<PreferenceRanking>) => void;
  optionsTitle: string;
  rankingTitle: string;
  disabled: boolean;
}) {
  const [starterList, setStarterList] = useState<
    Array<keyof PreferenceRanking>
  >(
    Object.keys(pickBy(props.surveyValues, isNull)) as Array<
      keyof PreferenceRanking
    >,
  );

  const applySurveyValues = (values: Array<keyof PreferenceRanking>) => {
    props.setSurveyValues({
      acceleration:
        values.indexOf('acceleration') === -1
          ? null
          : values.indexOf('acceleration') + 1,
      fuelEconomy:
        values.indexOf('fuelEconomy') === -1
          ? null
          : values.indexOf('fuelEconomy') + 1,
      lowSpeedManeuvers:
        values.indexOf('lowSpeedManeuvers') === -1
          ? null
          : values.indexOf('lowSpeedManeuvers') + 1,
      price:
        values.indexOf('price') === -1 ? null : values.indexOf('price') + 1,
      tripTime:
        values.indexOf('tripTime') === -1
          ? null
          : values.indexOf('tripTime') + 1,
    });
  };

  const surveyValues = filter(
    sortBy(Object.entries(props.surveyValues), ([key, value]) => value),
    ([key, value]) => value !== null,
  ).map(([key, value]) => key) as Array<keyof PreferenceRanking>;

  const startColumnId = 'start-column';
  const finalColumnId = 'final-column';

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === finalColumnId) {
        const items = reorder(surveyValues, source.index, destination.index);
        applySurveyValues(items);
      } else {
        const items = reorder(starterList, source.index, destination.index);
        setStarterList(items);
      }
    } else {
      if (source.droppableId === finalColumnId) {
        const [sourceResult, destinationResult] = move(
          surveyValues,
          starterList,
          source,
          destination,
        );
        applySurveyValues(sourceResult);
        setStarterList(destinationResult);
      } else {
        const [sourceResult, destinationResult] = move(
          starterList,
          surveyValues,
          source,
          destination,
        );
        setStarterList(sourceResult);
        applySurveyValues(destinationResult);
      }
    }
  };

  const reorder = (
    listItems: Array<keyof PreferenceRanking> | null,
    startIndex: number,
    endIndex: number,
  ) => {
    const result = listItems || [];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const move = (
    sourceList: Array<keyof PreferenceRanking>,
    destinationList: Array<keyof PreferenceRanking>,
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation,
  ) => {
    const sourceClone = Array.from(sourceList);
    const destClone = Array.from(destinationList);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);
    return [sourceClone, destClone];
  };

  const classes = useStyles();

  return (
    <>
      <Typography className={classes.description}>
        Drag the rectangles from the “options” list into the “Customer
        Preference Ranking” list to rank what is most important for this
        vehicle. 1 is the highest priority goal. 5 is the lowest.
      </Typography>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className={classes.listsContainer}>
          <PreferenceColumn
            disabled={props.disabled}
            columnId={finalColumnId}
            values={surveyValues}
            title={props.rankingTitle}
            number
          />
          <div className={classes.arrowIcon}>
            <LeftArrow />
          </div>
          <OptionsColumn
            disabled={props.disabled || starterList.length === 0}
            columnId={startColumnId}
            values={starterList}
            title={props.optionsTitle}
          />
        </div>
      </DragDropContext>
    </>
  );
}

function PreferenceColumn<T extends keyof PreferenceRanking>(props: {
  columnId: string;
  values: T[];
  title: string;
  number?: boolean;
  disabled: boolean;
}) {
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const classes = useStyles();

  return (
    <div className={classes.preferenceColumn}>
      <div className={classes.preferenceHeader}>
        <Typography variant="subtitle2">{props.title}</Typography>
      </div>
      <div
        className={`${classes.columnContainer} ${
          isDraggingOver ? classes.draggingSelectedColumn : ''
        }`}
      >
        <div className={classes.columnContents}>
          <div>
            {times(5, (i) => (
              <div key={i} className={classes.preferenceNumberRow}>
                <Avatar className={classes.preferenceNumber}>{`${i +
                  1}`}</Avatar>
                &nbsp;
              </div>
            ))}
          </div>
          <Droppable droppableId={props.columnId}>
            {(provided: DroppableProvided, snapshot) => {
              setIsDraggingOver(snapshot.isDraggingOver);
              return (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={classes.dropZone}
                >
                  {props.values.map((preference, i) => {
                    return (
                      <div key={preference} className={classes.columnListItem}>
                        <DraggableOption
                          disabled={props.disabled}
                          index={i}
                          id={preference}
                          title={PreferenceRankingToLabelMappings[preference]}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            }}
          </Droppable>
        </div>
      </div>
    </div>
  );
}

function OptionsColumn<T extends keyof PreferenceRanking>(props: {
  columnId: string;
  values: T[];
  title: string;
  number?: boolean;
  disabled: boolean;
}) {
  const classes = useStyles();
  return (
    <div className={classes.optionsColumn}>
      <div className={classes.optionsHeader}>
        <Typography variant="subtitle2">{props.title}</Typography>
      </div>
      <Droppable droppableId={props.columnId} isDropDisabled={props.disabled}>
        {(provided: DroppableProvided) => (
          <div
            className={classes.columnContents}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {props.values.length === 0 ? (
              <Typography>
                Great job! Double check the list you’ve created to ensure it
                properly reflects your performance priority for the vehicle.
              </Typography>
            ) : (
              <div className={classes.dropZone}>
                {props.values.map((preference, i) => (
                  <div key={preference} className={classes.columnListItem}>
                    <DraggableOption
                      disabled={props.disabled}
                      index={i}
                      id={preference}
                      title={PreferenceRankingToLabelMappings[preference]}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </Droppable>
    </div>
  );
}

function DraggableOption(props: {
  title: string;
  id: string;
  disabled: boolean;
  index: number;
}) {
  const classes = useStyles();
  return (
    <Draggable
      draggableId={props.id}
      index={props.index}
      data-testid={`${props.id}-draggable`}
      isDragDisabled={props.disabled}
    >
      {(draggableProvided: DraggableProvided, snapshot) => (
        <div
          className={`${classes.draggableOption} ${
            snapshot.isDragging ? classes.draggingDraggable : ''
          }`}
          {...draggableProvided.draggableProps}
          {...draggableProvided.dragHandleProps}
          ref={draggableProvided.innerRef}
        >
          <Typography className={classes.draggableOptionText}>
            {props.title}
          </Typography>
          <DragHandle
            className={classes.dragIcon}
            white={snapshot.isDragging}
          />
        </div>
      )}
    </Draggable>
  );
}
