import React from 'react';
import { useStyles } from './styles';
import { SnackbarContent, Snackbar, IconButton } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';

export enum SnackBarType {
  'Success',
  'Error',
}

type OwnProps = {
  type: SnackBarType;
  message: string;
  onClose: () => void;
  showSnack: boolean;
};

export const EatonSnackBar = (props: OwnProps) => {
  const classes = useStyles();
  const snackbarClass =
    props.type === SnackBarType.Success
      ? `${classes.success} success-snack`
      : `${classes.error} error-snack`;
  const icon =
    props.type === SnackBarType.Success ? (
      <CheckCircleIcon className={classes.iconVariant} />
    ) : (
      <ErrorIcon className={classes.iconVariant} />
    );

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={props.showSnack}
      autoHideDuration={6000}
      onClose={() => props.onClose()}
    >
      <SnackbarContent
        className={snackbarClass}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            {icon}
            {props.message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={() => {
              props.onClose();
            }}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};
