import React, { useState } from 'react';
import {
  Typography,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { useStyles } from './styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { DotIcon } from '../../icons/dot-icon';
import * as Colors from '@pxblue/colors';

const hasLowerCase = (password: string) => /[a-z]/.test(password);
const hasUpperCase = (password: string) => /[A-Z]/.test(password);
const hasNumber = (password: string) => /[0-9]/.test(password);
// https://owasp.org/www-community/password-special-characters
const hasSpecialCharacter = (password: string) =>
  // eslint-disable-next-line no-useless-escape
  /[~`!@#$%\^&*+=\-\[\]';,\/{}|":<>\?()\.]/.test(password);
const hasNineToSixteenCharacters = (password: string) =>
  password.length >= 9 && password.length <= 16;

export const evaluatePassword = (password: string) =>
  hasNumber(password) &&
  hasSpecialCharacter(password) &&
  hasUpperCase(password) &&
  hasLowerCase(password) &&
  hasNineToSixteenCharacters(password);

type Props = {
  password: string;
  setPassword: (password: string) => void;
  passwordMatchError: boolean;
  setPasswordMatchError: (error: boolean) => void;
  className?: string;
};

export const CreatePasswordField = (props: Props) => {
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);
  const [retypePassword, setRetypePassword] = useState(props.password);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={props.className}>
      <div className={classes.passwordFieldContainer}>
        <FormControl className={classes.passwordField}>
          <InputLabel htmlFor="password">Password</InputLabel>
          <Input
            id="password"
            data-testid="password"
            type={showPassword ? 'text' : 'password'}
            className={classes.passwordField}
            value={props.password}
            onChange={(event) => {
              props.setPassword(event.target.value);
              if (event.target.value !== retypePassword) {
                props.setPasswordMatchError(true);
              } else {
                props.setPasswordMatchError(false);
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={(event) => {
                    event.preventDefault();
                  }}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl className={classes.passwordField}>
          <InputLabel htmlFor="retypepassword" error={props.passwordMatchError}>
            Retype Password
          </InputLabel>
          <Input
            id="retypepassword"
            data-testid="retypepassword"
            type={showPassword ? 'text' : 'password'}
            value={retypePassword}
            onChange={(event) => {
              setRetypePassword(event.target.value);
              if (props.password === event.target.value) {
                props.setPasswordMatchError(false);
              } else {
                props.setPasswordMatchError(true);
              }
            }}
            error={props.passwordMatchError}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={(event) => {
                    event.preventDefault();
                  }}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {props.passwordMatchError && (
            <div className={classes.passwordRegisterError}>
              Passwords do not match.
            </div>
          )}
        </FormControl>
      </div>
      <div className={classes.passwordVerification}>
        <div className={classes.passwordVerificationColumn}>
          <div className={classes.passwordVerificationRow}>
            <DotIcon
              color={
                hasLowerCase(props.password)
                  ? Colors.blue[500]
                  : Colors.blue[100]
              }
            />
            <Typography
              className={`${classes.passwordVerificationText} ${
                hasLowerCase(props.password)
                  ? ''
                  : classes.unmetPasswordRequirementText
              }`}
            >
              One lowercase character
            </Typography>
          </div>
          <div className={classes.passwordVerificationRow}>
            <DotIcon
              color={
                hasUpperCase(props.password)
                  ? Colors.blue[500]
                  : Colors.blue[100]
              }
            />
            <Typography
              className={`${classes.passwordVerificationText} ${
                hasUpperCase(props.password)
                  ? ''
                  : classes.unmetPasswordRequirementText
              }`}
            >
              One uppercase character
            </Typography>
          </div>
          <div className={classes.passwordVerificationRow}>
            <DotIcon
              color={
                hasNumber(props.password) ? Colors.blue[500] : Colors.blue[100]
              }
            />
            <Typography
              className={`${classes.passwordVerificationText} ${
                hasNumber(props.password)
                  ? ''
                  : classes.unmetPasswordRequirementText
              }`}
            >
              One number
            </Typography>
          </div>
        </div>
        <div className={classes.passwordVerificationColumn}>
          <Tooltip
            placement="right"
            title={`Special characters are !"#$%&'()*+,-./:;<=>?@[]^\`{|}~`}
          >
            <div className={classes.passwordVerificationRow}>
              <DotIcon
                color={
                  hasSpecialCharacter(props.password)
                    ? Colors.blue[500]
                    : Colors.blue[100]
                }
              />
              <Typography
                className={`${classes.passwordVerificationText} ${
                  hasSpecialCharacter(props.password)
                    ? ''
                    : classes.unmetPasswordRequirementText
                }`}
              >
                One special character
              </Typography>
            </div>
          </Tooltip>
          <div className={classes.passwordVerificationRow}>
            <DotIcon
              color={
                hasNineToSixteenCharacters(props.password)
                  ? Colors.blue[500]
                  : Colors.blue[100]
              }
            />
            <Typography
              className={`${classes.passwordVerificationText} ${
                hasNineToSixteenCharacters(props.password)
                  ? ''
                  : classes.unmetPasswordRequirementText
              }`}
            >
              9 to 16 characters
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
