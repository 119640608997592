import React from 'react';
import NumberFormat from 'react-number-format';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    textField: {
      width: '75px',
      textAlignLast: 'right',
      paddingBottom: '0',
    },
    stepper: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 'auto',
      marginRight: '3px',
      alignItems: 'baseline',
      marginBottom: '-5px',
    },
    stepperIcon: {
      width: '15px',
      height: '15px',
    },
  }),
);

interface NumberInputProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { value: string } }) => void;
  onBlur?: (event: globalThis.React.FocusEvent<HTMLInputElement>) => void;
}

const NumberInput = (props: NumberInputProps) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values: any) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      onBlur={props.onBlur}
    />
  );
};

interface FormattedNumberInputProps {
  onChange?: (value: any) => any;
  value?: any;
  displayValue?: string;
  step?: number;
  min?: number;
  max?: number;
  label?: string;
  className?: string;
  ariaLabelledBy?: string;
  disabled?: boolean;
  dataTestId?: string;
}

enum Direction {
  Up,
  Down,
}

export const FormattedNumberInput = (props: FormattedNumberInputProps) => {
  const classes = useStyles();

  const handleChangeTextField = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const parsedValue = parseInt(event?.target.value.replace(/,/g, ''));
    if (event?.target.value === '' && props.value !== null) {
      handleChangeInternal(undefined);
    } else if (isNaN(parsedValue)) {
      handleChangeInternal(null);
    } else {
      handleChangeInternal(parsedValue);
    }
  };

  const handleChangeInternal = (newValue: number | undefined | null) => {
    props.onChange && props.onChange(newValue);
  };

  const minMaxHandler = (candidateValue: number): number => {
    if (props.min && candidateValue < props.min) {
      return props.min;
    } else if (props.max && candidateValue > props.max) {
      return props.max;
    }
    return candidateValue;
  };

  const handleButton = (direction: Direction) => {
    const stepValue =
      direction === Direction.Up
        ? props.step
          ? props.step
          : 1
        : props.step
        ? -props.step
        : -1;
    handleChangeInternal(minMaxHandler(parseInt(props.value) + stepValue));
  };

  const onBlur = (event: globalThis.React.FocusEvent<HTMLInputElement>) => {
    const candidateValue = parseInt(event.target.value.replace(/,/g, ''));
    if (event.target.value === '') {
      handleChangeInternal(minMaxHandler(0));
    } else if (props.step) {
      const remainder = candidateValue % props.step;
      const finalValue =
        remainder < props.step / 2
          ? candidateValue - remainder
          : candidateValue + (props.step - remainder);
      handleChangeInternal(minMaxHandler(finalValue));
    } else {
      handleChangeInternal(minMaxHandler(candidateValue));
    }
  };

  const handleArrowKeys = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.keyCode === 38) handleButton(Direction.Up);
    if (event.keyCode === 40) handleButton(Direction.Down);
  };

  return (
    <div className={classes.root}>
      <div className={classes.stepper}>
        <IconButton
          disabled={props.disabled}
          aria-label="Increase"
          size="small"
          onClick={() => handleButton(Direction.Up)}
        >
          <KeyboardArrowUpIcon className={classes.stepperIcon} />
        </IconButton>
        <IconButton
          disabled={props.disabled}
          aria-label="Decrease"
          size="small"
          onClick={() => handleButton(Direction.Down)}
        >
          <KeyboardArrowDownIcon className={classes.stepperIcon} />
        </IconButton>
      </div>
      <TextField
        disabled={props.disabled}
        className={`${props.className} ${classes.textField}`}
        label={props.label}
        value={props.value}
        onChange={handleChangeTextField}
        InputProps={{
          inputComponent: NumberInput as any,
          inputProps: {
            onBlur,
            // eslint-disable-next-line quote-props
            'style': { paddingBottom: '4px' },
            'data-testid': props.dataTestId,
            'aria-labelledby': props.ariaLabelledBy,
          },
        }}
        onKeyDown={handleArrowKeys}
      />
    </div>
  );
};
