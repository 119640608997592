/* eslint-disable quote-props */
import { makeStyles, Color, Theme } from '@material-ui/core';
import { PaletteColor } from '@material-ui/core/styles/createPalette';
import { SubType } from '../reusable-components/types';
import * as Colors from '@pxblue/colors';

export const useStyles = makeStyles((theme) => ({
  loginContainer: {
    display: 'block',
    height: '100vh',
    width: '100vw',
    background: 'linear-gradient(90deg, #007BC1 0%, #00599A 99.1%)',
    backgroundSize: '100%',
    margin: 0,
    padding: 0,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  loginPage: {
    backgroundImage: `url(../../../../transmission-outline.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '-25px 35px',
    backgroundSize: 'cover',
    height: '100%',
    maxWidth: '65%',
  },
  loginContentContainer: {
    width: '62%',
    position: 'absolute',
    height: '100%',
    top: 0,
    bottom: 0,
    right: 0,
    backgroundColor: theme.palette.common.white,
  },
  loginFormContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '370px',
    textAlign: 'center',
  },
  loginLogo: {
    height: '58px',
    marginBottom: '16px',
  },
  loginTitle: {
    marginBottom: '32px',
  },
  loginFormFields: {
    width: '370px',
  },
  loginFieldMargin: {
    marginBottom: '50px',
  },
  errorText: {
    color: getPaletteColor(theme, 'error', 500),
    marginBottom: '6px',
    fontSize: '12px',
  },
  loginSubmitButton: {
    marginBottom: '16px',
  },
  loginForgot: {
    marginTop: '8px',
    textAlign: 'right',
    fontSize: '12px',
  },
  loginCreateAccount: {
    fontSize: '12px',
    color: Colors.gray[800],
  },
  backtoHome: {
    marginTop: '100px'
  },
}));

type PaletteKey = keyof PaletteColor | keyof Color;

/**
 * Workaround for mismatch between the Palette type and the "real" objects behind the types
 */
function getPaletteColor(
  theme: Theme,
  palette: keyof SubType<Theme['palette'], Theme['palette']['primary']>,
  key: PaletteKey,
): string {
  const p = theme.palette[palette];
  return (p as Color & PaletteColor)[key];
}
