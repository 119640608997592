import { makeStyles, Theme } from '@material-ui/core';
import { FooterHeight } from '../app-chrome/footer/styles';
import { Gutters } from '../../styles/gutters';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    surveyFooterToolbar: {
      ...Gutters(theme),
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '68px',
      minWidth: '960px',
      maxWidth: '1500px',
      marginRight: 'auto',
      marginLeft: 'auto',
      color: theme.palette.getContrastText(theme.palette.grey[800]),
    },
    surveyFooter: {
      minHeight: '68px',
      position: 'absolute',
      backgroundColor: theme.palette.grey[50],
      bottom: FooterHeight,
      width: '100%',
      borderTop: `1px solid ${theme.palette.grey[100]}`,
    }
  };
});
