import React from 'react';
import { useStyles } from './styles';
import { GutterToolbar } from '../gutter-toolbar';

type Props = {
  lhs?: JSX.Element;
  rhs?: JSX.Element;
};

export const PureSurveyFooter = (props: Props) => {
  const classes = useStyles();
  return (
    <>
      <GutterToolbar className={classes.surveyFooterToolbar} />
      <footer className={classes.surveyFooter}>
        <GutterToolbar className={classes.surveyFooterToolbar}>
          <div>{props.lhs}</div>
          <div>{props.rhs}</div>
        </GutterToolbar>
      </footer>
    </>
  );
};
