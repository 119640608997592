import React, { Dispatch, SetStateAction } from 'react';
import useMounted from './use-mounted';

/**
 * Prevents the React warning about updating state in a component that has been unmounted.
 * Debatable whether it's worth caring about or not, but since a warning is displayed in the
 * console when it happens, here is a way to avoid it.
 *
 * This can happen when an async function is in flight (a button click handler for example),
 * and the component is unmounted (because the user was redirected to the login page).
 */
function useStateWhileMounted<S>(initialState: S | (() => S)): [S, Dispatch<SetStateAction<S>>];
function useStateWhileMounted<S = undefined>(): [S, Dispatch<SetStateAction<S | undefined>>];
function useStateWhileMounted<S>(initialState?: S | (() => S)): [S | undefined, Dispatch<SetStateAction<S | undefined>>] {
  const mounted = useMounted();
  const [state, setState] = React.useState(initialState);
  const setStateIfMounted = React.useCallback((s: S | ((prevState: S | undefined) => S | undefined) | undefined): void => {
    if (mounted.current) {
      setState(s);
    }
  }, [setState, mounted]);

  return [state, setStateIfMounted];
}

export default useStateWhileMounted;