import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    contentLabel: {
      marginBottom: '23px',
      color: theme.palette.text.primary,
    },
  };
});
