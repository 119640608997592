import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  wholeApp: {
    minHeight: '100vh',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '1120px',
  },
  children: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
}));
