import React from 'react';
import { useStyles } from './styles';

export const DefaultPage = (
  props: React.PropsWithChildren<{ background?: string }>,
) => {
  const classes = useStyles();
  return (
    <div
      style={props.background ? { background: props.background } : {}}
      className={classes.defaultPageContainer}
    >
      {props.children}
    </div>
  );
};
