import { VerificationStates, AccountCreationState } from '.';
import { useStyles } from './styles';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Link, TextField } from '@material-ui/core';
import { EatonButton, EatonLinkButton } from '../button';
import {
  CreatePasswordField,
  evaluatePassword,
} from '../create-password-field';

type Props = {
  code: string;
  setCode: (code: string) => void;
  state: VerificationStates;
  verifyCode: () => void;
  resendEmail: () => void;
  existingUser: boolean;
  password: string;
  setPassword: (password: string) => void;
  back: () => void;
};

export const PureVerificationCodePrompt = (props: Props) => {
  const classes = useStyles();

  const [passwordMatchError, setPasswordMatchError] = useState(false);

  const PasswordPrompt = props.existingUser ? null : (
    <>
      <Typography variant="h3" className={classes.passwordFieldHeader}>
        Password
      </Typography>
      <CreatePasswordField
        password={props.password}
        setPassword={props.setPassword}
        passwordMatchError={passwordMatchError}
        setPasswordMatchError={setPasswordMatchError}
      />
      {props.state === AccountCreationState.VerificationOrPasswordFailed && (
        <Typography className={classes.errorText}>
          <br />
          Something went wrong. Please try again.
        </Typography>
      )}
    </>
  );

  return (
    <form
      className={classes.createAccountDetails}
      onSubmit={(e) => {
        e.preventDefault();
        if (!props.existingUser) {
          if (!passwordMatchError) props.verifyCode();
        } else {
          props.verifyCode();
        }
      }}
    >
      <Typography className={classes.createAccountSubheading} variant="h3">
        Email Verification
      </Typography>

      <Typography className={classes.createAccountSubtext}>
        {props.existingUser && (
          <>
            It looks like you already have an Eaton account. Verify your email
            to add this account to the Automated Transmission Selector.
            <br />
            <br />
          </>
        )}
        Please enter the six-digit verification code we just sent to you. The
        code is valid for 15 minutes.
      </Typography>
      <div className={classes.verificationCodeContainer}>
        <TextField
          error={props.state === AccountCreationState.InvalidVerificationCode}
          helperText={
            props.state === AccountCreationState.InvalidVerificationCode &&
            'The verification code was invalid.'
          }
          data-testid="verificationcode"
          id="verificationcode"
          label="Verification Code"
          inputProps={{ maxLength: 6 }}
          className={classes.verificationCodeField}
          value={props.code}
          onChange={(event) => {
            props.setCode(event.target.value);
          }}
        />
        <EatonButton
          onClick={props.resendEmail}
          id="resendcode"
          variant="outlined"
          disabled={props.state === AccountCreationState.ResendingCode}
          loadingSpinner={props.state === AccountCreationState.ResendingCode}
        >
          Resend Code
        </EatonButton>
      </div>
      {props.state === AccountCreationState.AccountAlreadyVerified ? (
        <Typography className={classes.errorText}>
          <br />
          It looks like this account has already been verified. Please{' '}
          <Link component={RouterLink} to="/login">
            log in.
          </Link>
        </Typography>
      ) : (
        PasswordPrompt
      )}
      <span>
        <EatonButton
          disabled={
            props.state ===
            AccountCreationState.SubmittingVerificationAndPassword
          }
          className={classes.submitButton}
          variant="outlined"
          onClick={props.back}
        >
          Back
        </EatonButton>
        <EatonButton
          disabled={
            !(
              props.code !== '' &&
              (props.existingUser ||
                (props.password.trim() === props.password &&
                  evaluatePassword(props.password) &&
                  !passwordMatchError))
            ) ||
            props.state ===
              AccountCreationState.SubmittingVerificationAndPassword
          }
          data-testid="submit"
          className={classes.submitButton}
          type="submit"
          loadingSpinner={
            props.state ===
            AccountCreationState.SubmittingVerificationAndPassword
          }
        >
          Verify
        </EatonButton>
        <EatonLinkButton
          variant="outlined"
          disabled={
            props.state ===
            AccountCreationState.SubmittingVerificationAndPassword
          }
          to="/"
          className={classes.submitButton}
        >
          No, Thanks
        </EatonLinkButton>
      </span>
    </form>
  );
};
