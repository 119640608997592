import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { EatonButton } from '../button';
import ReactMarkdown from '../react-markdown';
import { kebabCase } from 'lodash';

export type TransmissionDisplay = {
  image: string | null;
  name: string;
  description: string;
  modelName: string;
  axleRatio: string;
  learnMoreUrl: string;
};

export enum MatchType {
  TopMatch = 'Top Match',
  RunnerUp = 'Runner Up',
}

type Props = {
  matchType: MatchType;
  transmission: TransmissionDisplay;
  disableLearnMore?: boolean;
  hideBorder?: boolean;
};

export const TransmissionResult = (props: Props) => {
  const placeholderImg = '../../transmission-no-image.png';
  const classes = useStyles();
  const link = props.transmission.learnMoreUrl;

  return (
    <Paper
      className={`${
        props.matchType === MatchType.TopMatch
          ? classes.containerTopChoice
          : props.hideBorder
            ? `${classes.containerRunnerUp}`
            : `${classes.containerRunnerUp} ${classes.runnerUpBorderBox}`
      }`}
    >
      <div
        className={`${
          props.matchType === MatchType.TopMatch
            ? classes.imageContainerTopChoice
            : props.hideBorder
              ? `${classes.imageContainerRunnerUp}`
              : `${classes.imageContainerRunnerUp} ${classes.runnerUpBorderRight}`
        }`}
      >
        <img
          data-testid={`transmission-image-${
            props.matchType === MatchType.TopMatch ? 'top-match' : 'rumnner-up'
          }`}
          className={`${classes.image} ${!props.transmission.image &&
            classes.imagePadding}`}
          src={
            props.transmission.image ? props.transmission.image : placeholderImg
          }
          alt={props.transmission.name}
        />
      </div>
      <div className={classes.contentContainer}>
        <Typography variant="subtitle2" className={classes.contentLabel}>
          {props.matchType}
        </Typography>
        <Typography variant="h3" className={classes.contentName}>
          {props.transmission.name}
        </Typography>
        <div className={`${kebabCase(props.matchType)}-content-body`}>
          <ReactMarkdown>{props.transmission.description}</ReactMarkdown>
          <Typography
            variant="subtitle1"
            className={classes.contentDescription}
          >
            <div style={{ marginTop: '0px' }}>
              {`\u2022 Model: ${props.transmission.modelName}`}
              <br />
              {`\u2022 Suggested Axle Ratio Range: ${props.transmission.axleRatio}`}
            </div>
          </Typography>
        </div>

        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <EatonButton
            className={classes.contentButton}
            disabled={props.disableLearnMore}
            variant={
              props.matchType === MatchType.TopMatch ? 'contained' : 'outlined'
            }
          >
            Learn More
          </EatonButton>
        </a>
      </div>
    </Paper>
  );
};
