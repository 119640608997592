import React from 'react';
import { Link } from 'react-router-dom';
import { ListTransmissions } from '../../components/transmission-table';
import {
  makeStyles,
  Theme,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { DefaultPage } from '../../components/default-page';
import { UserDataDump } from '../../components/data-dump';

const useStyles = makeStyles((theme: Theme) => {
  return {
    header: {
      padding: '10px',
      alignItems: 'center',
    },
    bodyText: {
      marginLeft: '15px',
    },
    addNewButton: {
      marginRight: '15px',
    },
  };
});

export function TransmissionData(props: any) {
  const classes = useStyles();
  return (
    <DefaultPage>
      <div style={{ marginBottom: '20px' }}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          className={classes.header}
        >
          <Typography className={classes.bodyText} variant="h2">
            Transmission List
          </Typography>
          <div style={{ marginRight: '10px' }}>
            <Tooltip title="Add New" placement="left">
              <Link
                to="/transmission-data/add/"
                color="secondary"
                className={classes.addNewButton}
                >
                <IconButton>
                  <AddIcon />
                </IconButton>
              </Link>
            </Tooltip>
            <UserDataDump/>
          </div>
        </Grid>
        <ListTransmissions />
      </div>
    </DefaultPage>
  );
}
