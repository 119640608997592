import { makeStyles, Theme } from '@material-ui/core';
import { Gutters } from '../../styles/gutters';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    mainSplashContainer: {
      width: '100vw',
      minWidth: '100%',
      marginBottom: '60px',
    },
    heroContentContainer: {
      ...Gutters(theme),
      display: 'flex',
      alignContent: 'center',
      minWidth: '960px',
      maxWidth: '1500px',
      marginRight: 'auto',
      marginLeft: 'auto',
    },
    heroImageContainer: {
      marginTop: '45px',
      marginRight: '65px',
      marginBottom: '-200px',
      position: 'relative',
    },
    heroTextContainer: {
      marginTop: '53px',
      maxWidth: '800px',
    },
    heroHeader: {
      marginBottom: '22px',
    },
    heroSubtitle: {
      marginBottom: '16px',
    },
  };
});
