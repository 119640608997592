import React from 'react';
import { EatonLinkButton } from '../../components/button';
import { Typography } from '@material-ui/core';

export const NotFoundPage = () => {
  return (
    <div style={{ marginTop: '100px', textAlign: 'center' }}>
      <Typography variant='h2' style={{ marginBottom: '32px' }}>
        Page Not Found
      </Typography>
      <EatonLinkButton to='/'>Take me back home</EatonLinkButton>
    </div>
  );
};
