import React from 'react';
import { Typography, LinearProgress } from '@material-ui/core';
import { useStyles } from './styles';

type Props = {
  currentPage: number;
  totalPages: number;
};

export const ProgressBar = (props: Props) => {
  const classes = useStyles();
  const progressValue = Math.round(
    (props.currentPage / props.totalPages) * 100,
  );
  return (
    <div className={classes.progressBarContainer}>
      <Typography className={`${classes.progressNumber} ${classes.leftNumber}`}>
        {props.currentPage}/{props.totalPages}
      </Typography>
      <LinearProgress
        className={classes.linearProgress}
        variant="determinate"
        value={progressValue}
      />
      <Typography
        className={`${classes.progressNumber} ${classes.rightNumber}`}
      >
        {`${progressValue}%`}
      </Typography>
    </div>
  );
};
