interface Flavoring<FlavorT> {
  _type?: FlavorT;
}
export type Flavor<T, FlavorT> = T & Flavoring<FlavorT>;

export enum Unit {
  Imperial = 'Imperial',
  Metric = 'Metric',
}

export type UnitType = keyof typeof UnitMapping;

export type ImperialUnit = number;
export type MetricUnit = number;

export const UnitMapping = {
  weight: { ...MakeConverters(0.453592), imperial: 'lbs', metric: 'kg' },
  speed: { ...MakeConverters(1.61), imperial: 'mph', metric: 'kph' },
  power: { ...MakeConverters(0.7457), imperial: 'hp', metric: 'kw' },
  torque: { ...MakeConverters(1.3558), imperial: 'lbs.-ft.', metric: 'Nm' },
};

export type Lbs = Flavor<number, 'lbs'>;
export type Kg = Flavor<number, 'kg'>;

export type Mph = Flavor<number, 'mph'>;
export type Kmph = Flavor<number, 'kmph'>;

export type Hp = Flavor<number, 'hp'>;
export type Kw = Flavor<number, 'kw'>;

export type Ftlb = Flavor<number, 'ftlb'>;
export type Nm = Flavor<number, 'nm'>;

function MakeConverters<ImperialUnits extends ImperialUnit, MetricUnits extends MetricUnit>(factor: number) {
  return {
    toMetric: (value: ImperialUnits) => (Math.round(value * factor) as MetricUnits),
    fromMetric: (value: MetricUnits) => (Math.round(value / factor) as ImperialUnits),
  };
}
