import React from 'react';
import {
  AppBar,
  Typography,
  Link,
  MenuItem,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useStyles } from './styles';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import * as _ from 'lodash';
import { GutterToolbar } from '../../gutter-toolbar';
import { useAuthState } from '../../reusable-components';
import {
  AuthStatus,
  useAuthDispatch,
} from '../../reusable-components/components/AuthContext';

export const AppHeader = () => {
  const classes = useStyles();
  const location = useLocation();
  const authState = useAuthState();
  const { logout } = useAuthDispatch();

  const Tab = (props: { name: string; link: string }) => (
    <Link
      className={`${classes.headerTab} ${
        location.pathname.includes(props.link) ? classes.activeHeaderTab : ''
      }`}
      component={RouterLink}
      underline='none'
      to={props.link}
      data-testid={`${_.kebabCase(props.name)}-link`}
    >
      <Typography className={classes.headerTabText}>{props.name}</Typography>
    </Link>
  );

  const SignInButton = (props: { name: string; link: string }) => (
    <Link
      component={RouterLink}
      underline='none'
      to={props.link}
      data-testid={`${_.kebabCase(props.name)}-link`}
    >
      <Typography className={classes.signIn}>{props.name}</Typography>
    </Link>
  );

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  // new things
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <AppBar position={'static'} className={classes.appBar}>
      <GutterToolbar className={classes.headerBar}>
        <div className={classes.tabContainer}>
          <RouterLink to='/' className={classes.logoLink}>
            <img
              className={classes.logo}
              src='../../../../../logo.png'
              data-testid='logo'
              alt='Eaton Cummins Joint Venture Logo'
            />
          </RouterLink>
          <Tab name='Create Spec' link='/survey' />
          {authState.authenticated === AuthStatus.Authenticated && (
            <Tab name='My Specs' link='/spec' />
          )}
          {authState.authenticated === AuthStatus.Authenticated &&
            authState.privileges.roles.includes('TransmissionAdmin') && (
              <Tab name='Admin' link='/transmission-data' />
            )}
        </div>
        {authState.authenticated === AuthStatus.Unauthenticated ? (
          <SignInButton name='Sign in' link='/login' />
        ) : (
          <div>
            <div
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup='true'
              onClick={handleToggle}
              data-testid='account-menu'
            >
              <Typography className={classes.headerTabText}>
                {authState.displayName
                  ? `Hi, ${authState.displayName}`
                  : 'Welcome'}
                <ArrowDropDownIcon className={classes.userMenuArrowIcon} />
              </Typography>
            </div>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
              id='simple-menu'
              placement='bottom-end'
              className={classes.userMenuMargin}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center left' : 'center bottom',
                  }}
                >
                  <Paper className={classes.userMenucontainer}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id='menu-list-grow'
                        onKeyDown={handleListKeyDown}
                        style={{ padding: 0 }}
                      >
                        <MenuItem
                          data-testid='sign-out-account-menu'
                          onClick={async () => {
                            setOpen(false);
                            // eslint-disable-next-line @typescript-eslint/no-floating-promises
                            logout();
                          }}
                          style={{
                            width: '100%',
                            paddingTop: '8px',
                            paddingBottom: '8px',
                          }}
                        >
                          <Typography>Sign Out</Typography>
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        )}
      </GutterToolbar>
    </AppBar>
  );
};
