export enum OrganizationType {
  AftermarketChannelPartner = 'Aftermarket Channel Partner',
  DealerSales = 'Dealer/Sales',
  Distributor = 'Distributor',
  EndUserDriverOwnerOperator = 'End User/Driver/Owner-Operator',
  Fleet = 'Fleet',
  LeaseRental = 'Lease Rental',
  MaintenanceServiceTechnician = 'Maintenance/Service/Technician',
  Manufacturer = 'Manufacturer',
  Other = 'Other',
}
