import React from 'react';
import { SurveyView } from '../../components/survey';
import { UnitProvider } from '../../components/unit-switcher';

export const SurveyPage = () => {
  return (
    <UnitProvider>
      <SurveyView />
    </UnitProvider>
  );
};
