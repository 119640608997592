import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    surveyHeaderContainer: {
      marginBottom: '32px',
      display: 'flex',
    },
    containerSmallerMargin: {
      marginBottom: '23px',
      display: 'flex',
    },
    containerTinyMargin: {
      marginBottom: '16px',
      display: 'flex',
    },
    helpIcon: {
      width: '28px',
      height: '28px',
      marginLeft: '16px',
    },
  };
});
