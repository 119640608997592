import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  ButtonBaseProps,
  Theme,
  withStyles,
  CircularProgress,
} from '@material-ui/core';
import { TSPTheme } from '../../../src/styles/theme';

const StyledButton = withStyles((theme: Theme) => ({
  root: {
    width: 'fit-content',
    fontWeight: 600,
    borderRadius: '2px',
  },
  outlined: {
    borderColor: theme.palette.grey[100],
  },
  outlinedSecondary: {
    borderColor: theme.palette.grey[100],
  },
}))(Button);

export const EatonButton: React.FunctionComponent<
  ButtonBaseProps & {
    color?: 'inherit' | 'primary' | 'secondary' | 'default';
    variant?: 'text' | 'outlined' | 'contained';
    component?: 'label';
    loadingSpinner?: boolean;
  }
> = (props) => {
  const { loadingSpinner, ...myProps } = props;
  return (
    <StyledButton
      {...myProps}
      color={props.color || 'primary'}
      variant={props.variant || 'contained'}
      className={`${props.className}`}
      disableRipple
    >
      {props.children}
      {props.loadingSpinner && (
        <CircularProgress
          size={24}
          style={{
            color: TSPTheme.palette.primary.main,
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
          }}
        />
      )}
    </StyledButton>
  );
};

export const EatonLinkButton: React.FunctionComponent<
  ButtonBaseProps & {
    to: string;
    color?: 'inherit' | 'primary' | 'secondary' | 'default';
    variant?: 'text' | 'outlined' | 'contained';
    loadingSpinner?: boolean;
    target?: string;
  }
> = (props) => {
  const { loadingSpinner, ...myProps } = props;
  return (
    <EatonButton
      // @ts-ignore Disabling this line because of the problem described in https://github.com/mui-org/material-ui/issues/15827
      component={Link}
      to={props.to}
      {...myProps}
      color={props.color || 'primary'}
      variant={props.variant || 'contained'}
    >
      {props.children}
    </EatonButton>
  );
};
