import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { EatonButton } from '../button';
import { useAuthDispatch } from '../reusable-components/components/AuthContext';
import { Link, Redirect, useLocation } from 'react-router-dom';
import * as Query from 'query-string';
import {
  CreatePasswordField,
  evaluatePassword,
} from '../create-password-field';
import { useStyles } from './styles';

export const PureResetPassword = (props: {
  submit: (email: string) => void;
  passwordSet: 'unset' | 'setting' | 'set' | 'failed';
}) => {
  const [password, setPassword] = useState('');
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const classes = useStyles();

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        await props.submit(password);
      }}
    >
      {props.passwordSet === 'set' ? (
        <>
          <Typography className={classes.resetPasswordRow} variant="h1">
            Success!
          </Typography>
          <Typography className={classes.resetPasswordRow}>
            Your new password has been set. You can now{' '}
            <Link to="/login">log in</Link> with your new password.
          </Typography>
        </>
      ) : (
        <>
          <Typography className={classes.resetPasswordRow} variant="h1">
            Reset Your Password
          </Typography>
          <Typography className={classes.resetPasswordRow}>
            Enter your new password.
          </Typography>
          <CreatePasswordField
            password={password}
            setPassword={setPassword}
            passwordMatchError={passwordMatchError}
            setPasswordMatchError={setPasswordMatchError}
          />
          <EatonButton
            className={classes.resetPasswordRow}
            data-testid="reset"
            type="submit"
            loadingSpinner={props.passwordSet === 'setting'}
            disabled={
              !evaluatePassword(password) || props.passwordSet === 'setting'
            }
          >
            Set Password
          </EatonButton>

          {props.passwordSet === 'failed' && (
            <Typography className={classes.error}>
              Something went wrong. Please try again.
            </Typography>
          )}
        </>
      )}
    </form>
  );
};

export const ResetPassword = () => {
  const { authenticatedRequest } = useAuthDispatch();
  const { search } = useLocation();
  const [passwordSet, setPasswordSet] = useState<
    'unset' | 'setting' | 'set' | 'failed'
  >('unset');

  const { id, verificationCode } = Query.parse(search);
  if (!verificationCode || !id) {
    return <Redirect to="/error" />;
  }

  return (
    <>
      <PureResetPassword
        passwordSet={passwordSet}
        submit={(newPassword) => {
          setPasswordSet('setting');
          const set = async () => {
            const result = await authenticatedRequest({
              method: 'POST',
              url: `/api/security/reset-password/password`,
              data: {
                verificationCode,
                id,
                newPassword,
              },
            });
            if (result.ok) {
              setPasswordSet('set');
            } else {
              setPasswordSet('failed');
            }
          };
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          set();
        }}
      />
    </>
  );
};
