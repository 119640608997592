import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { EditTransmissionForm } from '../../components/edit-transmission-form';
import { Transmission } from '../../domain/transmission';
import { CircularProgress, Grid, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { DefaultPage } from '../../components/default-page';
import { useAuthenticatedRequest } from '../../components/reusable-components/hooks/use-authenticated-request';

type EditRouteComponentProps = {
  id: string;
};

export function EditTransmissionPage(
  props: RouteComponentProps<EditRouteComponentProps>,
) {
  const [loadingTransmission, transmission] = useAuthenticatedRequest<
    Transmission
  >({
    requestConfig: {
      method: 'GET',
      url: `api/transmission/${props.match.params.id}`,
    },
  });

  const PageBody = () => {
    if (loadingTransmission) {
      return (
        <CircularProgress
          disableShrink={true}
          style={{
            width: '75px',
            height: '75px',
            marginLeft: '50%',
            marginTop: '20%',
          }}
        />
      );
    }

    if (transmission) {
      return <EditTransmissionForm transmission={transmission} />;
    }
    return <div>Unknown error occurred.</div>;
  };

  return (
    <DefaultPage>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        style={{ padding: '10px' }}
      >
        <Link
          to="/transmission-data/"
          style={{ textDecoration: 'none' }}
          data-testid="back-button"
        >
          <Button
            color="secondary"
            style={{ textTransform: 'inherit', marginLeft: '10px' }}
          >
            <ArrowBackIcon style={{ marginRight: '5px' }} />
            Back
          </Button>
        </Link>
      </Grid>
      <PageBody />
    </DefaultPage>
  );
}
