import { makeStyles, Theme } from '@material-ui/core';
import * as Colors from '@pxblue/colors';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    preferenceColumn: {
      height: '444px',
      backgroundColor: theme.palette.primary.light,
      border: `1px solid ${Colors.blue[100]}`,
      boxShadow: 'none',
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'column',
    },
    dropZone: {
      height: '100%',
      width: '100%',
      minWidth: '394px',
      maxWidth: '562px',
      flexGrow: 1,
    },
    columnContainer: {
      width: '100%',
      height: '100%',
    },
    columnContents: {
      display: 'flex',
      padding: '32px',
      height: '100%',
    },
    optionsColumn: {
      height: '444px',
      width: '442px',
      backgroundColor: Colors.white[100],
      border: `1px solid ${theme.palette.grey[100]}`,
      boxShadow: 'none',
      borderRadius: '4px',
    },
    description: {
      width: '770px',
      paddingBottom: '32px',
    },
    columnListItem: {
      display: 'flex',
      alignItems: 'baseline',
    },
    listsContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '30px',
    },
    arrowIcon: {
      padding: '28px',
    },
    preferenceHeader: {
      background: 'rgba(255, 255, 255, 0.5)',
      borderRadius: '4px 4px 0 0',
      padding: '8px',
      borderBottom: `1px solid ${Colors.blue[100]}`,
      textAlign: 'center',
    },
    optionsHeader: {
      background: theme.palette.grey[50],
      borderRadius: '4px 4px 0 0',
      padding: '8px',
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
      textAlign: 'center',
    },
    draggableOption: {
      'flexGrow': 1,
      'width': '100%',
      'display': 'flex',
      'justifyContent': 'space-between',
      'alignItems': 'baseline',
      'boxShadow': '0px 1px 5px rgba(0, 0, 0, 0.2)',
      'padding': '16px',
      'background': Colors.white[100],
      'borderRadius': '2px',
      'marginBottom': '16px',
      '&:hover': {
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
      },
    },
    draggingDraggable: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    draggableOptionText: {
      fontWeight: 600,
    },
    dragIcon: {
      marginRight: '8px',
      marginLeft: '16px',
    },
    draggingSelectedColumn: {
      boxShadow: 'inset 1px 1px 20px rgba(0, 123, 193, 0.3)',
    },
    preferenceNumberRow: {
      'display': 'flex',
      'alignItems': 'center',
      'height': '56px',
      'marginBottom': '16px',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    preferenceNumber: {
      marginRight: '22px',
      marginLeft: '-10px',
      width: 26,
      height: 26,
      fontSize: '16px',
      fontWeight: 'bold',
      background: Colors.blue[400],
    },
  };
});
