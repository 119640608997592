import { makeStyles, Theme } from '@material-ui/core';
import * as Colors from '@pxblue/colors';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    success: {
      backgroundColor: Colors.green[800],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      marginTop: '15px',
    },
    inputField: {
      marginBottom: '16px',
      width: '100%',
    },
    wrapper: {
      position: 'relative',
      width: 'fit-content',
    },
    buttonProgress: {
      color: Colors.blue[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    card: {
      width: '100%',
      marginBottom: '20px',
    },
    header: {
      paddingBottom: '20px',
    },
    errorText: {
      color: '#ca3c3d',
      fontSize: '0.75rem',
      marginBottom: '8px',
    },
    largeErrorText: {
      color: '#ca3c3d',
    },
    imageButtonContainer: {
      marginTop: '24px',
      display: 'inline-block',
    },
    imageButton: {
      marginRight: '16px',
    },
    unitLabel: {
      marginLeft: '7px',
      alignItems: 'flex-end',
      color: theme.palette.text.secondary,
    },
  };
});
