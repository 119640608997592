import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    sliderContainer: {
      width: '370px',
      marginBottom: '64px',
    },
    topContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      marginBottom: '24px',
    },
    inputContainer: {
      display: 'inline-flex',
      alignItems: 'flex-end',
      height: 'fit-content',
      marginRight: '-30px',
    },
    unitLabel: {
      marginLeft: '7px',
      alignItems: 'flex-end',
      color: theme.palette.text.secondary,
    },
    valueLabel: {
      // eslint-disable-next-line quote-props
      'top': -28,
      // eslint-disable-next-line quote-props
      'fontSize': 15,
      '& *': {
        background: 'transparent',
        color: '#000',
      },
    },
  };
});
