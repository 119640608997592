import React, { useState } from 'react';
import { Transmission } from '../../domain/transmission';
import { PureTransmissionForm } from './pure-transmission-form';

type Props = {
  transmission: Omit<Transmission, 'id'> | undefined;
  onSubmit: (transmission: TransmissionFormData) => Promise<'success' | 'fail'>;
  header: string;
};

export type SubmissionState = 'submitting' | 'success' | 'fail' | 'unsubmitted';
// Helps aid us with form validation.
export type TransmissionFormData = Omit<Transmission, 'id' | 'image'> & {
  imageFile?: File;
};

export const TransmissionForm = (props: Props) => {
  const [submissionState, setSubmissionState] = useState<SubmissionState>(
    'unsubmitted',
  );

  const onSubmit = (data: TransmissionFormData) => {
    setSubmissionState('submitting');
    const submit = async () => {
      const result = await props.onSubmit(data);
      setSubmissionState(result);
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    submit();
  };

  return (
    <PureTransmissionForm
      onSubmit={onSubmit}
      clearSubmissionState={() => {
        setSubmissionState('unsubmitted');
      }}
      header={props.header}
      submissionState={submissionState}
      transmission={props.transmission}
    />
  );
};
