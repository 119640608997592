import React from 'react';
import { AppHeader } from './header';
import { AppFooter } from './footer';
import { useStyles } from './styles';

export const AppChrome = (props: React.PropsWithChildren<{}>) => {
  const classes = useStyles();
  return (
    <div className={classes.wholeApp}>
      <AppHeader />
      <div className={classes.children}>{props.children}</div>
      <AppFooter />
    </div>
  );
};
