import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { EatonButton } from '../button';
import { useAuthDispatch } from '../reusable-components/components/AuthContext';
import { EmailField, validateEmail } from '../email-field';
import { useStyles } from './styles';

export const PureForgotPassword = (props: {
  submit: (email: string) => void;
  emailSent: 'unsent' | 'sending' | 'sent' | 'failed';
}) => {
  const [email, setEmail] = useState('');
  const isValidEmail = validateEmail(email);
  const classes = useStyles();
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        props.submit(email);
      }}
    >
      <Typography variant="h1" className={classes.forgotPasswordRow}>
        Reset Your Password
      </Typography>
      <Typography className={classes.forgotPasswordRow}>
        Enter your email below, and we&apos;ll send you a link to reset your
        password.
      </Typography>
      <EmailField
        className={[classes.email, classes.forgotPasswordRow].join(' ')}
        email={email}
        setEmail={(e) => {
          setEmail(e);
        }}
      />
      <EatonButton
        type="submit"
        className={classes.resetButton}
        disabled={!isValidEmail || props.emailSent === 'sending'}
        loadingSpinner={props.emailSent === 'sending'}
        data-testid="sendemail"
      >
        Send Email
      </EatonButton>
      {props.emailSent === 'sent' && (
        <Typography className={classes.forgotPasswordRow}>
          If an account exists for that address we have sent you a password
          reset link. Follow the instructions in the email to reset your
          password.
        </Typography>
      )}
      {props.emailSent === 'failed' && (
        <Typography
          className={[classes.forgotPasswordRow, classes.error].join(' ')}
        >
          Something went wrong. Please try again.
        </Typography>
      )}
    </form>
  );
};

export const ForgotPassword = () => {
  const { authenticatedRequest } = useAuthDispatch();
  const [emailSent, setEmailSent] = useState<
    'unsent' | 'sent' | 'sending' | 'failed'
  >('unsent');
  return (
    <>
      <PureForgotPassword
        emailSent={emailSent}
        submit={(email) => {
          setEmailSent('sending');
          const send = async () => {
            const result = await authenticatedRequest({
              method: 'POST',
              url: `/api/security/reset-password/email/${email}`,
            });
            if (result.ok) {
              setEmailSent('sent');
            } else {
              setEmailSent('failed');
            }
          };
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          send();
        }}
      />
    </>
  );
};
