import React from 'react';
import { DefaultPage } from '../../components/default-page';
import { ForgotPassword } from '../../components/forgot-password';

export const ForgotPasswordPage = () => {
  return (
    <DefaultPage>
      <ForgotPassword />
    </DefaultPage>
  );
};
