import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      marginTop: '52px',
      marginBottom: '32px',
    },
    backButton: {
      border: `1px solid ${theme.palette.grey[100]}`,
      marginRight: '16px',
    },
    buttonProgress: {
      color: theme.palette.primary.main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    questions: { paddingBottom: '32px', flexGrow: 1 },
  };
});
