import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    subtitle: {
      marginTop: '32px',
    },
    image: {
      marginTop: '16px',
      width: '475px',
    },
    description: {
      marginTop: '8px',
    },
    roadExamples: {
      marginBottom: '32px',
    },
  };
});
