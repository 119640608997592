import React from 'react';
import { Redirect } from 'react-router-dom';
import { RouteProps, Route, useLocation } from 'react-router';
import { useAuthState, AuthStatus } from './AuthContext';
import { Unauthorized } from '../../unauthorized';
import Cookie from 'js-cookie';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
interface Props extends RouteProps {
  allowedRoles?: string[];
  loginPath: string;
}

const PrivateRoute: React.FC<Props> = (props: Props) => {
  const authState = useAuthState();
  const location = useLocation();

  if (authState.authenticated === AuthStatus.Unauthenticated) {
    Cookie.set('etn-destination', location.pathname);
    return (
      <Redirect
        to={{
          pathname: props.loginPath,
          state: { from: props.location },
        }}
      />
    );
  } else if (props.allowedRoles) {
    const hasAnAllowedRole = props.allowedRoles.find((role) =>
      authState.privileges.roles.includes(role),
    );
    if (!hasAnAllowedRole) {
      return <Unauthorized />;
    }
  }

  return <Route {...props} />;
};

export default PrivateRoute;
