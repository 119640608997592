import { makeStyles, Theme } from '@material-ui/core';
import { Gutters } from '../../styles/gutters';

export const useStyles = makeStyles((theme: Theme) => ({
  defaultPageContainer: {
    ...Gutters(theme),
    minWidth: '960px',
    maxWidth: '1500px',
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
}));
