import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { QuestionButton } from '../../question-button';

type Props = {
  headerText: string;
  helpLink?: string;
  smallMargin?: boolean;
  tinyMargin?: boolean;
};

export const SurveyPageHeader = (props: Props) => {
  const classes = useStyles();
  return (
    <div
      className={
        props.tinyMargin
          ? classes.containerTinyMargin
          : props.smallMargin
          ? classes.containerSmallerMargin
          : classes.surveyHeaderContainer
      }
    >
      <Typography variant="h3">{props.headerText}</Typography>
      {!!props.helpLink && <QuestionButton
        className={classes.helpIcon}
        to={props.helpLink}
      />}
    </div>
  );
};
