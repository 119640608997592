import React, { useState } from 'react';
import { Survey, PreferenceRanking } from '../../domain/survey';
import { PureSurvey, NullableSurvey } from '../pure-survey';
import { TransmissionDisplay } from '../transmission-result';
import { SurveyShell } from '../survey-shell/survey-shell';
import { SubmissionState } from '../pure-transmission-form/transmission-form';
import { TransmissionConstants } from '../../domain/transmission';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { DefaultPage } from '../default-page';
import { EatonButton, EatonLinkButton } from '../button';
import { useAuthState } from '../reusable-components';
import {
  AuthStatus,
  useAuthDispatch,
} from '../reusable-components/components/AuthContext';
import { SurveyResult } from '../survey-result';
const JSONPretty = require('react-json-pretty'); // TS gets cranky if we import this normally 🤷‍♀️

export type ApiResponse = {
  resultType: 'success' | 'fail';
  content: Result;
};

export type Result = (
  | MessageResultTransmission
  | MessageResultNoTransmission
  | MessageResultError
) & { debug?: any };

export enum MessageResultType {
  Transmission = 'Transmission',
  NoTransmission = 'NoTransmission',
  Error = 'Error',
}

type MessageResultTransmission = {
  type: MessageResultType.Transmission;
  topChoice: Pick<
    TransmissionDisplay,
    | 'image'
    | 'name'
    | 'description'
    | 'learnMoreUrl'
    | 'modelName'
    | 'axleRatio'
  >;
  runnerUp?: Pick<
    TransmissionDisplay,
    | 'image'
    | 'name'
    | 'description'
    | 'learnMoreUrl'
    | 'modelName'
    | 'axleRatio'
  >;
};

type MessageResultNoTransmission = {
  type: MessageResultType.NoTransmission;
  message: string;
};

type MessageResultError = {
  type: MessageResultType.Error;
  message: string;
};

export const EMPTY_SURVEY = {
  application: null,
  maxWeight: TransmissionConstants.MIN_WEIGHT,
  terrain: null,
  maneuvers: null,
  roadTypes: null,
  cruiseSpeed: TransmissionConstants.MIN_SPEED,
  maxSpeed: TransmissionConstants.MIN_SPEED,
  pto: null,
  engineMake: null,
  engineHp: TransmissionConstants.MIN_HP,
  engineTorque: TransmissionConstants.MIN_TORQUE,
  preferenceRanking: {
    acceleration: null,
    fuelEconomy: null,
    lowSpeedManeuvers: null,
    price: null,
    tripTime: null,
  },
};

export const allPreferenceRankingFilled = (
  candidate: NullableSurvey<PreferenceRanking>,
) =>
  !!candidate &&
  Object.entries(candidate).reduce(
    (acc, entry) => acc && entry[1] !== null,
    true,
  );

export const isCompleteSurvey = (
  candidate: NullableSurvey<Survey>,
): candidate is Survey => {
  const allFieldsFilled = Object.entries(candidate).reduce((acc, entry) => {
    return acc && entry[1] !== null;
  }, true);

  return (
    allFieldsFilled && allPreferenceRankingFilled(candidate.preferenceRanking)
  );
};

export const Disclaimer = (props: { setDisplay: () => void }) => (
  <DefaultPage>
    <div style={{ marginTop: '52px' }}>
      <Typography variant="h1" style={{ marginBottom: '32px' }}>
        Create a New Transmission Spec
      </Typography>
      <div
        style={{ width: '667px', marginBottom: '32px', alignItems: 'baseline' }}
      >
        <div style={{ display: 'flex' }}>
          <Typography>
            The most appropriate transmission will vary with application.&nbsp;
            <a href="/help#Disclaimer" target="_blank">
              Learn more here.
            </a>
          </Typography>
        </div>
        <br />
        <Typography>
          Eaton Cummins Automated Transmission Technologies reserves the right
          to discontinue or modify its models and/or procedures and to change
          specifications at any time without notice.
        </Typography>
      </div>
      <div style={{ marginBottom: '32px' }}>
        <EatonButton
          style={{ marginRight: '14px' }}
          onClick={props.setDisplay}
          data-testid="confirm-disclaimer"
        >
          Got it! Create spec
        </EatonButton>
        <EatonLinkButton to="/" variant="outlined">
          Cancel
        </EatonLinkButton>
      </div>
    </div>
  </DefaultPage>
);

export const SurveyView = (props: { _inTest?: boolean }) => {
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(true);
  const [message, setMessage] = useState<ApiResponse | undefined>(undefined);
  const [survey, setSurvey] = useState<NullableSurvey<Survey>>(EMPTY_SURVEY);
  const [submissionState, setSubmissionState] = useState<SubmissionState>(
    'unsubmitted',
  );
  const router = useHistory();
  const authState = useAuthState();
  const { authenticatedRequest } = useAuthDispatch();

  const setDisclaimer = () => setShowDisclaimer(!showDisclaimer);

  const sendSurveyData = async (
    filledSurvey: Survey,
  ): Promise<'success' | 'fail'> => {
    const result = await authenticatedRequest<Result>({
      method: 'POST',
      url: `api/survey${
        authState.authenticated === AuthStatus.Authenticated &&
        authState.privileges.roles.includes('TransmissionAdmin')
          ? '/debug'
          : ''
      }`,
      data: filledSurvey,
    });
    if (result.ok) {
      setMessage({
        resultType: 'success',
        content: result.body,
      });
      return 'success';
    } else {
      setMessage(undefined);
      return 'fail';
    }
  };
  const trackEvent = (eventName: string, eventData: any) => {
      if ((window as any)._satellite) {
          (window as any)._satellite.track(eventName, eventData);
      }
  };
  const onSubmit = async () => {
    if (isCompleteSurvey(survey)) {
      setSubmissionState('submitting');
      const result = await sendSurveyData(survey);
      setSubmissionState(result);
      if (result === 'fail') {
        router.push('/error');
      }
      trackEvent('Survey Completed', {
        category: 'Survey',
        action: 'Completed survey'
    });
      window.scrollTo({ top: 0 });
    }
  };

  if (message?.resultType === 'success' && isCompleteSurvey(survey)) {
    switch (message.content.type) {
      case MessageResultType.Transmission:
        return (
          <SurveyResult
            debug={message.content.debug}
            cancelLink="/"
            topChoice={{
              ...message.content.topChoice,
            }}
            runnerUp={
              message.content.runnerUp
                ? {
                    ...message.content.runnerUp,
                  }
                : undefined
            }
            survey={survey}
            _inTest={props._inTest}
          />
        );
      case MessageResultType.NoTransmission:
        return (
          <SurveyResult
            debug={message.content.debug}
            cancelLink="/"
            noTransmissionMessage={message.content.message}
            survey={survey}
            _inTest={props._inTest}
          />
        );
      // This case should be handled in the onSubmit, but just in case, we can display it here.
      case MessageResultType.Error:
        return (
          <div style={{ padding: '50px' }}>
            <JSONPretty id="json-pretty" data={message} />
          </div>
        );
      default:
        throw new Error('Should never reach this point.');
    }
  } else if (message?.resultType === 'fail') {
    // This case should be handled in the onSubmit, but just in case, we can display it here.
    return <Typography>Something went wrong. Please try again.</Typography>;
  }

  return (
    <>
      {showDisclaimer ? (
        <Disclaimer setDisplay={setDisclaimer} />
      ) : (
        <SurveyShell
          onSubmit={onSubmit}
          submissionState={submissionState}
          cancelLink="/"
        >
          {PureSurvey({ survey, setSurvey, submissionState })}
        </SurveyShell>
      )}
    </>
  );
};