import React from 'react';
import { AppSplash } from '../../components/splash';
import { AppSplashNotLoggedIn } from '../../components/splash-not-logged-in';
import { useStyles } from './styles';
import { useAuthState } from '../../components/reusable-components';
import { AuthStatus } from '../../components/reusable-components/components/AuthContext';
import { AppSplashLoggedIn } from '../../components/splash-logged-in';
import { DefaultPage } from '../../components/default-page';

export const Home = () => {
  const classes = useStyles();
  const { authenticated } = useAuthState();
  const signedIn = authenticated === AuthStatus.Authenticated;
  return (
    <div
      className={`${classes.mainContainer} ${
        signedIn ? '' : classes.signedOutBg
      }`}
    >
      <AppSplash />
      {signedIn ? (
        <DefaultPage>
          <AppSplashLoggedIn />
        </DefaultPage>
      ) : (
        <AppSplashNotLoggedIn />
      )}
    </div>
  );
};
