import React from "react";
import { EatonLinkButton } from "../../components/button";
import { Typography } from "@material-ui/core";

export const InactivePage = () => {
  return (
    <div style={{ marginTop: "100px", textAlign: "center" }}>
      <Typography variant="h2" style={{ marginBottom: "32px" }}>
        You have been signed out due to inactivity.
      </Typography>
      <Typography>
        Please sign in again to access your saved results.
      </Typography>
      <EatonLinkButton style={{ marginTop: "32px" }} to="/login">
        Sign In
      </EatonLinkButton>
    </div>
  );
};
