/* eslint-disable quote-props */
import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    editorToolbar: {
      backgroundColor: theme.palette.grey[100],
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderRadius: '0px',
    },
    editorWrapper: {
      border: `1px solid ${theme.palette.grey[200]}`,
      borderRadius: '2px',
    },
    editorButton: {
      fontFamily: theme.typography.fontFamily,
      border: 'none',
      '& .rdw-option-active': {
        backgroundColor: theme.palette.grey[300],
        border: `1px solid ${theme.palette.grey[300]}`,
        boxShadow: `1px 1px 0px ${theme.palette.grey[400]}`,
      },
      '& .rdw-option-wrapper': {
        border: 'none',
      },
    },
    editorContent: {
      paddingLeft: '10px',
      paddingRight: '10px',
      maxHeight: '400px',
    },
  };
});
