import { useAuthDispatch, UserInfo } from '../components/AuthContext';
import React from 'react';

export type LoginForm = {
  email: string;
  password: string;
};

export type LoginFormResult =
  | { success: true }
  | { success: false; messages: string[] };

export default function useLogin(): (
  form: LoginForm,
) => Promise<LoginFormResult> {
  const { userLoggedIn, authenticatedRequest } = useAuthDispatch();
  return React.useCallback(
    async (form: LoginForm): Promise<LoginFormResult> => {
      try {
        const response = await authenticatedRequest({
          url: '/api/security/token',
          data: {
            user: form.email,
            password: form.password,
          },
          method: 'POST',
        });

        if (response.status !== 200) {
          return {
            success: false,
            messages: ['Email or password is incorrect.'],
          };
        }
        const userInfoResponse = await authenticatedRequest<UserInfo>({
          url: '/api/security/currentuser',
          method: 'GET',
        });

        if (!userInfoResponse.ok) {
          return {
            success: false,
            messages: ['Something went wrong'],
          };
        }
        const userInfo: UserInfo = userInfoResponse.body;
        userLoggedIn(userInfo);
        return { success: true };
      } catch (err) {
        return { success: false, messages: [`${err.message}`] };
      }
    },
    [userLoggedIn],
  );
}
