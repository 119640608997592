import { makeStyles, Theme } from '@material-ui/core';
import * as Colors from '@pxblue/colors';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    optionsContainer: {
      display: 'inline-flex',
      flexWrap: 'wrap',
      width: '100%'
    },
    contentLabel: {
      marginBottom: '23px',
      color: theme.palette.text.primary,
    },
    checkmarkBubble: {
      width: '22px',
      height: '22px',
      position: 'absolute',
      left: '-1.48%',
      right: '93.33%',
      top: '-2.23%',
      bottom: '89.94%',
      boxSizing: 'border-box',
      boxShadow: '1px 1px 2px rgba(0, 54, 94, 0.1)',
      borderRadius: '11px',
      border: `2px solid ${Colors.gray[200]}`,
      background: Colors.white[200],
    },
    unselectedCheckmarkBubble: {},
    selectedCheckmarkBubble: {
      border: `2px solid ${Colors.white[50]}`,
      background: theme.palette.primary.main
    },
    selectedCheckmark: {
      transform: 'rotate(45deg)',
      borderBottom: `2px solid ${Colors.white[50]}`,
      borderRight: `2px solid ${Colors.white[50]}`,
      position: 'absolute',
      width: '8px',
      height: '18px',
      marginLeft: '7px',
      marginTop: '-3px',
      filter: 'drop-shadow(0px 0px 1px rgba(0, 54, 94, 0.2))',
    },
    imageOptionContainer: { display: 'inline-block' },
    imageOption: {
      position: 'relative',
      background: Colors.white[200],
      width: '270px',
      height: '180px',
      marginRight: '32px',
      marginBottom: '32px',
      boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2)',
      borderRadius: '2px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '16px',
      '&:hover $unselectedCheckmarkBubble': {
        backgroundColor: Colors.blue[50],
        border: `2px solid ${Colors.blue[100]}`,
      },
      '&:hover': {
        backgroundColor: Colors.blue[50],
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)'
      },
    },
    iconContainer: {
      marginTop: '16px',
      minHeight: '78px',
      maxHeight: '78px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    selectedOption: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
      boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14)',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14)',
      },
    },
    titleText: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.16px',
      textTransform: 'uppercase',
      marginTop: '24px'
    },
    selectedTitleText: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    unselectedTitleText: {
      color: Colors.blue[700],
    },
    subtitleText: {
      fontSize: '14px',
    }
  };
});
