import { makeStyles, Theme } from '@material-ui/core';
import * as Colors from '@pxblue/colors';
import { Gutters } from '../../styles/gutters';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    createAccountHeaderContainer: {
      borderBottom: `1px solid ${Colors.gray[50]}`,
    },
    createAccountHeader: {
      background: Colors.white[50],
      ...Gutters(theme),
      minWidth: '960px',
      maxWidth: '1500px',
      width: '100%',
      marginRight: 'auto',
      marginLeft: 'auto',
      paddingBottom: '52px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    createAccountDescription: {
      maxWidth: '670px',
    },
    createAccountHeading: { paddingTop: '52px', paddingBottom: '8px' },
    createAccountSubheading: {
      paddingBottom: '32px',
    },
    createAccountSubtext: {
      paddingBottom: '16px',
    },
    createAccountSignIn: {
      marginLeft: '30px',
      width: '470px',
      height: '165px',
      backgroundColor: Colors.blue[50],
      borderRadius: '2px',
      textAlign: 'center',
      paddingTop: '32px',
      paddingBottom: '32px',
      marginTop: 'auto',
    },
    anotherContainer: {
      background: Colors.white[100],
      flexGrow: 1,
    },
    createAccountDetailsContainer: {
      ...Gutters(theme),
      marginRight: 'auto',
      marginLeft: 'auto',
      paddingTop: '32px',
      paddingBottom: '32px',
      minWidth: '960px',
      maxWidth: '1500px',
      width: '100%',
      flexGrow: 1,
    },
    createAccountDetails: {
      width: '570px',
    },
    createAccountField: {
      width: '100%',
    },
    accountinformationField: {
      marginTop: '32px',
    },
    verificationCodeContainer: {
      width: '370px',
      display: 'flex',
      alignItems: 'flex-end',
    },
    verificationCodeField: {
      flexGrow: 1,
      marginRight: '16px',
    },
    emailHeading: {
      fontSize: '12px',
      color: Colors.gray[800],
    },
    disclaimer: {
      fontSize: '10px',
      color: Colors.gray[800],
    },
    submitButton: {
      marginTop: '48px',
      marginRight: '16px',
    },
    errorText: {
      color: theme.palette.error.main,
    },
    passwordFieldHeader: {
      marginTop: '64px',
    },
    philWarning: {
      marginTop: '16px',
      textAlign: 'center',
      width: '370px',
      background: Colors.red[500],
    },
    nameFields: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    firstNameField: {
      marginRight: '30px',
    },
    accountCreatedMessage: {
      marginTop: '16px',
      marginBottom: '32px',
    },
  };
});
