import React from 'react';
import { Link } from 'react-router-dom';
import { AddTransmissionForm } from '../../components/add-transmission-form';
import { Button, Grid } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { DefaultPage } from '../../components/default-page';

export function AddTransmissionPage(props: any) {
  return (
    <DefaultPage>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        style={{ padding: '10px' }}
      >
        <Link
          to="/transmission-data/"
          style={{ textDecoration: 'none' }}
          data-testid="back-button"
        >
          <Button
            color="secondary"
            style={{ textTransform: 'inherit', marginLeft: '10px' }}
          >
            <ArrowBackIcon style={{ marginRight: '5px' }} />
            Back
          </Button>
        </Link>
      </Grid>
      <AddTransmissionForm />
    </DefaultPage>
  );
}
