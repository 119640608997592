import React, { useState } from 'react';
import {
  PureSurveyShell,
  PropsWithSurveyPageChildren,
} from './pure-survey-shell';
import { SubmissionState } from '../pure-transmission-form/transmission-form';

export const SurveyShell = (
  props: PropsWithSurveyPageChildren<{
    onSubmit: () => void;
    cancelLink: string;
    submissionState: SubmissionState;
  }>,
) => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const totalPages = React.Children.count(props.children);
  const onBack = () => setCurrentPage(currentPage - 1);
  const onNext = () => setCurrentPage(currentPage + 1);

  return (
    <PureSurveyShell
      currentPage={currentPage}
      onBackClicked={onBack}
      onNextClicked={currentPage === totalPages - 1 ? props.onSubmit : onNext}
      cancelLink={props.cancelLink}
      loading={props.submissionState === 'submitting'}
    >
      {props.children}
    </PureSurveyShell>
  );
};
export type SurveyPageProps = React.PropsWithChildren<{
  isFilled: boolean;
}>;
export const SurveyPage = (props: SurveyPageProps) => <>{props.children}</>;
