import React, { useState, useEffect, CSSProperties } from "react";
import {
  FormControl,
  TextField,
  CircularProgress,
  Card,
  CardContent,
  Typography,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  Slider,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Tooltip,
  IconButton,
  Divider,
  Checkbox,
  Theme,
} from "@material-ui/core";
import {
  Transmission,
  SupportValue,
  DEFAULT_TRANSMISSION,
  PerformanceGoalPoints,
  AxleRatioThresholds,
  TransmissionConstants,
} from "../../domain/transmission";
import { EatonButton } from "../button";
import { useStyles } from "./styles";
import { EatonSnackBar, SnackBarType } from "../snack-bar";
import DeleteIcon from "@material-ui/icons/Delete";
import _ from "lodash";
import {
  Controller,
  NestDataObject,
  FieldError,
  FieldErrors,
  useForm,
} from "react-hook-form";
import { cssClassName } from "../pure-survey";
import { Prompt } from "react-router";
import { SubmissionState, TransmissionFormData } from "./transmission-form";
import * as yup from "yup";
import { RichTextEditor } from "../rich-text-editor";
import { TransmissionResult, MatchType } from "../transmission-result";
import { FormattedNumberInput } from "../number-input";
import { timeStamp } from "console";

type OwnProps = {
  transmission?: Omit<Transmission, "id">;
  onSubmit: any;
  header: string;
  submissionState: SubmissionState;
  clearSubmissionState: () => void;
  _testShowErrors?: boolean;
};

const UNSAVED_CHANGES_WARNING =
  "Are you sure you want to leave? Your changes have not been saved.";

const MAX_CHARS_ERROR = (max: number) =>
  `Field cannot exceed ${max} characters`;
const NUMBER_RANGE_ERROR = (min: number, max: number, fieldName?: string) =>
  `${
    fieldName ? `${fieldName} value ` : "Value"
  } must be within the range of ${min} to ${max}`;

const REQUIRED_ERROR = "Required field";

const SUPPORTED_FORMATS = ["image/png", "image/jpg", "image/jpeg", "image/gif"];

const MAX_FILE_SIZE_MB = 5;

export const transmissionSchema = yup.object().shape({
  learnMoreUrl: yup
    .string()
    .required(REQUIRED_ERROR)
    .url(
      "Learn More URL must be a valid URL, including HTTP or HTTPS, e.g. http://something.com"
    ),
  imageFile: yup
    .mixed()
    .test(
      "fileSize",
      `File is too large. Max size is ${MAX_FILE_SIZE_MB}MB.`,
      (value) =>
        value === undefined ||
        value.length === 0 ||
        value[0].size <= MAX_FILE_SIZE_MB * 1024 * 1024
    )
    .test(
      "fileType",
      `Unsupported file format. Accepted file types: ${SUPPORTED_FORMATS.map(
        (format) => format.replace("image/", ".")
      ).join(", ")}`,
      (value) =>
        value[0] === undefined ||
        value.length === 0 ||
        SUPPORTED_FORMATS.includes(value[0].type)
    ),
  modelTorques: yup.array().min(1, "Add at least one model"),
  name: yup.string().required(REQUIRED_ERROR).max(200, MAX_CHARS_ERROR(200)),
  description: yup
    .string()
    .required(REQUIRED_ERROR)
    .max(100000, MAX_CHARS_ERROR(100000)),
  performanceGoalPoints: yup.object().shape({
    acceleration: yup
      .number()
      .typeError("Required field")
      .min(0, NUMBER_RANGE_ERROR(0, 100))
      .max(100, NUMBER_RANGE_ERROR(0, 100))
      .required(REQUIRED_ERROR),
    fuelEconomy: yup
      .number()
      .typeError("Required field")
      .min(0, NUMBER_RANGE_ERROR(0, 100))
      .max(100, NUMBER_RANGE_ERROR(0, 100))
      .required(REQUIRED_ERROR),
    lowSpeedManeuvers: yup
      .number()
      .typeError("Required field")
      .min(0, NUMBER_RANGE_ERROR(0, 100))
      .max(100, NUMBER_RANGE_ERROR(0, 100))
      .required(REQUIRED_ERROR),
    price: yup
      .number()
      .typeError("Required field")
      .min(0, NUMBER_RANGE_ERROR(0, 100))
      .max(100, NUMBER_RANGE_ERROR(0, 100))
      .required(REQUIRED_ERROR),
    tripTime: yup
      .number()
      .typeError("Required field")
      .min(0, NUMBER_RANGE_ERROR(0, 100))
      .max(100, NUMBER_RANGE_ERROR(0, 100))
      .required(REQUIRED_ERROR),
  }),
  axleRatioThresholds: yup.object().shape({
    defaultBottom: yup
      .number()
      .typeError("Required field")
      .min(2, NUMBER_RANGE_ERROR(2, 7, "Minimum"))
      .max(7, NUMBER_RANGE_ERROR(2, 7, "Max"))
      .required(REQUIRED_ERROR)
      .test(
        "defaultBottom-above",
        "Minimum value must be lower than maximum",
        function (value: number) {
          const otherValue = this.parent.defaultTop as any;
          return value <= otherValue;
        }
      ),
    defaultTop: yup
      .number()
      .typeError("Required field")
      .min(2, NUMBER_RANGE_ERROR(2, 7, "Minimum"))
      .max(7, NUMBER_RANGE_ERROR(2, 7, "Maximum"))
      .required(REQUIRED_ERROR),
    mediumBottom: yup
      .number()
      .typeError("Required field")
      .min(2, NUMBER_RANGE_ERROR(2, 7, "Minimum"))
      .max(7, NUMBER_RANGE_ERROR(2, 7, "Maximum"))
      .required(REQUIRED_ERROR)
      .test(
        "mediumBottom-above",
        "Minimum value must be lower than maximum",
        function (value: number) {
          const otherValue = this.parent.mediumTop as any;
          return value <= otherValue;
        }
      ),
    mediumTop: yup
      .number()
      .typeError("Required field")
      .min(2, NUMBER_RANGE_ERROR(2, 7, "Minimum"))
      .max(7, NUMBER_RANGE_ERROR(2, 7, "Maximum"))
      .required(REQUIRED_ERROR),
    highBottom: yup
      .number()
      .typeError("Required field")
      .min(2, NUMBER_RANGE_ERROR(2, 7, "Minimum"))
      .max(7, NUMBER_RANGE_ERROR(2, 7, "Maximum"))
      .required(REQUIRED_ERROR)
      .test(
        "highBottom-above",
        "Minimum value must be lower than maximum",
        function (value: number) {
          const otherValue = this.parent.highTop as any;
          return value <= otherValue;
        }
      ),
    highTop: yup
      .number()
      .typeError("Required field")
      .min(2, NUMBER_RANGE_ERROR(2, 7, "Minimum"))
      .max(7, NUMBER_RANGE_ERROR(2, 7, "Maximum"))
      .required(REQUIRED_ERROR),
    extremeBottom: yup
      .number()
      .typeError("Required field")
      .min(2, NUMBER_RANGE_ERROR(2, 7, "Minimum"))
      .max(7, NUMBER_RANGE_ERROR(2, 7, "Maximum"))
      .required(REQUIRED_ERROR)
      .test(
        "extremeBottom-above",
        "Minimum value must be lower than maximum",
        function (value: number) {
          const otherValue = this.parent.extremeTop as any;
          return value <= otherValue;
        }
      ),
    extremeTop: yup
      .number()
      .typeError("Required field")
      .min(2, NUMBER_RANGE_ERROR(2, 7, "Minimum"))
      .max(7, NUMBER_RANGE_ERROR(2, 7, "Maximum"))
      .required(REQUIRED_ERROR),
  }),
});

export function PureTransmissionForm(props: OwnProps) {
  const transmission = props.transmission || DEFAULT_TRANSMISSION;
  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    reset,
    clearError,
    setError,
    formState,
    getValues,
    watch,
    triggerValidation,
  } = useForm<TransmissionFormData>({
    validationSchema: transmissionSchema,
    reValidateMode: "onSubmit",
    defaultValues: { ...transmission, imageFile: undefined },
  });

  const [name, setName] = useState<string>(props.transmission?.name || "");
  const debounceName = React.useCallback(
    _.debounce(setName, 2000, { trailing: true }),
    []
  );

  const [description, setDescription] = useState<string>(
    props.transmission?.description || ""
  );
  const debounceDescription = React.useCallback(
    _.debounce(setDescription, 2000, {
      trailing: true,
    }),
    []
  );

  const [learnMoreUrl, setLearnMoreUrl] = useState<string>(
    props.transmission?.learnMoreUrl || ""
  );
  const debounceLearnMoreUrl = React.useCallback(
    _.debounce(setLearnMoreUrl, 2000, { trailing: true }),
    []
  );

  const modelTorques = watch("modelTorques");
  const axleRatioThresholds = watch("axleRatioThresholds");

  useEffect(() => {
    if (props.submissionState === "success") {
      if (!props.transmission) {
        reset({ ...DEFAULT_TRANSMISSION, imageFile: undefined });
        debounceDescription.flush();
        debounceName.flush();
        debounceLearnMoreUrl.flush();
        setName("");
        setDescription("");
        setLearnMoreUrl("");
        setPreviewImage(null);
      } else {
        reset(getValues({ nest: true }));
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [props.submissionState]);

  useEffect(() => {
    const validate = async () => {
      if (props._testShowErrors) {
        await triggerValidation();
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    validate();
  }, [props._testShowErrors]);

  useEffect(() => {
    return () => {
      // Clean up debounced useState calls
      debounceDescription.flush();
      debounceName.flush();
      debounceLearnMoreUrl.flush();
    };
  });
  // Lifted from https://github.com/jacobbuck/react-beforeunload
  useEffect(() => {
    if (formState.dirty) {
      const handleBeforeunload = (event: BeforeUnloadEvent) => {
        event.returnValue = UNSAVED_CHANGES_WARNING;
        return UNSAVED_CHANGES_WARNING;
      };

      window.addEventListener("beforeunload", handleBeforeunload);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeunload);
      };
    }
  }, [formState.dirty]);

  const [previewImage, setPreviewImage] = useState<string | null>(null);

  const classes = useStyles({});

  const SupportedControl = (supportedControlProps: { name: string }) => (
    <RadioGroup
      data-testid={cssClassName(supportedControlProps.name)}
      aria-labelledby={cssClassName(supportedControlProps.name)}
      defaultValue={SupportValue.NotSupported}
      name={supportedControlProps.name}
    >
      {Object.values(SupportValue).map((value) => (
        <FormControlLabel
          disabled={props.submissionState === "submitting"}
          data-testid={_.kebabCase(`${supportedControlProps.name}-${value}`)}
          className={_.kebabCase(`${supportedControlProps.name}-${value}`)}
          key={value}
          control={<Radio />}
          value={value}
          label={_.startCase(value)}
        />
      ))}
    </RadioGroup>
  );

  const TransmissionTextField = (textFieldProps: {
    name: string;
    maxLength: number;
    style?: CSSProperties;
  }) => (
    <TextField
      onBlur={(e) => {
        e.target.value = e.target.value.trim();
      }}
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          (e.target as any).value = (e.target as any).value.trim();
        }
      }}
      onChange={(event) => {
        if (textFieldProps.name === "name") debounceName(event.target.value);
        if (textFieldProps.name === "learnMoreUrl") {
          debounceLearnMoreUrl(event.target.value);
        }
        return event;
      }}
      disabled={props.submissionState === "submitting"}
      label={_.startCase(textFieldProps.name)}
      name={textFieldProps.name}
      id={_.kebabCase(textFieldProps.name)}
      className={`transmission-${textFieldProps.name} ${classes.inputField}`}
      multiline={textFieldProps.maxLength > 300}
      inputRef={register}
      inputProps={{
        maxLength: textFieldProps.maxLength,
        "data-testid": _.kebabCase(textFieldProps.name),
      }}
      error={
        errors[
          textFieldProps.name as keyof NestDataObject<TransmissionFormData>
        ] !== undefined
      }
      helperText={
        errors[
          textFieldProps.name as keyof NestDataObject<TransmissionFormData>
        ] &&
        (errors[
          textFieldProps.name as keyof NestDataObject<TransmissionFormData>
        ] as FieldError).message
      }
    />
  );

  const FormField = (
    field: Exclude<keyof typeof DEFAULT_TRANSMISSION, "id">
  ) => {
    switch (field) {
      case "name":
        return (
          <div style={{ marginBottom: "0px" }}>
            {TransmissionTextField({ maxLength: 200, name: field })}
          </div>
        );

      case "learnMoreUrl":
        return (
          <div style={{ marginBottom: "8px" }}>
            {TransmissionTextField({ maxLength: 200, name: field })}
          </div>
        );

      case "description":
        return (
          <>
            <div style={{ marginBottom: "16px" }}>
              <Subtitle text="Description" id="description" />
              <br />
              {errors.description && (
                <Typography className={classes.errorText}>
                  {errors.description.message}
                </Typography>
              )}
              <Controller
                as={<RichTextEditor />}
                name="description"
                control={control}
                onChange={(value: any) => {
                  debounceDescription(value[0]);
                  return value[0];
                }}
              />
            </div>
            <div>
              <br />
              <Subtitle text="Preview Spec Result" id="preview" />
              <br />
              <Typography>
                <strong>Note:</strong> Transmission Model and Suggested Axle
                Ratio Range in this preview is determined by the first available
                for this family. Actual results will vary based on the survey.
              </Typography>
              <br />
              <TransmissionResult
                matchType={MatchType.TopMatch}
                transmission={{
                  image:
                    (!errors.imageFile &&
                      (previewImage ||
                        (props.transmission?.image &&
                          props.transmission.image.content))) ||
                    null,
                  name,
                  description,
                  modelName:
                    modelTorques && modelTorques.length > 0
                      ? modelTorques[0].name
                      : "None",
                  axleRatio:
                    axleRatioThresholds.defaultBottom &&
                    axleRatioThresholds.defaultTop
                      ? `${axleRatioThresholds.defaultBottom} - ${axleRatioThresholds.defaultTop}`
                      : "None",
                  learnMoreUrl,
                }}
              />
              <div className={classes.imageButtonContainer}>
                <EatonButton component="label" className={classes.imageButton}>
                  <input
                    type="file"
                    name="imageFile"
                    aria-labelledby="image"
                    accept={`${SUPPORTED_FORMATS.join(", ")}`}
                    id="imageInput"
                    ref={register}
                    style={{
                      display: "none",
                    }}
                    onChange={async (event) => {
                      if (
                        event.target &&
                        event.target.files &&
                        event.target.files.length > 0
                      ) {
                        const file = event.target.files[0];
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onloadend = function (e) {
                          setPreviewImage(reader.result as string);
                        };
                      } else {
                        setPreviewImage(null);
                      }
                      await triggerValidation("imageFile");
                    }}
                  />
                  Choose Image
                </EatonButton>
                {errors.imageFile && (
                  <span
                    className={errors.imageFile ? classes.largeErrorText : ""}
                  >
                    {(errors.imageFile as any).message}
                  </span>
                )}
              </div>
            </div>
          </>
        );

      case "maxSupportedCruiseSpeed":
        return (
          <Controller
            defaultValue={transmission.maxSupportedCruiseSpeed}
            onChange={(value: number[]) => {
              return value[0];
            }}
            name={field}
            control={control}
            as={
              <SettingSlider
                loading={props.submissionState === "submitting"}
                labelId={field}
                name={field}
                max={TransmissionConstants.MAX_SPEED}
                min={TransmissionConstants.MIN_SPEED}
                step={1}
                units="mph"
              />
            }
          />
        );
      case "maxSupportedEngineHp":
        return (
          <Controller
            defaultValue={transmission.maxSupportedEngineHp}
            onChange={(value: number[]) => {
              return value[0];
            }}
            name={field}
            control={control}
            as={
              <SettingSlider
                loading={props.submissionState === "submitting"}
                labelId={field}
                name={field}
                max={TransmissionConstants.MAX_HP}
                min={TransmissionConstants.MIN_HP}
                step={5}
                units="HP"
              />
            }
          />
        );
      case "maxSupportedGcw":
        return (
          <Controller
            defaultValue={transmission.maxSupportedGcw}
            onChange={(value: number[]) => {
              return value[0];
            }}
            name={field}
            control={control}
            as={
              <SettingSlider
                allowNoValue={true}
                loading={props.submissionState === "submitting"}
                labelId={field}
                name={field}
                max={TransmissionConstants.MAX_WEIGHT}
                min={TransmissionConstants.MIN_WEIGHT}
                step={1000}
                units="lbs."
              />
            }
          />
        );
      case "maxSupportedMaxSpeed":
        return (
          <Controller
            defaultValue={transmission.maxSupportedMaxSpeed}
            onChange={(value: number[]) => {
              return value[0];
            }}
            name={field}
            control={control}
            as={
              <SettingSlider
                loading={props.submissionState === "submitting"}
                labelId={field}
                name={field}
                max={TransmissionConstants.MAX_SPEED}
                min={TransmissionConstants.MIN_SPEED}
                step={1}
                units="mph"
              />
            }
          />
        );
      case "performanceGoalPoints":
        register({ name: `${field}.id` });
        return Object.keys(DEFAULT_TRANSMISSION.performanceGoalPoints).map(
          (subfield) => (
            <div key={subfield}>
              <Controller
                name={`${field}.${subfield}`}
                control={control}
                defaultValue={
                  transmission.performanceGoalPoints[
                    subfield as keyof PerformanceGoalPoints
                  ]
                }
                onChange={(value: string[]) => {
                  const valueString = value[0];
                  if (valueString === "") {
                    return "";
                  }
                  return parseInt(valueString);
                }}
                as={
                  <SettingNumberInput
                    loading={props.submissionState === "submitting"}
                    title={_.startCase(subfield)}
                    name={`${field}.${subfield}`}
                    errors={errors}
                  />
                }
              />
              <br />
            </div>
          )
        );
      case "modelTorques":
        return (
          <Controller
            defaultValue={transmission.modelTorques}
            as={
              <ModelList
                loading={props.submissionState === "submitting"}
                value={[]}
                error={(errors.modelTorques as any)?.message}
                clearError={() => clearError(field)}
                setError={setError}
                setValue={async (value) => {
                  await setValue(field, value);
                }}
                name={field}
              />
            }
            rules={{ minLength: 1 }}
            name={field}
            control={control}
          />
        );
      case "axleRatioThresholds":
        register({ name: `${field}.id` });
        return (
          <>
            <AxleRange
              loading={props.submissionState === "submitting"}
              title="Default"
              fieldNameMin="axleRatioThresholds.defaultBottom"
              fieldNameMax="axleRatioThresholds.defaultTop"
              defaultMinValue={transmission.axleRatioThresholds.defaultBottom}
              defaultMaxValue={transmission.axleRatioThresholds.defaultTop}
              min={2}
              max={7}
              control={control}
              errors={errors}
            />
            <AxleRange
              loading={props.submissionState === "submitting"}
              title="Medium"
              fieldNameMin="axleRatioThresholds.mediumBottom"
              fieldNameMax="axleRatioThresholds.mediumTop"
              defaultMinValue={transmission.axleRatioThresholds.mediumBottom}
              defaultMaxValue={transmission.axleRatioThresholds.mediumTop}
              min={2}
              max={7}
              control={control}
              errors={errors}
            />
            <AxleRange
              loading={props.submissionState === "submitting"}
              title="High"
              fieldNameMin="axleRatioThresholds.highBottom"
              fieldNameMax="axleRatioThresholds.highTop"
              defaultMinValue={transmission.axleRatioThresholds.highBottom}
              defaultMaxValue={transmission.axleRatioThresholds.highTop}
              min={2}
              max={7}
              control={control}
              errors={errors}
            />
            <AxleRange
              loading={props.submissionState === "submitting"}
              title="Extreme"
              fieldNameMin="axleRatioThresholds.extremeBottom"
              fieldNameMax="axleRatioThresholds.extremeTop"
              defaultMinValue={transmission.axleRatioThresholds.extremeBottom}
              defaultMaxValue={transmission.axleRatioThresholds.extremeTop}
              min={2}
              max={7}
              control={control}
              errors={errors}
            />
          </>
        );
      case "image":
        return null;
      default:
        register({ name: `${field}.id` });
        return Object.keys(DEFAULT_TRANSMISSION[field]).map((subfield) => (
          <div key={subfield} className={`${field}_${subfield}`}>
            <FormControl style={{ paddingTop: "30px" }}>
              <FormLabel id={cssClassName(`${field}.${subfield}`)}>
                {_.startCase(subfield)}
              </FormLabel>
              <Controller
                as={SupportedControl({
                  name: `${field}.${subfield}`,
                })}
                name={`${field}.${subfield}`}
                control={control}
                defaultValue={SupportValue.NotSupported}
              />
            </FormControl>
            <br />
          </div>
        ));
    }
  };

  register({ name: "id" });

  return (
    <div>
      <Prompt message={UNSAVED_CHANGES_WARNING} when={formState.dirty} />
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5" color="inherit" className={classes.header}>
            {props.header}
          </Typography>

          <form onSubmit={handleSubmit(props.onSubmit)}>
            {Object.keys(DEFAULT_TRANSMISSION)
              .filter((key) => key !== "id")
              .map((key) => (
                <div key={key}>
                  {key !== "name" &&
                    key !== "learnMoreUrl" &&
                    key !== "description" &&
                    key !== "image" && (
                      <Subtitle text={_.startCase(key)} id={key} />
                    )}
                  {FormField(key as keyof typeof DEFAULT_TRANSMISSION)}
                  <br />
                </div>
              ))}

            <div className={classes.wrapper} style={{ marginTop: "30px" }}>
              {Object.entries(errors).length > 0 && (
                <Typography className={classes.largeErrorText}>
                  There are unresolved errors. Review your inputs and try again.
                </Typography>
              )}
              <EatonButton
                disabled={props.submissionState === "submitting"}
                type="submit"
                value="Submit"
                data-testid="submit-button"
                className={classes.button}
              >
                Submit
                {props.submissionState === "submitting" && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </EatonButton>
            </div>
          </form>
        </CardContent>
      </Card>
      {props.submissionState === "success" ? (
        <EatonSnackBar
          type={SnackBarType.Success}
          message={"The Transmission has been saved."}
          onClose={props.clearSubmissionState}
          showSnack={props.submissionState === "success"}
        />
      ) : props.submissionState === "fail" ? (
        <EatonSnackBar
          type={SnackBarType.Error}
          message={"An unknown error occurred."}
          onClose={props.clearSubmissionState}
          showSnack={props.submissionState === "fail"}
        />
      ) : (
        <div />
      )}
    </div>
  );
}

const sliderStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      width: "400px",
    },
    valueLabel: {
      // eslint-disable-next-line quote-props
      top: -28,
      // eslint-disable-next-line quote-props
      fontSize: 15,
      "& *": {
        background: "transparent",
        color: "#000",
      },
    },
  };
});

const SettingSlider = (props: {
  labelId: string;
  max: number;
  min: number;
  step?: number;
  units: string;
  name: string;
  value?: number | null;
  onChange?: (value: number[] | number | null) => void;
  loading: boolean;
  allowNoValue?: boolean;
}) => {
  const sliderClasses = sliderStyles();
  const classes = useStyles();

  const marks = [
    {
      value: props.min,
      label: `${props.min.toLocaleString()} ${props.units}`,
    },
    {
      value: props.max,
      label: `${props.max.toLocaleString()} ${props.units}`,
    },
  ];

  const onChangeHandle = (newValue: number | null) => {
    props.onChange && props.onChange(newValue);
  };

  return (
    <>
      {props.allowNoValue && (
        <FormControlLabel
          control={
            <Checkbox
              value="not important"
              checked={props.value === null}
              onChange={async (event) => {
                onChangeHandle(event.target.checked ? null : props.min);
              }}
            />
          }
          label={`No ${_.startCase(props.name)}`}
        />
      )}
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={5}
        style={{ paddingTop: "30px" }}
      >
        <Grid item>
          <Slider
            disabled={props.loading || props.value === null}
            className={_.kebabCase(`${props.name}-slider`)}
            onChange={(event, value) => {
              props.onChange && props.onChange(value);
            }}
            step={props.step}
            style={{ marginLeft: "30px", marginRight: "20px" }}
            value={props.value || props.min}
            name={props.name}
            min={props.min}
            max={props.max}
            classes={sliderClasses}
            valueLabelDisplay={props.value === null ? "off" : "on"}
            marks={props.value === null ? undefined : marks}
          />
        </Grid>
        <Grid item style={{ display: "flex", alignItems: "flex-end" }}>
          <FormattedNumberInput
            value={props.value}
            onChange={(newValue: number) => onChangeHandle(newValue)}
            step={props.step}
            min={props.min}
            max={props.max}
            data-testid={cssClassName(props.name)}
            className={`${cssClassName(props.name)}-input`}
            ariaLabelledBy={props.labelId}
            dataTestId={props.labelId}
            disabled={props.loading || props.value === null}
          />
          <div className={classes.unitLabel}>
            <Typography>{props.units}</Typography>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const SettingNumberInput = (props: {
  title: string;
  name: string;
  className?: string;
  value?: number;
  onChange?: (value: number[] | number | string) => void;
  loading: boolean;
  errors: FieldErrors<TransmissionFormData>;
}) => {
  const errorMessage =
    props.errors.performanceGoalPoints &&
    props.errors.performanceGoalPoints[
      props.name.split(".")[1] as keyof NestDataObject<PerformanceGoalPoints>
    ]?.message;

  return (
    <>
      <FormControlLabel
        disabled={props.loading}
        label={props.title}
        style={{ marginTop: "10px", marginLeft: "5px" }}
        control={
          <TextField
            style={{ width: "40px", marginRight: "10px" }}
            value={props.value}
            type="number"
            className={_.kebabCase(props.name)}
            id={_.kebabCase(props.name)}
            onChange={(event) => {
              const value = event.target.value;
              props.onChange && props.onChange(value);
            }}
            inputProps={{
              type: "number",
              "data-testid": _.kebabCase(props.name),
            }}
            error={!!errorMessage}
          />
        }
      />
      <Typography variant="caption" color="error">
        {errorMessage && <br />}
        {errorMessage}
      </Typography>
    </>
  );
};

export type TransmissionModels = Transmission["modelTorques"];

const ModelList = (props: {
  value: TransmissionModels;
  setValue: (value: TransmissionModels) => void;
  name: string;
  error?: string;
  clearError: () => void;
  loading: boolean;
  setError: (name: string, type: string, message?: string) => void;
}) => {
  const [transmissionModelName, setTransmissionModelName] = useState<string>(
    ""
  );
  const [transmissionTorque, setTransmissionTorque] = useState<string>("");

  const maxTorqueValue = 2250;
  const minTorqueValue = 1000;

  const canSubmit =
    transmissionModelName.length > 0 && transmissionTorque.length > 0;

  const add = async () => {
    const candidateModel = {
      name: transmissionModelName,
      maxTorque: parseInt(transmissionTorque),
      isDeleted: false,
      updatedBy:'',
      timeStamp: new Date(),
    };

    if (duplicateModelExists(candidateModel)) {
      props.setError(
        props.name,
        "duplicate",
        "Cannot have two models with the same name under a single family."
      );
    } else if (
      candidateModel.maxTorque > maxTorqueValue ||
      candidateModel.maxTorque < minTorqueValue
    ) {
      props.setError(
        props.name,
        "maximum",
        "Torque must be within the range of 1000 to 2250"
      );
    } else {
      const newModelList = props.value.concat(candidateModel);
      await props.setValue(newModelList);
      props.clearError();
      setTransmissionModelName("");
      setTransmissionTorque("");
    }
  };

  const duplicateModelExists = (model: {
    name: string;
    maxTorque: number;
  }): boolean => {
    return !!_.find(props.value, (m) => m.name === model.name);
  };

  return (
    <div style={{ width: "100%" }}>
      <List className="model-torque-list">
        {props.value.length > 0 ? (
          props.value.map((value: TransmissionModels[0]) => {
            return (
              <div key={`${value.name}-div`}>
                <ListItem key={value.name}>
                  <ListItemText
                    key={value.name}
                    primary={`${value.name}`}
                    secondary={
                      <React.Fragment key={`${value.name}-fragment`}>
                        <Typography
                          key={`${value.name}-typography`}
                          component="span"
                          variant="caption"
                          color="textPrimary"
                          style={{ marginLeft: "10px" }}
                        >
                          Torque -
                        </Typography>
                        {` ${value.maxTorque} lbs.-ft`}
                      </React.Fragment>
                    }
                  />
                  <ListItemIcon key={`${value.name}-listicon`}>
                    <Tooltip
                      title="Delete"
                      placement="bottom"
                      key={`${value.name}-tooltip`}
                    >
                      <span>
                        <IconButton
                          disabled={props.loading}
                          aria-label={`Delete ${value.name}`}
                          data-testid="delete-button"
                          key={`${value.name}-iconbutton`}
                          onClick={async () => {
                            const newModelList = _.without(props.value, value);
                            await props.setValue(newModelList);
                          }}
                        >
                          <DeleteIcon
                            color="primary"
                            key={`${value.name}-delete`}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </ListItemIcon>
                </ListItem>
              </div>
            );
          })
        ) : (
          <Typography className="modelTorque-empty-text">
            No Transmission Models Found in Family
          </Typography>
        )}
      </List>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="baseline"
        style={{ marginTop: "30px" }}
      >
        <TextField
          disabled={props.loading}
          className="model-name"
          error={!!props.error}
          helperText={props.error}
          id="model-name"
          name="model-name"
          label="Transmission Model Name"
          style={{ width: "60%" }}
          value={transmissionModelName}
          onChange={(action) => setTransmissionModelName(action.target.value)}
          onBlur={(e) => {
            setTransmissionModelName(transmissionModelName.trim());
          }}
          onKeyDown={async (event) => {
            if (event.keyCode === 13) {
              event.preventDefault(); // Ignore form submission
              setTransmissionModelName(transmissionModelName.trim());
              if (canSubmit && transmissionModelName.trim() !== "") {
                await add();
              }
            }
          }}
          inputProps={{ "data-testid": "model-name" }}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          disabled={props.loading}
          className="model-torque"
          id="model-torque"
          name="model-torque"
          label="Torque"
          multiline={false}
          style={{ width: "20%" }}
          type="number"
          value={transmissionTorque}
          onChange={(action) => setTransmissionTorque(`${action.target.value}`)}
          onKeyDown={async (event) => {
            if (event.keyCode === 13) {
              event.preventDefault(); // Ignore form submission
              if (canSubmit) {
                await add();
              }
            }
          }}
          inputProps={{
            "data-testid": "model-torque",
            min: minTorqueValue,
            max: maxTorqueValue,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">lbs.-ft</InputAdornment>
            ),
          }}
          InputLabelProps={{ shrink: true }}
          error={!!props.error}
        />
        <EatonButton
          data-testid="model-add-button"
          onClick={add}
          disabled={!canSubmit || props.loading}
        >
          Add
        </EatonButton>
      </Grid>
    </div>
  );
};

const AxleRange = (axleProps: {
  loading: boolean;
  title: string;
  fieldNameMin: string;
  fieldNameMax: string;
  defaultMinValue: number;
  defaultMaxValue: number;
  control: any;
  min: number;
  max: number;
  errors: FieldErrors<TransmissionFormData>;
}) => {
  const errorMessageMin =
    axleProps.errors.axleRatioThresholds &&
    axleProps.errors.axleRatioThresholds[
      axleProps.fieldNameMin.split(".")[1] as keyof NestDataObject<
        AxleRatioThresholds
      >
    ]?.message;
  const errorMessageMax =
    axleProps.errors.axleRatioThresholds &&
    axleProps.errors.axleRatioThresholds[
      axleProps.fieldNameMax.split(".")[1] as keyof NestDataObject<
        AxleRatioThresholds
      >
    ]?.message;

  const InputField = (inputFieldProps: {
    onChange?: (value: number | string) => void;
    value?: number;
    fieldName: string;
    labelName: string;
  }) => (
    <TextField
      aria-labelledby={inputFieldProps.labelName}
      style={{ width: "40px", marginRight: "10px" }}
      value={inputFieldProps.value}
      type="number"
      inputProps={{
        name: inputFieldProps.fieldName,
        step: "0.1",
        type: "number",
        "data-testid": _.kebabCase(inputFieldProps.fieldName),
      }}
      onChange={(event) => {
        const value = event.target.value;
        inputFieldProps.onChange && inputFieldProps.onChange(value);
      }}
      error={!!errorMessageMin || !!errorMessageMax}
    />
  );
  return (
    <>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-end"
        style={{ marginTop: 20 }}
      >
        <Grid item style={{ width: "20%" }}>
          <Typography variant="subtitle1" id={cssClassName(axleProps.title)}>
            {axleProps.title}
          </Typography>
        </Grid>
        <Grid item style={{ marginRight: 10 }}>
          <Controller
            defaultValue={axleProps.defaultMinValue}
            onChange={(value: string[]) => {
              const valueString = value[0];
              if (valueString === "") {
                return "";
              }
              return Math.round(parseFloat(valueString) * 10) / 10;
            }}
            name={axleProps.fieldNameMin}
            control={axleProps.control}
            as={
              <InputField
                labelName={cssClassName(axleProps.title)}
                fieldName={axleProps.fieldNameMin}
              />
            }
          />
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">to</Typography>
        </Grid>
        <Grid item style={{ marginLeft: 20 }}>
          <Controller
            defaultValue={axleProps.defaultMaxValue}
            onChange={(value: string[]) => {
              const valueString = value[0];
              if (valueString === "") {
                return "";
              }
              return Math.round(parseFloat(valueString) * 10) / 10;
            }}
            name={axleProps.fieldNameMax}
            control={axleProps.control}
            as={
              <InputField
                labelName={cssClassName(axleProps.title)}
                fieldName={axleProps.fieldNameMax}
              />
            }
          />
        </Grid>
      </Grid>
      <Typography variant="caption" color="error">
        {errorMessageMin}
        {errorMessageMax && errorMessageMin
          ? `, ${errorMessageMax}`
          : errorMessageMax}
      </Typography>
    </>
  );
};

const Subtitle = (props: { text: string; id: string }) => {
  return (
    <div>
      <Typography variant="h6" id={props.id}>
        {props.text}
      </Typography>
      <Divider variant="fullWidth" style={{ marginTop: "5px" }} />
    </div>
  );
};
