import React from 'react';

export const HelpIconInactive = (props: { className?: string, width?: number }) => (
  <svg
    className={props.className}
    width={ props.width ? props.width : '28' }
    height={ props.width ? props.width : '28' }
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="14" cy="14" r="14" fill="#E0EFF8" />
    <path d="M15.8214 18.5625V20.9732C15.8214 21.0804 15.7812 21.1741 15.7008 21.2545C15.6205 21.3348 15.5267 21.375 15.4196 21.375H13.0089C12.9017 21.375 12.808 21.3348 12.7276 21.2545C12.6473 21.1741 12.6071 21.0804 12.6071 20.9732V18.5625C12.6071 18.4554 12.6473 18.3616 12.7276 18.2813C12.808 18.2009 12.9017 18.1607 13.0089 18.1607H15.4196C15.5267 18.1607 15.6205 18.2009 15.7008 18.2813C15.7812 18.3616 15.8214 18.4554 15.8214 18.5625ZM18.9955 12.5357C18.9955 12.8973 18.9419 13.2355 18.8348 13.5502C18.7343 13.865 18.6171 14.1228 18.4832 14.3237C18.356 14.5179 18.1718 14.7154 17.9307 14.9163C17.6964 15.1172 17.5055 15.2645 17.3582 15.3583C17.2109 15.4453 17.0066 15.5625 16.7455 15.7098C16.4709 15.8639 16.2399 16.0815 16.0524 16.3627C15.8716 16.644 15.7812 16.8683 15.7812 17.0357C15.7812 17.1496 15.741 17.2601 15.6607 17.3672C15.5803 17.4677 15.4865 17.5179 15.3794 17.5179H12.9687C12.8682 17.5179 12.7812 17.4576 12.7075 17.3371C12.6406 17.2098 12.6071 17.0826 12.6071 16.9554V16.5034C12.6071 15.9476 12.8247 15.4252 13.26 14.9364C13.6953 14.4409 14.174 14.0759 14.6964 13.8415C15.0915 13.6607 15.3727 13.4732 15.5401 13.279C15.7075 13.0848 15.7912 12.8304 15.7912 12.5157C15.7912 12.2344 15.6339 11.9866 15.3191 11.7723C15.0111 11.5581 14.6528 11.4509 14.2444 11.4509C13.8091 11.4509 13.4475 11.548 13.1595 11.7422C12.9252 11.9096 12.5669 12.2947 12.0848 12.8973C11.9977 13.0045 11.8939 13.0581 11.7734 13.0581C11.693 13.0581 11.6093 13.0313 11.5223 12.9777L9.87494 11.7221C9.78788 11.6552 9.73431 11.5715 9.71422 11.471C9.70083 11.3706 9.72092 11.2768 9.77449 11.1898C10.8459 9.40851 12.3995 8.51788 14.4352 8.51788C14.9709 8.51788 15.51 8.62168 16.0524 8.82927C16.5948 9.03686 17.0836 9.31476 17.5189 9.66297C17.9542 10.0112 18.3091 10.4398 18.5836 10.9487C18.8582 11.4509 18.9955 11.9799 18.9955 12.5357Z" fill="#007BC1"/>
  </svg>
);
