import React from 'react';
import {
  Survey,
  Application,
  RoadType,
  EngineMake,
  PreferenceRanking,
  EngineMakeToLabelMappings,
} from '../../domain/survey';
import { kebabCase } from 'lodash';
import { RadioQuestion } from './radio-question';
import { SliderQuestion } from './slider-question';
import { DragAndDropQuestion } from './drag-question';
import { PTOQuestion } from './pto-question';
import { SurveyPage } from '../survey-shell/survey-shell';
import { allPreferenceRankingFilled } from '../survey';
import { SubmissionState } from '../pure-transmission-form/transmission-form';
import { Typography } from '@material-ui/core';
import { SurveyPageHeader } from './survey-page-header';
import { TerrainQuestion } from './terrain-question';
import { ManeuversQuestion } from './maneuvers-question';
import { TransmissionConstants } from '../../domain/transmission';
import { ImageQuestion } from './image-question';
import { RoadTypeToLabelMappings } from '../../domain/image-mappings';

export type NullableSurvey<T> = {
  [P in keyof T]: T[P] extends PreferenceRanking
    ? NullableSurvey<T[P]>
    : T[P] | null;
};

type Props = {
  survey: NullableSurvey<Survey>;
  setSurvey: React.Dispatch<React.SetStateAction<NullableSurvey<Survey>>>;
  submissionState: SubmissionState;
};

export function PureSurvey(props: Props) {
  const { survey, setSurvey, submissionState } = props;

  return [
    <SurveyPage isFilled={!!survey.application} key={'application-page'}>
      <SurveyPageHeader
        headerText={`What is the vehicle's application?`}
        helpLink="/help#VehicleApplication"
        smallMargin
      />
      <RadioQuestion
        value={survey.application}
        setValue={(application) => {
          setSurvey({ ...survey, application });
        }}
        name="Application"
        values={Application}
        maxInColumn={6}
      />
    </SurveyPage>,
    <SurveyPage isFilled={!!survey.maxWeight} key={'max-weight-page'}>
      <SurveyPageHeader
        headerText={`What is the maximum gross combined weight (GCW) of the vehicle?`}
        helpLink="/help#GCW"
      />
      <SliderQuestion
        value={survey.maxWeight}
        setValue={(maxWeight) => {
          setSurvey({ ...survey, maxWeight });
        }}
        name="Max GCW"
        minValue={TransmissionConstants.MIN_WEIGHT}
        maxValue={TransmissionConstants.MAX_WEIGHT}
        units="weight"
        stepSize={1000}
      />
    </SurveyPage>,
    <SurveyPage isFilled={survey.terrain !== null} key={'terrain-page'}>
      <SurveyPageHeader
        headerText={`What type of terrain will the vehicle be operated on?`}
        helpLink="/help#PercentGrade"
        tinyMargin
      />
      <TerrainQuestion
        terrains={survey.terrain}
        setValue={(terrain) => {
          setSurvey({ ...survey, terrain });
        }}
      />
    </SurveyPage>,
    <SurveyPage
      isFilled={!!survey.roadTypes && survey.roadTypes.length !== 0}
      key={'road-types-page'}
    >
      <SurveyPageHeader
        headerText={`What type of roads will the vehicle operate on?`}
        helpLink="/help#RoadTypes"
        tinyMargin
      />
      <ImageQuestion
        name="Road Types"
        values={RoadType}
        value={survey.roadTypes}
        setValue={(roadTypes) => {
          setSurvey({ ...survey, roadTypes });
        }}
        labelMapping={RoadTypeToLabelMappings}
      />
    </SurveyPage>,
    <SurveyPage isFilled={survey.maneuvers !== null} key={'maneuvers-page'}>
      <SurveyPageHeader
        headerText={`What maneuvers will the vehicle need to do?`}
        tinyMargin
      />
      <ManeuversQuestion
        maneuvers={survey.maneuvers}
        setValue={(maneuvers) => {
          setSurvey({ ...survey, maneuvers });
        }}
      />
    </SurveyPage>,
    <SurveyPage
      isFilled={!!survey.cruiseSpeed && !!survey.maxSpeed}
      key={'cruise-speed-page'}
    >
      <Typography variant="subtitle2">Vehicle Speed</Typography>
      <SurveyPageHeader
        headerText={`What is the intended cruise speed of the vehicle?`}
        helpLink="/help#VehicleSpeeds"
      />
      <SliderQuestion
        value={survey.cruiseSpeed}
        setValue={(cruiseSpeed) => {
          setSurvey({ ...survey, cruiseSpeed });
        }}
        name="Avg Cruise Speed"
        minValue={TransmissionConstants.MIN_SPEED}
        maxValue={TransmissionConstants.MAX_SPEED}
        stepSize={1}
        units="speed"
      />
      <SurveyPageHeader
        headerText={`What is the occasional maximum road speed of the vehicle?`}
        helpLink="/help#VehicleSpeeds"
      />
      <SliderQuestion
        value={survey.maxSpeed}
        setValue={(maxSpeed) => {
          setSurvey({ ...survey, maxSpeed });
        }}
        name="Max Speed"
        minValue={TransmissionConstants.MIN_SPEED}
        maxValue={TransmissionConstants.MAX_SPEED}
        stepSize={1}
        units="speed"
      />
    </SurveyPage>,
    <SurveyPage isFilled={!!survey.pto} key={'pto-page'}>
      <SurveyPageHeader
        headerText={`Does the vehicle have any transmission PTO needs?`}
        helpLink="/help#PTO"
        smallMargin
      />
      <PTOQuestion
        value={survey.pto}
        setValue={(pto) => {
          setSurvey({ ...survey, pto });
        }}
      />
    </SurveyPage>,
    <SurveyPage
      isFilled={
        !!survey.engineMake && !!survey.engineHp && !!survey.engineTorque
      }
      key={'engine-page'}
    >
      <Typography variant="subtitle2">Engine Rating and Type</Typography>
      <SurveyPageHeader
        headerText={`What is the vehicle's engine make?`}
        smallMargin
      />
      <RadioQuestion
        value={survey.engineMake}
        setValue={(engineMake) => {
          setSurvey({ ...survey, engineMake });
        }}
        name="Engine Make"
        values={EngineMake}
        labelMapping={EngineMakeToLabelMappings}
        maxInColumn={3}
      />
      <div style={{ paddingTop: '64px' }}>
        <SurveyPageHeader
          headerText={`What is the engine's horsepower and torque?`}
        />
        <SliderQuestion
          value={survey.engineHp}
          setValue={(engineHp) => {
            setSurvey({ ...survey, engineHp });
          }}
          name="Horsepower"
          minValue={TransmissionConstants.MIN_HP}
          maxValue={TransmissionConstants.MAX_HP}
          stepSize={5}
          units="power"
        />
        <SliderQuestion
          value={survey.engineTorque}
          setValue={(engineTorque) => {
            setSurvey({ ...survey, engineTorque });
          }}
          name="Torque"
          minValue={TransmissionConstants.MIN_TORQUE}
          maxValue={TransmissionConstants.MAX_TORQUE}
          stepSize={50}
          units="torque"
        />
      </div>
    </SurveyPage>,
    <SurveyPage
      isFilled={
        !!survey.preferenceRanking &&
        allPreferenceRankingFilled(survey.preferenceRanking)
      }
      key={'preference-page'}
    >
      <SurveyPageHeader
        headerText="What is the goal of the vehicle?"
        tinyMargin
      />
      <DragAndDropQuestion
        disabled={submissionState === 'submitting'}
        surveyValues={survey.preferenceRanking}
        setSurveyValues={(preferenceRanking) => {
          setSurvey({ ...survey, preferenceRanking });
        }}
        name="Preference Ranking"
        optionsTitle="Options"
        rankingTitle="Customer Preference Ranking"
      />
    </SurveyPage>,
  ];
}

export const cssClassName = (string: string) => {
  return kebabCase(string).replace(
    /[!"#$%&'()*+,./:;<=>?@[\\\]^`{|}~\s]/g,
    '-',
  );
};
