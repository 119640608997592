import React, { useState } from 'react';
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core';
import { cssClassName } from '..';
import { startCase, capitalize } from 'lodash';
import { useStyles } from './styles';

export function CheckboxQuestion<T extends string>(props: {
  name: string;
  values: { [key: string]: T };
  value: T[] | null;
  setValue: (value: T[] | null) => void;
  allowNone?: true;
  labelMapping?: { [key in T]: string };
}) {
  const classes = useStyles();

  const [noneChecked, setNone] = useState(
    props.allowNone ? props.value?.length === 0 : false,
  );

  const handleNone = (checked: boolean) => {
    if (checked) {
      props.setValue([]);
    } else {
      props.setValue(null);
    }
    setNone(checked);
  };

  const handleChange = (name: T) => (
    event: React.ChangeEvent<{}>,
    checked: boolean,
  ) => {
    if (!checked) {
      const newValue = (props.value || []).filter((value) => value !== name);
      if (newValue.length === 0) {
        props.setValue(null);
      } else {
        props.setValue(newValue);
      }
    } else if (!props.value?.includes(name)) {
      props.setValue((props.value || []).concat(name));
    }
  };

  return (
    <FormControl component="fieldset">
      <FormLabel>
        <Typography className={classes.contentLabel}>
          Select all that apply.
        </Typography>
      </FormLabel>
      <FormGroup aria-label={props.name}>
        {Object.values(props.values).map((value) => (
          <FormControlLabel
            key={cssClassName(value)}
            data-testid={cssClassName(value)}
            className={cssClassName(value)}
            value={value}
            checked={props.value?.includes(value) || false}
            control={<Checkbox />}
            label={
              props.labelMapping
                ? props.labelMapping[value]
                : capitalize(startCase(value))
            }
            onChange={handleChange(value)}
            disabled={noneChecked}
          />
        ))}
        {props.allowNone && (
          <FormControlLabel
            key="none"
            data-testid="none-of-the-above"
            className="none-of-the-above"
            value="none"
            checked={noneChecked}
            control={<Checkbox />}
            label="None of the above"
            onChange={(event, checked) => {
              handleNone(checked);
            }}
          />
        )}
      </FormGroup>
    </FormControl>
  );
}
