import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { EatonButton } from '../button';

type Props = {
  open: boolean;
  onClose: () => void;
  alertTitle: string;
  alertContent: string;
  agreeButtonText: string;
  onAgree: () => void;
};
type DialogLogProps={
  open: boolean;
  onClose: () => void;
  alertTitle: string;
  alertContent: React.ReactNode;
}
export const EatonAlertDialogForTransmissionLog = (props: DialogLogProps) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg" 
    >
      <DialogTitle id="alert-dialog-title">{props.alertTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.alertContent}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <EatonButton
          onClick={props.onClose}
          data-testid="alert-dialog-cancel"
          color="primary"
        >
          Ok
        </EatonButton>
      </DialogActions>
    </Dialog>
  );
};

export const EatonAlertDialog = (props: Props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.alertTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.alertContent}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <EatonButton
          onClick={props.onClose}
          data-testid="alert-dialog-cancel"
          color="primary"
        >
          Cancel
        </EatonButton>
        <EatonButton
          onClick={props.onAgree}
          data-testid="alert-dialog-confirm"
          color="primary"
        >
          {props.agreeButtonText}
        </EatonButton>
      </DialogActions>
    </Dialog>
  );
};
