import React, { useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw } from 'draft-js';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { useStyles } from './styles';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export const RichTextEditor = (props: {
  onChange?: (value: string) => void;
  value?: string | undefined;
  readonly?: boolean;
  className?: string;
  editorClassName?: string;
}) => {
  const [editorState, setEditorState] = React.useState(
    props.value !== null
      ? EditorState.createWithContent(
          convertFromRaw(
            markdownToDraft(props.value, { preserveNewlines: true }),
          ),
        )
      : EditorState.createEmpty(),
  );

  useEffect(() => {
    if (props.value === '') {
      // setEditorState(EditorState.moveFocusToEnd(EditorState.createEmpty()));
      setEditorState(EditorState.createEmpty());
    }
  }, [props.value === '']);

  const classes = useStyles({});

  return (
    <div className={props.className}>
      <Editor
        wrapperId={111} // Need this ID otherwise it randomly generates and throws storyshots
        editorState={editorState}
        onEditorStateChange={setEditorState}
        onChange={(value) => {
          const markdownValue: string = draftToMarkdown(value);
          if (props.onChange) props.onChange(markdownValue);
        }}
        toolbarClassName={classes.editorToolbar}
        wrapperClassName={classes.editorWrapper}
        editorClassName={`${classes.editorContent} ${props.editorClassName}`}
        data-testid="rich-text-editor"
        readOnly={props.readonly}
        toolbar={{
          options: ['blockType', 'inline', 'list', 'link', 'history'],
          blockType: {
            className: classes.editorButton,
            inDropdown: true,
            options: [
              'Normal',
              'H1',
              'H2',
              'H3',
              'H4',
              'H5',
              'H6',
              'Blockquote',
            ],
          },
          inline: {
            className: classes.editorButton,
            options: ['bold', 'italic'],
          },
          list: {
            className: classes.editorButton,
            options: ['unordered', 'ordered'],
          },
          link: {
            className: classes.editorButton,
          },
          history: {
            className: classes.editorButton,
          },
        }}
      />
    </div>
  );
};
