import React, { useState } from 'react';
import { HelpIconInactive } from '../../icons/help-icon-inactive';
import { IconButton } from '@material-ui/core';
import { HelpIconActive } from '../../icons/help-icon-active';
import { Link } from 'react-router-dom';

type Props = {
  to: string;
  width?: number;
  disabled?: boolean;
  className?: string;
}

export const QuestionButton = (props: Props) => {
  const [icon, setIcon] = useState<JSX.Element>(<HelpIconInactive width={props.width}/>);
  const enterHover = () => setIcon(<HelpIconActive width={props.width}/>);
  const exitHover = () => setIcon(<HelpIconInactive width={props.width}/>);
  return (
    <div className={props.className} onMouseEnter={enterHover} onMouseLeave={exitHover}>
      <IconButton
        disableRipple
        disabled={props.disabled}
        style={{ padding: 0 }}
        component={Link}
        to={props.to}
        target="_blank"
      >
        {icon}
      </IconButton>
    </div>
  );
};
