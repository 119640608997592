import { makeStyles, Theme } from '@material-ui/core';
import * as Colors from '@pxblue/colors';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    progressBarContainer: {
      width: '100%',
      height: '23px',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '48px',
    },
    progressNumber: {
      fontWeight: 700,
      fontSize: '12px',
      color: Colors.black[300],
      textAlign: 'right',
      width: '28px',
    },
    linearProgress: {
      width: '100%',
    },
    leftNumber: {
      marginRight: '8px',
    },
    rightNumber: {
      marginLeft: '8px',
    },
  };
});
