import { makeStyles, Theme } from '@material-ui/core';
import * as Colors from '@pxblue/colors';
import { Gutters } from '../../styles/gutters';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    noResultUnitSwitcherContainer: {
      display: 'flex',
      flexDirection: 'row-reverse',
      marginTop: '32px',
    },
    resultTitleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
    },
    header: {
      marginTop: '52px',
      marginBottom: '32px',
    },
    transmissionResult: {
      marginBottom: '32px',
    },
    surveyResponses: {
      marginBottom: '64px',
    },
    contactContainer: {
      width: '770px',
      marginLeft: 'auto',
      marginRight: 'auto',
      borderRadius: '2px',
      backgroundColor: Colors.blue[50],
      marginBottom: '128px',
      padding: '32px',
      textAlign: 'center',
    },
    specialMessageContainer: {
      width: '770px',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '64px',
      borderRadius: '2px',
      backgroundColor: Colors.yellow[50],
      padding: '32px',
      textAlign: 'center',
    },
    emphasisNote: { fontWeight: 600 },
    noTransmissionContainer: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      borderRadius: '2px',
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.grey[100]}`,
      marginBottom: '32px',
      marginTop: '32px',
      padding: '32px',
      textAlign: 'center',
    },
    contactHeader: {
      marginBottom: '16px',
    },
    contactSubheader: {
      marginBottom: '8px',
      color: Colors.black[600],
    },
    link: {
      '&:visited': {
        color: theme.palette.primary.main,
      },
    },
    debugText: {
      whiteSpace: 'pre-wrap',
      marginBottom: '20px',
    },
    pdfButton: {
      marginRight: '16px',
    },
    saveSpecHeaderContainer: {
      display: 'flex',
      flexFlow: 'column',
    },
    saveSpecSubHeader: {
      marginBottom: '16px',
    },
    saveSpecFillerText: {
      marginBottom: '32px',
    },
    saveSpecTopContainer: {
      borderBottom: `1px solid ${Colors.gray[50]}`,
      background: Colors.white[50],
      ...Gutters(theme),
      minWidth: '960px',
      maxWidth: '1500px',
      width: '100%',
      marginRight: 'auto',
      marginLeft: 'auto',
      paddingBottom: '52px',
    },
    saveSpecBottomContainer: {
      ...Gutters(theme),
      minWidth: '960px',
      maxWidth: '1500px',
      width: '100%',
      marginRight: 'auto',
      marginLeft: 'auto',
      paddingTop: '32px',
    },
    saveSpecTitleField: {
      marginBottom: '32px',
      width: '769px',
    },
    saveSpecRichTextEditorContainer: {
      width: '769px',
      paddingBottom: '32px',
    },
    saveSpecRichTextEditorEditorField: {
      height: '176px',
    },
    errorText: {
      color: theme.palette.error.main,
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    transmissionButtonLink: {
      color: theme.palette.common.white,
      textDecoration: 'none',
    },
  };
});
