import React from 'react';
import { Typography } from '@material-ui/core';
import { SpecTable } from '../spec-table';
import { EatonLinkButton } from '../button';
import { useStyles } from './styles';

export const AllSpecs = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.specTableContainer}>
        <div className={classes.specTableHeader}>
          <Typography variant="h1">My Specs</Typography>
          <div>
            <EatonLinkButton to="/survey" variant="outlined">
              Create new spec
            </EatonLinkButton>
          </div>
        </div>
        <SpecTable actionLink="/survey" actionLabel="Create new spec" />
      </div>
    </>
  );
};
