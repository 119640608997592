import React from 'react';
import { useStyles } from './styles';
import { Typography } from '@material-ui/core';

export const RoadTypes = () => {
  const classes = useStyles();

  const RoadSection = (props: { title: string, body: string, examples: string[], image: string }) => {
    return (
      <div>
        <Typography variant="h3" className={classes.subtitle}>
          {props.title}
        </Typography>
        <img src={props.image} className={classes.image} />
        <Typography className={classes.description}>
          {props.body}
        </Typography>
        <div className={classes.roadExamples}>
          <ul>
            {props.examples.map((example, index) => {
              return (
                <li key={`${props.title}-${index}`}>
                  <Typography>
                    {example}
                  </Typography>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Typography variant="h2" id="RoadTypes" className={classes.subtitle}>
        Road Types
      </Typography>
      <RoadSection
        title="Highway Paved - (Class A)"
        body="Well-maintained roads of excellent asphalt or concrete construction with limited access points. Examples of Highway roads may include:"
        examples={[
          'Major/Federal Highways',
          'Expressways',
          'Interstates',
        ]}
        image="../../help-images/highway-paved.png"
      />
      <RoadSection
        title="Highway Paved - (Secondary) (Class B)"
        body="Roads of good concrete or asphalt construction with frequent or uncontrolled access points. Examples of Secondary roads may include:"
        examples={[
          'Two Lane Highways',
          'City/Township Roads',
          'Highways in Mountainous Areas',
        ]}
        image="../../help-images/highway-paved-secondary.png"
      />
      <RoadSection
        title="Maintained Gravel - (Off Highway) (Class C)"
        body="Public or maintained private roads. Examples of Off Highway roads may include:"
        examples={[
          'Gravel',
          'Crushed Rock',
          'Worn and Degraded Asphalt',
        ]}
        image="../../help-images/maintained-gravel.png"
      />
      <RoadSection
        title="Off Road - (Class D)"
        body="Private roads or private sites with no consistently maintained hard surfaces. Examples of roads classified as Off Road may include:"
        examples={[
          'Poor Gravel or Dirt Roads',
          'Two Rut Routes',
          'Agricultural Fields',
        ]}
        image="../../help-images/off-road.png"
      />
    </div>
  );
};
