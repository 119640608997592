import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { SpecTable } from '../spec-table';

export const AppSplashLoggedIn = () => {
  const classes = useStyles();

  return (
    <div className={classes.recentSpecsContainer}>
      <span className={classes.titleText}>
        <Typography variant="h2">
          My Recent Specs
          <span className={classes.subtitleText}>
            Your most recently created or edited specs.
          </span>
        </Typography>
      </span>
      <SpecTable actionLink="/spec" actionLabel="View all specs" limit={3} />
    </div>
  );
};
