import React from 'react';
import * as Colors from '@pxblue/colors';
import { Text, View, StyleSheet, Link } from '@react-pdf/renderer';
import { markdownToDraft } from 'markdown-draft-js';
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';
import redraft from 'redraft';
import { pixelsToPoints } from './helpers';

export const PDFRichText = ({ note }: any) => {
  const editorState = EditorState.createWithContent(
    convertFromRaw(markdownToDraft(note, { preserveNewlines: true })),
  );
  const rawContent = convertToRaw(editorState.getCurrentContent());
  return redraft(rawContent, renderers, { blockFallback: 'unstyled' });
};

const markdownStyles = StyleSheet.create({
  typography: {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    color: Colors.gray[800],
  },
  h1: {
    fontWeight: 600,
    fontSize: pixelsToPoints(42),
    color: Colors.black[600],
  },
  h2: {
    fontWeight: 600,
    fontSize: pixelsToPoints(32),
    color: Colors.black[600],
  },
  h3: {
    fontWeight: 600,
    fontSize: pixelsToPoints(24),
    color: Colors.black[600],
  },
  h4: {
    fontWeight: 600,
    fontSize: pixelsToPoints(18),
    color: Colors.black[600],
  },
  h5: {
    fontWeight: 600,
    fontSize: pixelsToPoints(16),
    color: Colors.gray[800],
    marginTop: pixelsToPoints(8),
    marginBottom: pixelsToPoints(8),
  },
  h6: {
    fontWeight: 700,
    letterSpacing: pixelsToPoints(1),
    fontSize: pixelsToPoints(10),
    color: Colors.gray[800],
    textTransform: 'uppercase',
    marginTop: pixelsToPoints(8),
    marginBottom: pixelsToPoints(8),
  },
  list: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
  },
  listItem: {
    marginBottom: 4,
  },
  blockquote: {
    marginLeft: 25,
  },
});

export const HeaderTwo = (props: React.PropsWithChildren<{ style?: any }>) => (
  <Text style={{ ...markdownStyles.h2, ...props.style }}>{props.children}</Text>
);

export const UnorderedList = ({ children, depth }: any) => {
  return <View style={markdownStyles.list}>{children}</View>;
};
export const UnorderedListItem = ({ children }: any) => {
  return (
    <View style={markdownStyles.listItem}>
      <Text>
        <Text>• &nbsp;{children}</Text>
      </Text>
    </View>
  );
};
const OrderedList = ({ children, depth }: any) => {
  return <View style={markdownStyles.list}>{children}</View>;
};
const OrderedListItem = ({ children, index }: any) => {
  return (
    <View style={markdownStyles.listItem}>
      <Text>
        {index + 1}. <Text>{children}</Text>
      </Text>
    </View>
  );
};
const renderers = {
  inline: {
    // The key passed here is just an index based on rendering order inside a block
    BOLD: (children: any, { key }: any) => {
      return (
        <Text key={`bold-${key}`} style={{ fontWeight: 700 }}>
          {children}
        </Text>
      );
    },
    ITALIC: (children: any, { key }: any) => {
      return <Text key={`italic-${key}`} style={{ fontStyle: 'italic' }}>{children}</Text>;
    },
    UNDERLINE: (children: any, { key }: any) => {
      return <Text key={`underline-${key}`}>{children}</Text>;
    },
  },
  /**
   * Blocks receive children and depth
   * Note that children are an array of blocks with same styling,
   */
  blocks: {
    'unstyled': (children: any, { keys }: any) => {
      return children.map((child: any, index: number) => {
        return (
          <View key={keys[index]}>
            <Text style={markdownStyles.typography}>{child}</Text>
          </View>
        );
      });
    },
    'header-one': (children: any, { keys }: any) => {
      return children.map((child: any, index: number) => {
        return (
          <Text style={markdownStyles.h1} key={keys[index]}>
            {child}
          </Text>
        );
      });
    },
    'header-two': (children: any, { keys }: any) => {
      return children.map((child: any, index: number) => {
        return <HeaderTwo key={keys[index]}>{child}</HeaderTwo>;
      });
    },
    'header-three': (children: any, { keys }: any) => {
      return children.map((child: any, index: number) => {
        return (
          <Text style={markdownStyles.h3} key={keys[index]}>
            {child}
          </Text>
        );
      });
    },
    'header-four': (children: any, { keys }: any) => {
      return children.map((child: any, index: number) => {
        return (
          <Text style={markdownStyles.h4} key={keys[index]}>
            {child}
          </Text>
        );
      });
    },
    'header-five': (children: any, { keys }: any) => {
      return children.map((child: any, index: number) => {
        return (
          <Text style={markdownStyles.h5} key={keys[index]}>
            {child}
          </Text>
        );
      });
    },
    'header-six': (children: any, { keys }: any) => {
      return children.map((child: any, index: number) => {
        return (
          <Text style={markdownStyles.h6} key={keys[index]}>
            {child}
          </Text>
        );
      });
    },
    'blockquote': (children: any, { keys }: any) => {
      return children.map((child: any, index: number) => {
        return (
          <Text style={markdownStyles.blockquote} key={keys[index]}>
            {child}
          </Text>
        );
      });
    },
    'unordered-list-item': (children: any, { depth, keys }: any) => {
      return (
        <UnorderedList key={keys[keys.length - 1]} depth={depth}>
          {children.map((child: any, index: number) => (
            <UnorderedListItem key={keys[index]}>{child}</UnorderedListItem>
          ))}
        </UnorderedList>
      );
    },
    'ordered-list-item': (children: any, { depth, keys }: any) => {
      return (
        <OrderedList key={keys.join('|')} depth={depth}>
          {children.map((child: any, index: number) => (
            <OrderedListItem key={keys[index]} index={index}>
              {child}
            </OrderedListItem>
          ))}
        </OrderedList>
      );
    },
  },
  /**
   * Entities receive children and the entity data
   */
  entities: {
    // key is the entity key value from raw
    LINK: (children: any, data: any, { key }: any) => (
      <Link key={key} src={data.url}>
        {children}
      </Link>
    ),
  },
};
