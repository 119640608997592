import React from 'react';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { cssClassName } from '..';
import { startCase, chunk, values } from 'lodash';
import { useStyles } from './styles';

export function RadioQuestion<T extends string>(props: {
  name: string;
  value: T | null;
  values: { [key: string]: T };
  setValue: (value: T) => void;
  maxInColumn?: number;
  labelMapping?: { [key in T]: string };
}) {
  const classes = useStyles();
  const flattenedValues = values(props.values);
  const display = props.maxInColumn
    ? chunk(flattenedValues, props.maxInColumn).map((arr, index) => {
        return (
          <div key={`column-${index}`} className={classes.column}>
            {arr.map((v) => (
              <div key={`item-${v}`}>
                <FormControlLabel
                  key={cssClassName(v)}
                  className={cssClassName(v)}
                  data-testid={cssClassName(v)}
                  value={v}
                  control={<Radio />}
                  label={
                    props.labelMapping ? props.labelMapping[v] : startCase(v)
                  }
                />
              </div>
            ))}
          </div>
        );
      })
    : flattenedValues.map((v) => (
        <div key={`item-${v}`}>
          <FormControlLabel
            key={cssClassName(v)}
            className={cssClassName(v)}
            data-testid={cssClassName(v)}
            value={v}
            control={<Radio />}
            label={props.labelMapping ? props.labelMapping[v] : startCase(v)}
          />
        </div>
      ));

  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label={props.name}
        name={cssClassName(props.name)}
        value={props.value || ''}
        onChange={(event: React.ChangeEvent<HTMLInputElement>, value) => {
          props.setValue((value as unknown) as T);
        }}
        className={
          props.maxInColumn
            ? classes.radioGroupColumns
            : classes.radioGroupSingle
        }
      >
        {display}
      </RadioGroup>
    </FormControl>
  );
}
