import React, { Props, PropsWithChildren } from 'react';
import Markdown from 'markdown-to-jsx';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const options = {
  forceBlock: true,
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'h1',
      },
    },
    h2: { component: Typography, props: { gutterBottom: true, variant: 'h2' } },
    h3: { component: Typography, props: { gutterBottom: true, variant: 'h3' } },
    h4: {
      component: Typography,
      props: { gutterBottom: true, variant: 'h4', paragraph: true },
    },
    h5: {
      component: Typography,
      props: { gutterBottom: true, variant: 'subtitle1', paragraph: true },
    },
    h6: {
      component: Typography,
      props: { gutterBottom: true, variant: 'subtitle2', paragraph: true },
    },
    p: { component: Typography, props: { paragraph: true } },
    a: { component: Link },
    ul: {
      component: (props: any) => (
        <ul style={{ paddingLeft: '0px' }}>
          {React.Children.map(props.children, (child) => {
            return (
              <Typography component="span" style={{ display: 'flex' }}>
                {`\u2022`}&nbsp;{child}
              </Typography>
            );
          })}
        </ul>
      ),
    },
    ol: {
      component: (props: any) => (
        <ol style={{ display: 'grid', paddingLeft: '18px' }}>
          {React.Children.map(props.children, (child, index) => {
            return (
              <li>
                <Typography
                  style={{ position: 'relative', display: 'inline-block' }}
                  component="span"
                >
                  {child}
                </Typography>
              </li>
            );
          })}
        </ol>
      ),
    },
    li: {
      component: Typography,
    },
    strong: {
      props: { style: { fontWeight: 600 } },
    },
  },
};

export default function ReactMarkdown(props: PropsWithChildren<any>) {
  return <Markdown options={options} {...props} />;
}
