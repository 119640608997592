/* eslint-disable quote-props */
import { makeStyles, Theme } from '@material-ui/core';
import { HeaderHeight } from '../../components/app-chrome/header/styles';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      minWidth: '1120px',
    },
    signedOutBg: {
      '&::before': {
        content: '""',
        left: 0,
        right: 0,
        zIndex: -1,
        width: '100%',
        height: `calc(100% - ${HeaderHeight})`,
        position: 'absolute',
        background:
          'linear-gradient(180deg, rgba(255, 255, 255, 0.98) 300px, rgba(255, 255, 255, 0.5) 100%), url(../../../../transmissionspecbg.jpeg)',
        filter: 'blur(2px)',
        backgroundSize: 'cover',
      },
    },
  };
});
