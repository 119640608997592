import { useState, useEffect } from 'react';
import {
  AuthenticatedRequestConfig,
  useAuthDispatch,
} from '../components/AuthContext';
import { unflatten } from 'flat';

export function useAuthenticatedRequest<T>(config: {
  requestConfig: AuthenticatedRequestConfig;
  requery?: any[];
}): [boolean, T | undefined, () => void] {
  const [data, setData] = useState<T | undefined>(undefined);
  const [loading, setIsLoading] = useState(true);
  const [requery, setRequery] = useState(true);

  const { authenticatedRequest } = useAuthDispatch();

  useEffect(() => {
    if (requery === true) {
      const fetchData = async () => {
        const response = await authenticatedRequest<T>(config.requestConfig);
        if (response.ok) {
          setData(response.body);
          setRequery(false);
        } else if (!response.ok) {
          setData(undefined);
          setRequery(false);
        } else {
          setIsLoading(true);
        }
        setIsLoading(false);
      };
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchData();
    }
  }, (config.requery || []).concat(requery));
  return [
    loading,
    data,
    () => {
      setRequery(true);
    },
  ];
}

export function objectFromFormData(formData: FormData) {
  const objData = {};
  formData.forEach((value, key) => {
    let finalValue: any = value;
    if (typeof value === 'string') {
      const number = parseInt(value);
      if (!isNaN(number)) finalValue = number;
    }
    (objData as any)[key] = finalValue;
  });
  return unflatten(objData);
}

export function appendFormData(
  data: any,
  formData: FormData,
  _root?: string,
): FormData {
  const root = _root || '';
  let result = formData;
  if (data instanceof File) {
    result.append(root, data);
  } else if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      result = appendFormData(data[i], result, root + '[' + i + ']');
    }
  } else if (typeof data === 'object' && data) {
    Object.entries(data).forEach((entry) => {
      if (root === '') {
        result = appendFormData(entry[1], result, entry[0]);
      } else {
        result = appendFormData(entry[1], result, root + '.' + entry[0]);
      }
    });
  } else {
    if (data !== null && typeof data !== 'undefined') {
      result.append(root, data);
    }
  }
  return result;
}
