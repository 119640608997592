import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  specTableContainer: {
    paddingBottom: '32px',
  },
  specTableHeader: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '52px',
    paddingBottom: '16px',
  },
  newSpecButton: { marginTop: '52px' },
}));
