import React, { useEffect, useState } from 'react';
import { SavedSpecView } from '../../components/saved-spec';
import { useAuthDispatch } from '../../components/reusable-components/components/AuthContext';
import { SavedSurveyResult } from '../../domain/saved-result';
import { useHistory } from 'react-router';
import { CircularProgress } from '@material-ui/core';

export const SavedSpecPage = (props: { match: any }) => {
  const router = useHistory();
  const { authenticatedRequest } = useAuthDispatch();
  const [loadingSurvey, setLoadingSurvey] = useState<boolean>(false);
  const [surveyData, setSurveyData] = useState<SavedSurveyResult | null>(null);

  useEffect(() => {
    setLoadingSurvey(true);
    const getData = async () => {
      const result = await authenticatedRequest<SavedSurveyResult>({
        method: 'GET',
        url: `/api/surveyresult/${props.match.params.id}`,
      });
      if (result.status < 300) {
        const resultBody = result.body as SavedSurveyResult;
        if (resultBody.version !== '1.0') {
          router.push('/error');
        } else {
          setSurveyData(result.body as SavedSurveyResult);
          setLoadingSurvey(false);
        }
      } else {
        router.push('/error');
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getData();
  }, []);

  return !loadingSurvey && surveyData !== null ? (
    <SavedSpecView savedSpec={surveyData} />
  ) : (
    <div style={{ paddingTop: '32px', width: '100%', textAlign: 'center' }}>
      <CircularProgress />
    </div>
  );
};
