import React, { useEffect, PropsWithChildren } from "react";
import axios, { AxiosRequestConfig, AxiosResponse, Method } from "axios";
import Cookie from "js-cookie";
import { useHistory } from "react-router";

/**
 * Part of the pattern used in this module based on: https://kentcdodds.com/blog/how-to-use-react-context-effectively
 */

type Action =
  | { type: "userLoggedIn"; payload: UserInfo }
  | { type: "unauthorized" };

type AuthContextState = AuthenticatedContextState | UnauthenticatedContextState;

export enum AuthStatus {
  Unauthenticated,
  Authenticated,
}

type AuthenticatedContextState = UserInfo & {
  authenticated: AuthStatus.Authenticated;
};

export type UserInfo = {
  email: string;
  privileges: { roles: string[]; operations: string[] };
  displayName: string;
};

type UnauthenticatedContextState = {
  authenticated: AuthStatus.Unauthenticated;
};

type AuthenticatedSuccess<T> = {
  ok: true;
  status: number;
  body: T;
};

type AuthenticatedFailure = {
  ok: false;
  status: number;
  body: object | string | null;
};

type AuthenticatedResponse<T> = AuthenticatedSuccess<T> | AuthenticatedFailure;

export type AuthenticatedRequestConfig = AxiosRequestConfig & {
  url: string;
  method: Method;
};

type AuthContextDispatch = {
  userLoggedIn(userInfo: UserInfo): void;
  authenticatedRequest<T>(
    config: AuthenticatedRequestConfig
  ): Promise<AuthenticatedResponse<T>>;
  logout(): Promise<void>;
  timeout(): Promise<void>;
};

export const AuthStateContext = React.createContext<AuthContextState | null>(
  null
);
export const AuthDispatchContext = React.createContext<AuthContextDispatch | null>(
  null
);

export function useAuthState(): AuthContextState {
  const context = React.useContext(AuthStateContext);
  if (context === null) {
    throw new Error("useAuthState must be used within a AuthContextProvider");
  }
  return context;
}

export function useAuthDispatch(): AuthContextDispatch {
  const context = React.useContext(AuthDispatchContext);
  if (context === null) {
    throw new Error(
      "useAuthDispatch must be used within a AuthContextProvider"
    );
  }
  return context;
}

function reducer(state: AuthContextState, action: Action): AuthContextState {
  switch (action.type) {
    case "userLoggedIn":
      return { authenticated: AuthStatus.Authenticated, ...action.payload };
    case "unauthorized":
      return { authenticated: AuthStatus.Unauthenticated };
    default:
      return state;
  }
}

function userLoggedIn(userInfo: UserInfo): Action {
  window.localStorage.setItem("etn-auth-user", userInfo.email);
  return { type: "userLoggedIn", payload: userInfo };
}

function userLoggedOut(): Action {
  window.localStorage.removeItem("etn-auth-user");
  window.localStorage.removeItem("etn-auth-remember-me");
  return { type: "unauthorized" };
}

async function parseErrorBody(
  response: AxiosResponse
): Promise<{} | string | null> {
  try {
    return response.data;
  } catch (err) {
    try {
      return await JSON.stringify(response.data);
    } catch (err2) {
      return null;
    }
  }
}

// The presence of an email address indicates a previously successful authentication.
// This will allow a user to get to a "private route". If the token has expired they'll
// end up getting redirected to the login anyway, after an API call fails.
// const storedUserEmail = window.localStorage.getItem('etn-auth-user');
const initialState: AuthContextState = {
  authenticated: AuthStatus.Unauthenticated, //storedUserEmail ? AuthState.Authenticated : AuthState.Unauthenticated,
};

type Props = {
  children: React.ReactNode;
  onUnauthorized?: () => Promise<void>;
};

/**
 * Component that provides authorization functionality for an app. Put this at the top of the component tree.
 * Use the useAuthState and useAuthDispatch hooks to access the contents of the context.
 */
// export default function AuthContextProvider(props: { children: React.ReactNode, onUnauthorized?: Function }) {
const AuthContextProvider: React.FC<Props> = (props: Props) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [loggingIn, setLoggingIn] = React.useState(true);
  const history = useHistory();

  useEffect(() => {
    setLoggingIn(true);
    const silentLogin = async () => {
      const userInfoResponse = await authenticatedRequest<UserInfo>({
        url: "/api/security/currentuser",
        method: "GET",
      });

      if (userInfoResponse.ok) {
        dispatch(userLoggedIn(userInfoResponse.body));
      }
      setLoggingIn(false);
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    silentLogin();
  }, [state.authenticated]);

  async function authenticatedRequest<T>(
    requestInfo: AuthenticatedRequestConfig
  ): Promise<AuthenticatedResponse<T>> {
    const response = await axios.request<T>({
      ...requestInfo,
      headers: {
        ...requestInfo.headers,
        "X-CSRF-TOKEN-ETSP": Cookie.get("X-CSRF-TOKEN-ETSP") || "",
      },
      validateStatus: () => true,
    });
    if (response.status < 300) {
      return {
        ok: true,
        status: response.status,
        body: response.data,
      };
    } else if (
      response.status === 401 &&
      window.localStorage.getItem("etn-auth-user")
    ) {
      const renewResponse = await axios.post(
        `api/security/token/refresh?email=${window.localStorage.getItem(
          "etn-auth-user"
        )}`,
        {},
        { validateStatus: () => true }
      );

      if (renewResponse.status === 200) {
        // Try the initial request again
        const retryResponse = await axios.request({
          ...requestInfo,
          validateStatus: () => true,
        });
        return {
          ok: true,
          status: retryResponse.status,
          body: retryResponse.data,
        };
      }
      // Unable to renew the token. If we have a function to call, call it.
      if (props.onUnauthorized) {
        // This could allow for some additional customization for handling a failed login
        await props.onUnauthorized();
      }

      dispatch({ type: "unauthorized" });

      return {
        ok: false,
        status: response.status,
        body: null,
      };
    }
    return {
      ok: false,
      status: response.status,
      body: await parseErrorBody(response),
    };
  }

  const logout = async (): Promise<void> => {
    try {
      await authenticatedRequest({
        url: "/api/security/token",
        method: "DELETE",
      });
    } catch (err) {
      // console.error("Ignoring issue logging out", err);
    }
    dispatch(userLoggedOut());
  };

  const timeout = async (): Promise<void> => {
    logout();
    history.push("/inactive");
  };

  const dispatchHelpers = {
    userLoggedIn: (userInfo: UserInfo): void =>
      dispatch(userLoggedIn(userInfo)),
    authenticatedRequest,
    logout,
    timeout,
  };

  if (loggingIn) return <div />;

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatchHelpers}>
        {props.children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export default AuthContextProvider;

export const MockAuthContextProvider = (
  props: PropsWithChildren<{
    state: AuthContextState;
    dispatch: AuthContextDispatch;
  }>
) => (
  <AuthStateContext.Provider value={props.state}>
    <AuthDispatchContext.Provider value={props.dispatch}>
      {props.children}
    </AuthDispatchContext.Provider>
  </AuthStateContext.Provider>
);
