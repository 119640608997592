import React, { useEffect } from 'react';
import { VehicleApplication } from './application';
import { DefaultPage } from '../default-page';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { RoadTypes } from './roads';

export const HelpPage = () => {
  const classes = useStyles();

  const divider = () => <hr className={classes.dividerLine} />;

  const BasicTextSection = (props: {
    id: string;
    title: string;
    body: string[];
    firstItem?: boolean;
  }) => {
    return (
      <>
        <Typography
          variant="h2"
          id={props.id}
          className={
            props.firstItem
              ? classes.basicTextTitleNoTopMargin
              : classes.basicTextTitle
          }
        >
          {props.title}
        </Typography>
        {props.body.map((bodyCopy, index) => (
          <Typography
            className={classes.basicTextBody}
            key={`${props.id}-${index}`}
          >
            {bodyCopy}
          </Typography>
        ))}
      </>
    );
  };

  const sleep = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  useEffect(() => {
    const locationScroll = async () => {
      if (window.location.hash) {
        await sleep(200);
        const element = document.querySelector(window.location.hash);
        if (element) element.scrollIntoView();
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    locationScroll();
  }, []);

  return (
    <DefaultPage>
      <Typography variant="h1" className={classes.helpTitle}>
        Transmission Spec Tool Help
      </Typography>
      <div className={classes.helpContainer}>
        <div className={classes.topicContainer}>
          <Typography variant="h4" className={classes.topicTitle}>
            Help Topics
          </Typography>
          <Typography className={classes.topicLinks}>
            <a href="/help#Disclaimer" className={classes.topicLink}>
              Disclaimer
            </a>
            <a href="/help#VehicleApplication" className={classes.topicLink}>
              Vehicle Applications
            </a>
            <a href="/help#GCW" className={classes.topicLink}>
              Gross Combined Weight (GCW)
            </a>
            <a href="/help#PercentGrade" className={classes.topicLink}>
              Percent (%) Grade
            </a>
            <a href="/help#RoadTypes" className={classes.topicLink}>
              Road Types
            </a>
            <a href="/help#VehicleSpeeds" className={classes.topicLink}>
              Vehicle Speeds
            </a>
            <a href="/help#PTO" className={classes.topicLink}>
              Power Take-Off (PTO)
            </a>
          </Typography>
        </div>
        <div className={classes.helpInfo}>
          <BasicTextSection
            id="Disclaimer"
            title="On The Use of The Automated Transmission Selector"
            body={[
              `Recommendations do not take the place of a formal Application Approval from Eaton or Eaton Cummins Automated Transmission Technologies or other rules and approvals on behalf of the Original Equipment Manufacturer.`,
              `Transmission model availability along with chassis and engine compatibility should be verified with the Original Equipment Manufacturer dealer.`,
              `The Automated Transmission Selector does have limitations for items such as specialty drivetrains (i.e. auxiliary transmission, transfer case, or 2-speed axle), very high GCW applications or other highly specialized operating conditions.  Please contact us to discuss this type of application.`,
            ]}
            firstItem
          />
          {divider()}
          <VehicleApplication />
          {divider()}
          <BasicTextSection
            id="GCW"
            title="Gross Combined Weight (GCW)"
            body={[
              `Gross Combined Weight or Gross Combination Weight is defined as the maximum combined mass of a road vehicle, passengers, and cargo in the powered vehicle plus the mass of the trailer and any cargo in the trailer.`,
            ]}
          />
          {divider()}
          <BasicTextSection
            id="PercentGrade"
            title="Percent (%) Grade"
            body={[
              `The percent (%) grade is the slope of the road per one hundred units.  When keeping units consistent (feet, meters, etc.), the % grade is the change in height per 100 units of travel.  For example, a 6-foot change of height over a distance of 100 feet equals a 6% grade.  An alternative calculation is (rise / run) x 100.`,
              `Most all interstate highways in the United States are at a 6% grade maximum.`,
              `Degrees of angle should not be confused with % grade.  For example, a 45-degree angle would be 100% grade.`,
            ]}
          />
          {divider()}
          <RoadTypes />
          {divider()}
          <BasicTextSection
            id="VehicleSpeeds"
            title="Vehicle Speeds"
            body={[
              `Intended cruise speed is the most typical speed when operating on the highway.`,
              `Occasional maximum road speed is the highest planned road speed of the vehicle when operating on the highway.`,
            ]}
          />
          {divider()}
          <BasicTextSection
            id="PTO"
            title="Power Take-Off (PTO)"
            body={[
              `A Power Take-Off (PTO) is a device that transfers power from an engine to another piece of equipment.  The PTO can be directly coupled to the transmission, engine, or driveline.`,
            ]}
          />
        </div>
      </div>
    </DefaultPage>
  );
};
