import { EmailStates, AccountCreationState } from '.';
import { useStyles } from './styles';
import React from 'react';
import { Typography, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { EatonButton, EatonLinkButton } from '../button';
import { EmailField, validateEmail } from '../email-field';

export const PureEmailPrompt = (props: {
  onSendVerification: () => void;
  email: string;
  setEmail: (email: string) => void;
  emailSubmissionState: EmailStates;
}) => {
  const classes = useStyles();

  return (
    <form
      className={classes.createAccountDetails}
      onSubmit={(e) => {
        e.preventDefault();
        props.onSendVerification();
      }}
    >
      <Typography className={classes.createAccountSubheading} variant="h3">
        Enter Your Email
      </Typography>
      <EmailField
        email={props.email}
        setEmail={props.setEmail}
        error={props.emailSubmissionState === AccountCreationState.EmailExists}
        disable={
          props.emailSubmissionState ===
          AccountCreationState.SendingEmailVerification
        }
        otherErrorText={
          <>
            That email already has an account. Please use another email or{' '}
            <Link component={RouterLink} to="/login">
              log in.
            </Link>{' '}
          </>
        }
        className={classes.createAccountField}
      />
      <EatonButton
        type="submit"
        data-testid="submitbutton"
        disabled={
          !validateEmail(props.email) ||
          props.emailSubmissionState ===
            AccountCreationState.SendingEmailVerification
        }
        className={classes.submitButton}
        loadingSpinner={
          props.emailSubmissionState ===
          AccountCreationState.SendingEmailVerification
        }
      >
        Send Verification Email
      </EatonButton>
      <EatonLinkButton
        variant="outlined"
        disabled={
          props.emailSubmissionState ===
          AccountCreationState.SendingEmailVerification
        }
        to="/"
        className={classes.submitButton}
      >
        No, Thanks
      </EatonLinkButton>
      {props.emailSubmissionState === AccountCreationState.EmailFailed && (
        <Typography className={classes.errorText}>
          <br />
          Something went wrong. Please try again.
        </Typography>
      )}
    </form>
  );
};
