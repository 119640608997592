import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { DefaultPage } from '../default-page';
import { EatonLinkButton } from '../button';

export function Unauthorized() {
  return (
    <div style={{ marginTop: '100px', textAlign: 'center' }}>
      <Typography variant='h1' style={{ marginBottom: '32px' }}>
        Access Denied
      </Typography>
      <Typography style={{ marginBottom: '32px' }}>
        You are not authorized to view this page.
      </Typography>
      <EatonLinkButton to='/'>Take me back home</EatonLinkButton>
    </div>
  );
}
