import React, { useMemo } from 'react';
import { TransmissionDisplay, MatchType } from '../transmission-result';
import {
  Survey,
  ManeuverToLabelMappings,
  Maneuver,
  EngineMakeToLabelMappings,
} from '../../domain/survey';
import {
  Page,
  Text,
  View,
  Document,
  Image,
  Font,
  PDFDownloadLink,
  Link,
} from '@react-pdf/renderer';
import {
  PDFRichText,
  UnorderedList,
  UnorderedListItem,
  HeaderTwo,
} from './markdown-pdf';
import { pixelsToPoints, formattedDateString } from './helpers';
import { startCase, isArray, findKey } from 'lodash';
import {
  UnitType,
  ImperialUnit,
  Unit,
  UnitMapping,
} from '../../domain/unit-converstions';
import {
  ImageQuestionMapping,
  TerrainToLabelMappings,
  RoadTypeToLabelMappings,
} from '../../domain/image-mappings';
import { ImageQuestionDetails } from '../pure-survey/image-question';
import { makeClasses } from './styles';
import { EatonButton } from '../button';
import { CircularProgress } from '@material-ui/core';
import { func } from 'prop-types';

Font.register({
  family: 'Open Sans',
  src: 'https://fonts.gstatic.com/s/opensans/v13/IgZJs4-7SA1XX_edsoXWog.ttf',
  fonts: [
    {
      src:
        'https://fonts.gstatic.com/s/opensans/v13/IgZJs4-7SA1XX_edsoXWog.ttf',
      fontWeight: 400,
    },
    {
      src:
        'https://fonts.gstatic.com/s/opensans/v13/O4NhV7_qs9r9seTo7fnsVKCWcynf_cDxXwCLxiixG1c.ttf',
      fontWeight: 400,
      fontStyle: 'italic',
    },
    {
      src:
        'https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSi3USBnSvpkopQaUR-2r7iU.ttf',
      fontWeight: 600,
    },
    {
      src:
        'https://fonts.gstatic.com/s/opensans/v13/PRmiXeptR36kaC0GEAetxpZ7xm-Bj30Bj2KNdXDzSZg.ttf',
      fontWeight: 600,
      fontStyle: 'italic',
    },
    {
      src:
        'https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzC3USBnSvpkopQaUR-2r7iU.ttf',
      fontWeight: 700,
    },
    {
      src:
        'https://fonts.gstatic.com/s/opensans/v13/PRmiXeptR36kaC0GEAetxne1Pd76Vl7zRpE7NLJQ7XU.ttf',
      fontWeight: 700,
      fontStyle: 'italic',
    },
    {
      src:
        'https://fonts.gstatic.com/s/opensans/v13/EInbV5DfGHOiMmvb1Xr-hi3USBnSvpkopQaUR-2r7iU.ttf',
      fontWeight: 800,
    },
    {
      src:
        'https://fonts.gstatic.com/s/opensans/v13/PRmiXeptR36kaC0GEAetxg89PwPrYLaRFJ-HNCU9NbA.ttf',
      fontWeight: 800,
      fontStyle: 'italic',
    },
  ],
});

const classes = makeClasses();
const placeholderImg = '../../transmission-no-image.png';

let getBase64Type = function(base64ImageString: string) : string {
  const pngBase64Prefix = 'data:image/png;base64,'
  const jpgBase64Prefix = 'data:image/jpeg;base64,'
  const imageData = base64ImageString.split(',')[1];
  if(imageData == undefined)
    return placeholderImg
  if(imageData.charAt(0) == '/') 
    return jpgBase64Prefix + imageData
  else
    return pngBase64Prefix + imageData
}

const PDFTransmissionResult = (resultProps: {
  matchType: MatchType;
  transmission: TransmissionDisplay;
}) => (
  <View
    style={{
      ...classes.container,
      ...classes.typography,
      ...(resultProps.matchType === MatchType.TopMatch
        ? classes.containerTopChoice
        : classes.containerRunnerUp),
    }}
  >
    <View style={classes.imageContainer}>
      <Image
        style={
          resultProps.transmission.image
            ? classes.image
            : classes.imageNoTransmission
        }
        src={
          resultProps.transmission.image 
            ? getBase64Type(resultProps.transmission.image)
            : placeholderImg
          }
      />
    </View>
    <View style={classes.contentContainer}>
      <Text style={classes.matchType}>{resultProps.matchType}</Text>
      <Text style={classes.transmissionTitle}>
        {resultProps.transmission.name}
      </Text>
      <View>
        {PDFRichText({ note: resultProps.transmission.description })}

        <UnorderedList>
          <UnorderedListItem>
            Model: {resultProps.transmission.modelName}
          </UnorderedListItem>
          <UnorderedListItem>
            Suggested Axle Ratio Range: {resultProps.transmission.axleRatio}
          </UnorderedListItem>
        </UnorderedList>
      </View>

      <Text style={classes.learnMore}>
        Contact your Eaton Representative to learn more.
      </Text>
    </View>
  </View>
);

const SimpleSurveyPart = (simpleSurveyProps: {
  title: string;
  body: string;
}) => {
  return (
    <View>
      <Text style={classes.detailHeader}>{simpleSurveyProps.title}</Text>
      <Text style={classes.detailBody}>{simpleSurveyProps.body}</Text>
    </View>
  );
};

const MultiSurveyPart = (multiPartProps: {
  title: string;
  parts: Array<{ subTitle: string; content: string | number }>;
}) => (
  <View style={classes.detailBody}>
    <Text style={classes.detailHeader}>{multiPartProps.title}</Text>
    {multiPartProps.parts.map((lineItem, index) => (
      <View style={classes.inRowSurveyItem} key={`${lineItem}-multi-${index}`}>
        <Text style={classes.matchType}>{lineItem.subTitle}:&nbsp;</Text>
        <Text>{lineItem.content}</Text>
      </View>
    ))}
  </View>
);

function LabelMappedSurveyPart<T extends string>(simplePartProps: {
  title: string;
  body: T[] | T;
  labelMapping: { [key in T]: string } | ImageQuestionMapping<T>;
  subtitleMapping?: boolean;
}) {
  const Item = (itemProps: { body: T }) => {
    const rawLabel: ImageQuestionDetails | string =
      simplePartProps.labelMapping[itemProps.body];
    const label =
      typeof rawLabel === 'string'
        ? rawLabel
        : simplePartProps.subtitleMapping
        ? `${rawLabel.title} ${rawLabel.subtitle || ''}`
        : rawLabel.title;
    return <Text style={classes.detailListItem}>{label}</Text>;
  };

  return (
    <View>
      <Text style={classes.detailHeader}>{simplePartProps.title}</Text>
      <View style={classes.detailBody}>
        {isArray(simplePartProps.body) ? (
          simplePartProps.body.map((lineItem, index) => (
            <Item key={`${lineItem}-simple-${index}`} body={lineItem} />
          ))
        ) : (
          <Item body={simplePartProps.body} />
        )}
      </View>
    </View>
  );
}

const PDFSurveyResponse = (props: { survey: Survey; unit: Unit }) => {
  const convertAndPrint = (unitType: UnitType, value: ImperialUnit) => {
    return props.unit === Unit.Imperial
      ? `${value.toLocaleString()} ${UnitMapping[unitType].imperial}`
      : `${UnitMapping[unitType].toMetric(value).toLocaleString()} ${
          UnitMapping[unitType].metric
        }`;
  };
  return (
    <View style={classes.responsesContainer}>
      <View style={classes.responsesContentContainer}>
        <Text style={classes.responsesTitle}>Your Responses</Text>
        <View style={classes.responsesDetails}>
          <View style={classes.responsesColumn}>
            <SimpleSurveyPart
              title="Application"
              body={startCase(props.survey.application)}
            />
            <SimpleSurveyPart
              title="Max GCW"
              body={convertAndPrint('weight', props.survey.maxWeight)}
            />
            <LabelMappedSurveyPart
              title="Terrain"
              body={props.survey.terrain}
              labelMapping={TerrainToLabelMappings}
              subtitleMapping
            />
          </View>
          <View style={classes.responsesColumn}>
            <LabelMappedSurveyPart<Maneuver | 'NoManeuvers'>
              title="Maneuvers"
              body={
                props.survey.maneuvers.length > 0
                  ? props.survey.maneuvers
                  : 'NoManeuvers'
              }
              labelMapping={ManeuverToLabelMappings}
            />
            <LabelMappedSurveyPart
              title="Road Type"
              body={props.survey.roadTypes}
              labelMapping={RoadTypeToLabelMappings}
            />
            <SimpleSurveyPart
              title={'PTOs'}
              body={startCase(props.survey.pto)}
            />
          </View>
          <View style={classes.responsesColumn}>
            <MultiSurveyPart
              title="Engine Rating"
              parts={[
                {
                  subTitle: 'Horsepower',
                  content: convertAndPrint('power', props.survey.engineHp),
                },
                {
                  subTitle: 'Torque',
                  content: convertAndPrint('torque', props.survey.engineTorque),
                },
                {
                  subTitle: 'Engine Make',
                  content: EngineMakeToLabelMappings[props.survey.engineMake],
                },
              ]}
            />
            <MultiSurveyPart
              title="Vehicle Speed"
              parts={[
                {
                  subTitle: 'Max',
                  content: convertAndPrint('speed', props.survey.maxSpeed),
                },
                {
                  subTitle: 'Average Cruise',
                  content: convertAndPrint('speed', props.survey.cruiseSpeed),
                },
              ]}
            />
          </View>
          <View style={classes.responsesColumn}>
            <MultiSurveyPart
              title="Performance Goal"
              parts={[
                {
                  subTitle: '1',
                  content: startCase(
                    findKey(props.survey.preferenceRanking, (n) => n === 1) ||
                      '',
                  ),
                },
                {
                  subTitle: '2',
                  content: startCase(
                    findKey(props.survey.preferenceRanking, (n) => n === 2) ||
                      '',
                  ),
                },
                {
                  subTitle: '3',
                  content: startCase(
                    findKey(props.survey.preferenceRanking, (n) => n === 3) ||
                      '',
                  ),
                },
                {
                  subTitle: '4',
                  content: startCase(
                    findKey(props.survey.preferenceRanking, (n) => n === 4) ||
                      '',
                  ),
                },
                {
                  subTitle: '5',
                  content: startCase(
                    findKey(props.survey.preferenceRanking, (n) => n === 5) ||
                      '',
                  ),
                },
              ]}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

const PDFFooter = () => {
  const rootUrl = window.location.origin;
  return (
    <View style={classes.footerContainer}>
      <Text style={classes.transmissionTitle}>
        Contact your Eaton Representative
      </Text>
      <Text style={classes.footerText}>
        The most appropriate transmission will vary with application.&nbsp;
        <Link src={`${rootUrl}/help#Disclaimer`}>
          Learn more by clicking here.
        </Link>
      </Text>
      <Text>
        If you have more specific questions, contact us at 1-800-826-4357, or
        contact your local Roadranger representative.
      </Text>
    </View>
  );
};

interface Props {
  survey: Survey;
  unit: Unit;
  topChoice: TransmissionDisplay;
  runnerUp?: TransmissionDisplay;
  className?: string;
  title?: string;
  notes?: string;
  surveyDate?: Date;
  loading?: boolean;
}

export const TransmissionDoc = (props: Props & { testingDate?: Date }) => {
  const headerInfo = (
    <View>
    {
      props.title || props.notes
    ? <View>
    <View style={classes.specTitleView}>
          <Text style={classes.specTitleSmallText}>
            SPEC:
          </Text>
          <Text style={classes.specTitleLargeText}>
            {props.title}
          </Text>
      </View>
      <View>
        <Text style={classes.matchType}>
          YOUR NOTES:
        </Text>
        <View style={classes.notesContainer}>
          {PDFRichText({ note: props.notes })}
        </View>
      </View>
    </View>
    : (<HeaderTwo style={classes.title}>{`Your Recommended Transmission${
      props.runnerUp ? 's' : ''
    }`}</HeaderTwo>)
}
    </View>
  );
  return (
    <Document>
      <Page
        style={{ ...classes.pageMargins, ...classes.typography }}
        size={{ width: pixelsToPoints(1440) } as any}
      >
        <View style={classes.header}>
          <Image src="../../../../../logo.png" style={classes.logo} />
          <View style={classes.fileDetails}>
            <Text style={classes.fileDetail}>
              Survey Date: {formattedDateString(props.surveyDate || props.testingDate || new Date())}
            </Text>
            <Text style={classes.fileDetail}>
              Print Date: {formattedDateString(props.testingDate || new Date())}
            </Text>
          </View>
        </View>
        <View style={classes.hr} />
          {headerInfo}
        <View>
          <PDFTransmissionResult
            matchType={MatchType.TopMatch}
            transmission={props.topChoice}
          />
          {props.runnerUp && (
            <PDFTransmissionResult
              matchType={MatchType.RunnerUp}
              transmission={props.runnerUp}
            />
          )}
        </View>
        <View>
          <PDFSurveyResponse survey={props.survey} unit={props.unit} />
        </View>
        <View>
          <PDFFooter />
        </View>
      </Page>
    </Document>
  );
};

export const PDFGenerator = (props: Props) => {
  const PDFFunc = (className?: string) =>
    useMemo(() => {
      return (
        <PDFDownloadLink
          style={{ textDecoration: 'none' }}
          className={className}
          document={<TransmissionDoc {...props} />}
          fileName="transmission-spec.pdf"
        >
          {({ blob, url, loading, error }) => {
            return (
              <EatonButton variant="outlined" disabled={loading || props.loading}>
                Download PDF
                {(loading || props.loading) && (
                  <CircularProgress
                    size={24}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: -12,
                      marginLeft: -12,
                    }}
                  />
                )}
              </EatonButton>
            );
          }}
        </PDFDownloadLink>
      );
    }, [props.unit, props.loading]);
  return PDFFunc(props.className);
};
