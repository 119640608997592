import React, { useState } from 'react';
import { Pto } from '../../../domain/survey';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { startCase } from 'lodash';
import { SurveyPageHeader } from '../survey-page-header';
import { useStyles } from './styles';

export function PTOQuestion(props: {
  value: Pto | null;
  setValue: (value: Pto | null) => void;
}) {
  const classes = useStyles();
  const [needPto, setNeedPto] = useState<boolean | ''>(
    props.value === null ? '' : props.value !== Pto.NoPTO,
  );
  return (
    <div className={classes.ptoContainer}>
      <FormControl component="fieldset" className={classes.topHalfContainer}>
        <RadioGroup
          aria-label="Need PTO"
          name="need-pto"
          value={needPto}
          onChange={(
            event: React.ChangeEvent<HTMLInputElement>,
            value: string,
          ) => {
            const needPtoChecked = value === 'true';
            props.setValue(needPtoChecked ? null : Pto.NoPTO);
            setNeedPto(needPtoChecked);
          }}
        >
          <FormControlLabel
            className="pto-no"
            data-testid="pto-no"
            value={false}
            control={<Radio />}
            label="No PTO needs"
          />
          <FormControlLabel
            className="pto-yes"
            data-testid="pto-yes"
            value={true}
            control={<Radio />}
            label="Yes, PTO needs"
          />
        </RadioGroup>
      </FormControl>
      {needPto && (
        <FormControl component="fieldset">
          <SurveyPageHeader
            headerText="How many transmission PTOs does the vehicle need?"
            helpLink="/help#PTO"
            smallMargin
          />
          <RadioGroup
            aria-label="PTO Options"
            name="pto-options"
            value={props.value || 0}
            onChange={(
              event: React.ChangeEvent<HTMLInputElement>,
              value: string,
            ) => {
              props.setValue((value as unknown) as Pto);
            }}
          >
            <FormControlLabel
              className="pto-1"
              data-testid="pto-1"
              value={Pto.OnePto}
              control={<Radio />}
              label={startCase(Pto.OnePto)}
            />
            <FormControlLabel
              className="pto-2"
              data-testid="pto-2"
              value={Pto.TwoPto}
              control={<Radio />}
              label={`${startCase(Pto.TwoPto)}s`}
            />
            <FormControlLabel
              className="pto-3"
              data-testid="pto-2"
              value={Pto.ThreeOrMorePto}
              control={<Radio />}
              label={'Three or more PTOs'}
            />
          </RadioGroup>
        </FormControl>
      )}
    </div>
  );
}
