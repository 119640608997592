import React, { useState } from 'react';
import * as Colors from '@pxblue/colors';
import { ImageQuestionMapping } from '../../../domain/image-mappings';

import {
  FormControl,
  FormLabel,
  Typography,
  CheckboxProps,
  Checkbox,
} from '@material-ui/core';
import { cssClassName } from '..';
import { useStyles } from './styles';

export function ImageQuestion<T extends string>(props: {
  name: string;
  values: { [key: string]: T };
  value: T[] | null;
  setValue: (value: T[] | null) => void;
  labelMapping: ImageQuestionMapping<T>;
}) {
  const classes = useStyles();

  const handleChange = (name: T) => (checked: boolean) => {
    if (!checked) {
      const newValue = (props.value || []).filter((value) => value !== name);
      if (newValue.length === 0) {
        props.setValue(null);
      } else {
        props.setValue(newValue);
      }
    } else if (!props.value?.includes(name)) {
      props.setValue((props.value || []).concat(name));
    }
  };

  return (
    <FormControl component="fieldset">
      <FormLabel>
        <Typography className={classes.contentLabel}>
          Select all that apply.
        </Typography>
      </FormLabel>
      <div className={classes.optionsContainer}>
        {Object.values(props.values).map((value) => (
          <ImageOption
            key={cssClassName(value)}
            checked={props.value?.includes(value) || false}
            title={props.labelMapping[value].title}
            subtitle={props.labelMapping[value].subtitle}
            icon={props.labelMapping[value].icon}
            onClick={() => {
              handleChange(value)(!props.value?.includes(value));
            }}
          />
        ))}
      </div>
    </FormControl>
  );
}

export type ImageQuestionDetails = {
  title: string;
  subtitle?: string;
  icon: (color: string) => JSX.Element;
};

export const ImageOption = (
  props: {
    onClick: () => void;
    checked: boolean;
  } & ImageQuestionDetails,
) => {
  const classes = useStyles();
  return (
    <div
      className={`${classes.imageOptionContainer} ${cssClassName(props.title)}${
        props.checked ? '-checked' : ''
      }`}
      onClick={props.onClick}
    >
      <div
        className={`${classes.imageOption} ${
          props.checked ? classes.selectedOption : ''
        }`}
      >
        <div
          className={`${classes.checkmarkBubble} ${
            props.checked
              ? classes.selectedCheckmarkBubble
              : classes.unselectedCheckmarkBubble
          }`}
        >
          {props.checked && <div className={classes.selectedCheckmark} />}
        </div>

        <div className={classes.iconContainer}>
          {props.icon(props.checked ? Colors.white[100] : Colors.blue[600])}
        </div>
        <Typography
          className={`${classes.titleText} ${
            props.checked
              ? classes.selectedTitleText
              : classes.unselectedTitleText
          }`}
        >
          {props.title}
        </Typography>
        <Typography className={classes.subtitleText}>
          {props.subtitle}
        </Typography>
      </div>
    </div>
  );
};
