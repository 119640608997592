import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    outerContainer: {
      height: '100%',
      display: 'flex',
      flexGrow: 1,
      alignSelf: 'center',
    },
    mainContainer: {
      width: '970px',
      paddingTop: '64px',
      paddingLeft: '150px',
      paddingRight: '150px',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      paddingBottom: '64px',
      boxShadow:
        '0px 11px 15px rgba(0, 0, 0, 0.2), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 24px 38px rgba(0, 0, 0, 0.14)',
    },
    splashItemsContainer: {
      textAlign: 'center',
    },
    text: {
      marginBottom: '16px',
    },
  };
});
