import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    ptoContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    topHalfContainer: {
      marginBottom: '64px',
    },
  };
});
