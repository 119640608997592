import React from 'react';

export const ExpandIcon = (props: { className?: string }) => (
  <svg
    className={props.className}
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="14" cy="14" r="14" fill="#E0EFF8"/>
    <path d="M20.9688 14.9688H14.9688V20.9688H13V14.9688H7V13H13V7H14.9688V13H20.9688V14.9688Z" fill="#007BC1"/>
  </svg>
);
