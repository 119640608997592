import React from 'react';
import { Toolbar, Typography, Link, withStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useStyles, FooterHeight } from './styles';
import { kebabCase } from 'lodash';
import { HelpIconInactive } from '../../../icons/help-icon-inactive';
import { Gutters } from '../../../styles/gutters';
import { useAuthState, AuthStatus } from '../../reusable-components/components/AuthContext';

export const AppFooter = () => {
  const classes = useStyles();
  const authState = useAuthState();
  const copyrightText = '\u00A92020 Eaton Cummins. All Rights Reserved.';
  const FooterLink = (props: {
    name: string;
    link: string;
    contactUs?: boolean;
    newTab?: boolean;
  }) => {
    if (props.newTab) {
      return (
        <Link
          href={props.link}
          target="_blank"
          rel="noopener noreferrer"
          className={`${classes.footerLink} ${classes.footerLinkText}`}
          data-testid={kebabCase(props.name)}
        >
          {props.name}
        </Link>
      );
    } else {
      return (
        <Link
          component={RouterLink}
          to={props.link}
          className={`${!props.contactUs ? classes.footerLink : ''} ${
            classes.footerLinkText
          }`}
          data-testid={kebabCase(props.name)}
        >
          {props.name}
        </Link>
      );
    }
  };

  const StyledToolbar = withStyles({
    root: {
      minHeight: FooterHeight,
      ...Gutters(),
    },
  })(Toolbar);

  return (
    <>
      <StyledToolbar className={classes.footerToolbar} />
      <footer className={classes.footer}>
        <StyledToolbar className={classes.footerToolbar}>
          <div className={classes.footerToolbarItem}>
            <Typography className={classes.footerText}>
              {copyrightText}
            </Typography>
          </div>
          <span className={classes.footerLinkList}>
            <FooterLink name="Privacy Policy" link="https://www.eatoncummins.com/us/en-us/company/policies.html" newTab />
            <FooterLink name="Terms & Conditions" link="https://www.eatoncummins.com/us/en-us/company/policies.html" newTab />
            <FooterLink name="Create Spec" link="/survey" />
            { authState.authenticated ===  AuthStatus.Authenticated && <FooterLink name="My Specs" link="/spec"/> }
          </span>
          <div className={classes.footerToolbarItem}>
            <HelpIconInactive className={classes.helpIcon} />
            <Typography
              className={`${classes.footerText} ${classes.contactUs}`}
            >
              Need assistance?{' '}
              <FooterLink name="Contact Us." link="https://www.eatoncummins.com/us/en-us/support.html" contactUs newTab />
            </Typography>
          </div>
        </StyledToolbar>
      </footer>
    </>
  );
};
