import React from 'react';
import { useLogin, useAuthState } from '../../components/reusable-components';
import { AuthStatus } from '../../components/reusable-components/components/AuthContext';
import Cookies from 'js-cookie';
import { Redirect } from 'react-router';
import UserLogin from '../../components/user-login';

export const LoginPage = (props: {}) => {
  const login = useLogin();
  const authState = useAuthState();
  if (authState.authenticated === AuthStatus.Authenticated) {
    const redirectTo = Cookies.get('etn-destination') || '/';
    Cookies.remove('etn-destination');
    return <Redirect to={redirectTo} />;
  }
  return <UserLogin submitForm={login} />;
};
