import React, { useState, useContext } from 'react';
import { FormLabel, Slider, Typography } from '@material-ui/core';
import { cssClassName } from '..';
import { useStyles } from './styles';
import { FormattedNumberInput } from '../../number-input';
import { Unit, UnitMapping, UnitType } from '../../../domain/unit-converstions';
import { UnitContext } from '../../unit-switcher';

export function SliderQuestion(props: {
  value: number | null;
  setValue: (value: number) => void;
  minValue: number;
  maxValue: number;
  name: string;
  units: UnitType;
  stepSize?: number;
  showValueLabels?: boolean;
}) {
  const classes = useStyles();

  const unitContext = useContext(UnitContext);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let { value, minValue, maxValue } = props;
  if (unitContext.units === Unit.Metric) {
    value = value ? UnitMapping[props.units].toMetric(value) : null;
    minValue = UnitMapping[props.units].toMetric(minValue);
    maxValue = UnitMapping[props.units].toMetric(maxValue);
  }
  const unitLabel =
    unitContext.units === Unit.Imperial
      ? UnitMapping[props.units].imperial
      : UnitMapping[props.units].metric;

  const [currValue, setCurrValue] = useState<number>(
    props.value || props.minValue,
  );

  const setValues = (newValue: number) => {
    const imperialValue =
      unitContext.units === Unit.Imperial
        ? newValue
        : UnitMapping[props.units].fromMetric(newValue);
    setCurrValue(imperialValue);
    props.setValue(imperialValue);
  };

  const displayValue =
    unitContext.units === Unit.Imperial
      ? currValue
      : UnitMapping[props.units].toMetric(currValue);

  return (
    <div className={classes.sliderContainer}>
      <div className={classes.topContainer}>
        <FormLabel component="legend">
          <Typography variant="h4">{props.name}</Typography>
        </FormLabel>
        <div className={classes.inputContainer}>
          <FormattedNumberInput
            value={displayValue}
            onChange={(newValue: number) => setValues(newValue)}
            step={props.stepSize}
            min={minValue}
            max={maxValue}
            dataTestId={`${cssClassName(props.name)}-input`}
            className={`${cssClassName(props.name)}-input`}
          />
          <div className={classes.unitLabel}>
            <Typography>{unitLabel}</Typography>
          </div>
        </div>
      </div>
      <div>
        <Slider
          valueLabelFormat={
            props.showValueLabels
              ? (value) => value.toLocaleString()
              : undefined
          }
          step={props.stepSize}
          value={parseInt(`${displayValue}`) || minValue}
          name={cssClassName(props.name)}
          onChangeCommitted={(event, value) => {
            setValues(value as number);
          }}
          onChange={(event, value) => {
            setCurrValue(
              unitContext.units === Unit.Imperial
                ? (value as number)
                : UnitMapping[props.units].fromMetric(value as number),
            );
          }}
          min={minValue}
          max={maxValue}
          getAriaValueText={(value) => `${value.toLocaleString()} ${unitLabel}`}
          aria-labelledby="discrete-slider-always"
          valueLabelDisplay={props.showValueLabels ? 'on' : 'off'}
          marks={
            props.showValueLabels
              ? [
                  {
                    value: minValue,
                    label: `${minValue.toLocaleString()} ${unitLabel}`,
                  },
                  {
                    value: maxValue,
                    label: `${maxValue.toLocaleString()} ${unitLabel}`,
                  },
                ]
              : undefined
          }
        />
      </div>
    </div>
  );
}
