export interface Survey {
  application: Application;
  cruiseSpeed: number;
  engineHp: number;
  engineMake: EngineMake;
  engineTorque: number;
  maneuvers: Maneuver[];
  maxWeight: number;
  maxSpeed: number;
  preferenceRanking: PreferenceRanking;
  pto: Pto;
  roadTypes: RoadType[];
  terrain: Terrain[];
}

export interface PreferenceRanking {
  acceleration: number;
  fuelEconomy: number;
  lowSpeedManeuvers: number;
  price: number;
  tripTime: number;
}

export enum Application {
  Agriculture = 'Agriculture',
  Construction = 'Construction',
  Fire = 'Fire',
  HeavyHaul = 'HeavyHaul',
  Linehaul = 'Linehaul',
  Logging = 'Logging',
  Mixer = 'Mixer',
  OilField = 'OilField',
  PickUpAndDelivery = 'PickUpAndDelivery',
  Recreational = 'Recreational',
  Refuse = 'Refuse',
  DoorToDoorRefuse = 'DoorToDoorRefuse',
  Rescue = 'Rescue',
  Transit = 'Transit',
}

export enum EngineMake {
  CumminsDiesel = 'CumminsDiesel',
  CumminsNaturalGas = 'CumminsNaturalGas',
  Paccar = 'PACCAR',
  International = 'International',
  Detroit = 'Detroit',
  Other = 'Other',
}

export const EngineMakeToLabelMappings: { [key in EngineMake]: string } = {
  CumminsDiesel: 'Cummins (Diesel)',
  CumminsNaturalGas: 'Cummins (Natural Gas)',
  PACCAR: 'PACCAR',
  International: 'International',
  Detroit: 'Detroit',
  Other: 'Other',
};

export enum Maneuver {
  FastInReverse = 'FastInReverse',
  Mud = 'Mud',
  RepeatedLaunches = 'RepeatedLaunches',
  UrbanStopAndGo = 'UrbanStopAndGo',
  SlowCreeping = 'SlowCreeping',
}

export const ManeuverToLabelMappings: { [key in (Maneuver | 'NoManeuvers')]: string } = {
  FastInReverse: 'Drive fast in reverse (greater than 5 mph or 8 kph)',
  Mud: 'Frequent maneuvers in mud',
  RepeatedLaunches: 'Repeated launches or maneuvering',
  SlowCreeping: 'Very slow, sustained creeping (<0.5 mph or <0.8 kph)',
  UrbanStopAndGo: 'Urban stop and go traffic',
  NoManeuvers: 'No Maneuvers',
};

export const PreferenceRankingToLabelMappings: { [P in keyof PreferenceRanking]: string } = {
  acceleration: 'Acceleration from a stop',
  fuelEconomy: 'Fuel economy',
  lowSpeedManeuvers: 'Job site low speed maneuverability',
  price: 'Purchase price',
  tripTime: 'Route / trip time',
};

export enum Pto {
  NoPTO = 'NoPTO',
  ThreeOrMorePto = 'ThreeOrMorePTO',
  OnePto = 'OnePTO',
  TwoPto = 'TwoPTO',
}

export enum RoadType {
  Paved = 'Paved',
  MaintainedGravel = 'MaintainedGravel',
  OffRoad = 'OffRoad',
}

export enum Terrain {
  Flat = 'Flat',
  HillyRolling = 'HillyRolling',
  OccasionalMountain = 'OccasionalMountain',
  FrequentMountain = 'FrequentMountain',
  SteepGrade = 'SteepGrade',
}
