import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  units: {
    fontSize: '12px',
    color: theme.palette.grey[800],
    paddingRight: '8px',
  },
  unitSwitcher: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
}));
