import React from 'react';
import { Typography, Paper } from '@material-ui/core';
import { EatonLinkButton } from '../button';
import { useStyles } from './styles';

export const AppSplashNotLoggedIn = () => {
  const classes = useStyles();
  const headerText = 'Save your specs. Reference them later.';
  const subtitleText =
    'Clicking the button below offers the option to save your specs for future access by completing a simple registration. This will also allow creation of a printable pdf form of your recommendations.';
  return (
    <div className={classes.outerContainer}>
      <Paper className={classes.mainContainer}>
        <div className={classes.splashItemsContainer}>
          <Typography variant="h2" className={classes.text}>
            {headerText}
          </Typography>
          <Typography variant="subtitle1" className={classes.text}>
            {subtitleText}
          </Typography>
          <EatonLinkButton to="/register">Create Account</EatonLinkButton>
        </div>
      </Paper>
    </div>
  );
};
