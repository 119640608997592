import React, { useState, useContext } from 'react';
import { CheckboxQuestion } from '../checkbox-question';
import { Maneuver, ManeuverToLabelMappings } from '../../../domain/survey';
import { Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import { useStyles } from './styles';
import { UnitContext } from '../../unit-switcher';
import { Unit } from '../../../domain/unit-converstions';

export const ManeuversQuestion = (props: {
  maneuvers: Maneuver[] | null;
  setValue: (maneuvers: Maneuver[] | null) => void;
}) => {
  const classes = useStyles();

  const unitContext = useContext(UnitContext);

  const [slowCreepingChecked, setSlowCreepingChecked] = useState(
    props.maneuvers?.includes(Maneuver.SlowCreeping),
  );
  const [withheldValues, setWithheldValues] = useState<Maneuver[] | null>(null);

  const slowCreepText = unitContext.units === Unit.Metric
    ? `Your selection indicates the intended vehicle use will be collecting
    or discharging materials on a job site at very slow speeds for
    sustained time intervals. These speeds are in the range of 0.8 kph
    or approximately 13 m/min (typical walking speed ~ 5 kph).`
    : `Your selection indicates the intended vehicle use will be collecting
    or discharging materials on a job site at very slow speeds for
    sustained time intervals. These speeds are in the range of 0.5 mph
    or 44 ft/min (typical walking speed ~ 3 mph).`;

  return (
    <>
      <CheckboxQuestion
        name="Maneuvers"
        values={Maneuver}
        value={withheldValues || props.maneuvers}
        setValue={(maneuvers: Maneuver[] | null) => {
          setSlowCreepingChecked(!!maneuvers?.includes(Maneuver.SlowCreeping));

          // If the new list includes SlowCreeping and it was not in the list already
          if (
            !!maneuvers?.includes(Maneuver.SlowCreeping) &&
            !props.maneuvers?.includes(Maneuver.SlowCreeping)
          ) {
            setWithheldValues(maneuvers); // Save the list of maneuvers for after they check the confirmation
            props.setValue(null); // And set the maneuvers to null until it's done
          } else {
            setWithheldValues(null);
            props.setValue(maneuvers);
          }
        }}
        allowNone
        labelMapping={ManeuverToLabelMappings}
      />
      {slowCreepingChecked && (
        <div className={classes.confirmationDialogContainer}>
          <Typography variant="h3" className={classes.confirmationDialogHeader}>
            Confirm your use case.
          </Typography>
          <Typography className={classes.confirmationDialogBody}>
            {slowCreepText}
          </Typography>
          <Typography className={classes.confirmationDialogBodyBold}>
            Confirm your use case has reasonable alignment to this description.
          </Typography>
          <FormControlLabel
            data-testid={'confirm-slow-creep'}
            className={'confirm-slow-creep'}
            value={Maneuver.SlowCreeping}
            checked={!!props.maneuvers?.includes(Maneuver.SlowCreeping)}
            control={<Checkbox />}
            label={'My use case aligns to the description above.'}
            onChange={(event, checked) => {
              if (checked) {
                props.setValue(
                  (props.maneuvers || [])
                    .filter((m) => m !== Maneuver.SlowCreeping)
                    .concat(Maneuver.SlowCreeping),
                );
              } else {
                setWithheldValues(withheldValues || props.maneuvers);
                props.setValue(null);
              }
            }}
          />
        </div>
      )}
    </>
  );
};
