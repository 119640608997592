import React, { useContext, useState, useEffect } from 'react';
import { PureSurveyFooter } from '../pure-survey-footer';
import { EatonButton, EatonLinkButton } from '../button';
import { Typography, TextField } from '@material-ui/core';
import { DefaultPage } from '../default-page';
import { useStyles } from './styles';
import {
  TransmissionResult,
  MatchType,
  TransmissionDisplay,
} from '../transmission-result';
import { SurveyResponses, Drawer } from '../survey-responses';
import { Survey } from '../../domain/survey';
import { PDFGenerator } from '../pdf-generator';
import { UnitContext } from '../unit-switcher';
import {
  useAuthState,
  AuthStatus,
  useAuthDispatch,
} from '../reusable-components/components/AuthContext';
import { RichTextEditor } from '../rich-text-editor';
import { debounce } from 'lodash';
import { SavedSurveyResult } from '../../domain/saved-result';
import { useHistory } from 'react-router-dom';
import { Unit } from '../../domain/unit-converstions';

type Props = {
  survey: Survey;
  cancelLink: string;
  noTransmissionMessage?: string;
  topChoice?: TransmissionDisplay;
  runnerUp?: TransmissionDisplay;
  debug?: any;
  _inTest?: boolean;
};

export const SurveyResult = (props: Props) => {
  const classes = useStyles();
  const unitContext = useContext(UnitContext);
  const router = useHistory();
  const authState = useAuthState();
  const { authenticatedRequest } = useAuthDispatch();

  // Save Spec Dependent Items
  const [viewingEditingState, setViewingEditingState] = useState<
    'viewingResult' | 'editingForSave'
  >('viewingResult');

  const [specTitle, setSpecTitle] = useState<string>('');

  const [specNotes, setSpecNotes] = useState<string>('');

  const canViewSurveyResult =
    authState.authenticated === AuthStatus.Authenticated &&
    authState.privileges.operations.includes('GetSurveyResult');
  const canEditSurveyResult =
    authState.authenticated === AuthStatus.Authenticated &&
    authState.privileges.operations.includes('EditSurveyResult');
  const hasViewAndEditPermission = canViewSurveyResult && canEditSurveyResult;

  const [saving, setSaving] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<boolean>(false);

  const footerLhs = (
    <EatonLinkButton variant="outlined" color="secondary" to={props.cancelLink}>
      Cancel
    </EatonLinkButton>
  );

  const DebugInfo = props.debug && (
    <Drawer header="Transmission Logic Log" startExpanded={false}>
      <Typography component={'span'} className={classes.debugText}>
        <b>NOTE: Calculations below are in Imperial units.</b>
        <br />
        <br />
        {props.debug}
      </Typography>
    </Drawer>
  );

  const saveSpec = async () => {
    setSaving(true);
    try {
      const result = await authenticatedRequest<SavedSurveyResult>({
        method: 'POST',
        url: `/api/surveyresult`,
        data: {
          notes: specNotes,
          result: `${JSON.stringify({
            topChoice: props.topChoice,
            runnerUp: props.runnerUp,
          })}`,
          survey: `${JSON.stringify(props.survey)}`,
          title: specTitle.trim(),
          version: '1.0',
        },
      });
      if (result.status < 300) {
        const id = (result.body as SavedSurveyResult).id;
        router.push(`/spec/${id}`);
      } else {
        setSaving(false);
        setSubmitError(true);
      }
    } catch {
      setSaving(false);
      setSubmitError(true);
    }
  };

  if (props.noTransmissionMessage) {
    return (
      <>
        <DefaultPage>
          <div className={classes.noTransmissionContainer}>
            <Typography className={classes.contactSubheader}>
              {props.noTransmissionMessage}
              <br />
              For your unique case, please contact your Eaton Representative.
            </Typography>
            <Typography>
              <a
                href="https://www.eaton.com/us/en-us/locate/roadranger-contacts.html"
                className={classes.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Locate your representative.
              </a>
            </Typography>
          </div>
          <div className={classes.surveyResponses}>
            {
              <SurveyResponses
                survey={props.survey}
                unit={unitContext.units}
                startExpanded={true}
              />
            }
            {DebugInfo}
          </div>
        </DefaultPage>
        <PureSurveyFooter lhs={footerLhs} rhs={undefined} />
      </>
    );
  }

  if (viewingEditingState === 'editingForSave') {
    return (
      <PureEditSurveyResult
        {...props}
        setSpecNotes={setSpecNotes}
        specNotes={specNotes}
        setSpecTitle={setSpecTitle}
        specTitle={specTitle}
        submitError={submitError}
        units={unitContext.units}
        saving={saving}
        cancelButton={footerLhs}
        saveSpec={saveSpec}
      />
    );
  }

  const SavePDFButton =
    !props._inTest && props.topChoice ? (
      <PDFGenerator
        survey={props.survey}
        topChoice={props.topChoice}
        runnerUp={props.runnerUp}
        className={classes.pdfButton}
        unit={unitContext.units}
      />
    ) : undefined;

  const StartSaveSpecButton = props.topChoice ? (
    <EatonButton
      onClick={() => {
        setViewingEditingState('editingForSave');
      }}
      data-testid="save-spec-start"
      id="save-spec-start"
    >
      Save Spec
    </EatonButton>
  ) : undefined;

  const rhsButtons = (
    <>
      <EatonButton className={hasViewAndEditPermission ? classes.pdfButton : undefined}>
        <a
          href="https://www.eatoncummins.com/us/en-us/products/transmissions.html"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.transmissionButtonLink}
        >
          View Transmission Portfolio
        </a>
      </EatonButton>
      {hasViewAndEditPermission && SavePDFButton}
      {hasViewAndEditPermission && StartSaveSpecButton}
    </>
  );

  return (
    <>
      <DefaultPage>
        <div className={classes.resultTitleContainer}>
          {props.topChoice && (
            <Typography variant="h1" className={classes.header}>
              {props.topChoice && props.runnerUp
                ? 'Your Recommended Transmissions'
                : 'Your Recommended Transmission'}
            </Typography>
          )}
        </div>
        {props.topChoice && (
          <div className={classes.transmissionResult}>
            <TransmissionResult
              matchType={MatchType.TopMatch}
              transmission={props.topChoice}
            />
          </div>
        )}
        {props.runnerUp && (
          <div className={classes.transmissionResult}>
            <TransmissionResult
              matchType={MatchType.RunnerUp}
              transmission={props.runnerUp}
            />
          </div>
        )}
        <div className={classes.surveyResponses}>
          {
            <SurveyResponses
              survey={props.survey}
              unit={unitContext.units}
              startExpanded={true}
            />
          }
          {DebugInfo}
        </div>
        <div className={classes.contactContainer}>
          <Typography variant="h3" className={classes.contactHeader}>
            Contact your Eaton Representative
          </Typography>
          <Typography className={classes.contactSubheader}>
            Share your results with your Eaton representative.
          </Typography>
          <Typography>
            <a
              href="https://www.eaton.com/us/en-us/locate/roadranger-contacts.html"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Locate your representative.
            </a>
          </Typography>
          <br />
          <Typography className={classes.contactSubheader}>
            The most appropriate transmission will vary with application.&nbsp;
          </Typography>
          <Typography>
            <a
              href="/help#Disclaimer"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Learn more here.
            </a>
          </Typography>
        </div>
      </DefaultPage>
      <PureSurveyFooter
        lhs={footerLhs}
        rhs={rhsButtons}
      />
    </>
  );
};

const PureEditSurveyResult = (
  props: Props & {
    units: Unit;
    specNotes: string;
    setSpecNotes: (notes: string) => void;
    specTitle: string;
    setSpecTitle: (title: string) => void;
    submitError: boolean;
    cancelButton: JSX.Element;
    saveSpec: () => void;
    saving: boolean;
  },
) => {
  const classes = useStyles();

  const [pdfLoading, setPdfLoading] = useState<boolean>(false);
  const [pdfTitle, setPdfTitle] = useState<string>(props.specTitle);
  const [pdfNotes, setPdfNotes] = useState<string>(props.specNotes);
  const debounceNotes = debounce(setPdfNotes, 2000, { trailing: true });
  const debounceTitle = debounce(setPdfTitle, 2000, { trailing: true });

  useEffect(() => {
    if (pdfNotes === props.specNotes && pdfTitle === props.specTitle) {
      setPdfLoading(false);
    } else {
      setPdfLoading(true);
    }
  }, [props.specNotes, props.specTitle, pdfTitle, pdfNotes]);

  return (
    <>
      <div className={classes.saveSpecTopContainer}>
        <div
          className={`${classes.resultTitleContainer} ${classes.saveSpecHeaderContainer}`}
        >
          <Typography variant="h1" className={classes.header}>
            Save Transmission Spec
          </Typography>
          <Typography variant="h3" className={classes.saveSpecSubHeader}>
            {props.topChoice && props.runnerUp
              ? 'Transmission Recommendations'
              : 'Transmission Recommendation'}
          </Typography>
          <Typography className={classes.saveSpecFillerText}>
            Save your results so you can access them later. Add notes to help
            you keep track of your decision making process.
          </Typography>
        </div>
        {props.topChoice && (
          <div className={classes.transmissionResult}>
            <TransmissionResult
              matchType={MatchType.TopMatch}
              transmission={props.topChoice}
            />
          </div>
        )}
        {props.runnerUp && (
          <div className={classes.transmissionResult}>
            <Drawer header="Runner Up" startExpanded={false}>
              <TransmissionResult
                matchType={MatchType.RunnerUp}
                transmission={props.runnerUp}
                hideBorder={true}
              />
            </Drawer>
          </div>
        )}
        <div className={classes.surveyResponses}>
          {
            <SurveyResponses
              survey={props.survey}
              unit={props.units}
              startExpanded={false}
            />
          }
        </div>
      </div>
      <div className={classes.saveSpecBottomContainer}>
        <TextField
          id="spec-title-field"
          data-testid="spec-title-field"
          label="Spec Name"
          className={classes.saveSpecTitleField}
          value={props.specTitle}
          autoFocus={true}
          onFocus={(event) => {
            event.target.select();
          }}
          onChange={(event) => {
            props.setSpecTitle(event.target.value);
            debounceTitle(event.target.value);
          }}
        />
        <RichTextEditor
          data-testid="spec-notes-field"
          className={classes.saveSpecRichTextEditorContainer}
          editorClassName={classes.saveSpecRichTextEditorEditorField}
          value={props.specNotes}
          onChange={(value) => {
            props.setSpecNotes(value);
            debounceNotes(value);
          }}
        />
        {props.submitError && (
          <div className={classes.errorText}>
            <Typography>
              An error occured during the save. Please try again.
            </Typography>
          </div>
        )}
      </div>
      <PureSurveyFooter
        lhs={props.cancelButton}
        rhs={
          <>
            <EatonButton className={classes.pdfButton}>
              <a
                href="https://www.eatoncummins.com/us/en-us/products/transmissions.html"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.transmissionButtonLink}
              >
                View Transmission Portfolio
              </a>
            </EatonButton>
            {!props._inTest && (
              <PDFGenerator
                survey={props.survey}
                topChoice={props.topChoice!}
                runnerUp={props.runnerUp}
                className={classes.pdfButton}
                unit={props.units}
                title={pdfTitle}
                notes={pdfNotes}
                loading={pdfLoading || props.saving}
              />
            )}
            <EatonButton
              onClick={props.saveSpec}
              loadingSpinner={props.saving}
              disabled={props.saving || !props.specTitle.trim()}
              data-testid="save-spec-finish"
              id="save-spec-finish"
            >
              Save
            </EatonButton>
          </>
        }
      />
    </>
  );
};
