/* eslint-disable quote-props */
import * as PXBThemes from '@pxblue/themes/react';
import * as Colors from '@pxblue/colors';

const BaseTheme = PXBThemes.blue;

export const TSPTheme = {
  ...BaseTheme,
  palette: {
    ...BaseTheme.palette,
    primary: {
      main: Colors.blue[500],
      light: Colors.blue[50],
    },
    text: {
      ...BaseTheme.palette?.text,
      primary: Colors.gray[800],
    },
    grey: Colors.gray,
    background: {
      default: Colors.white[50],
      paper: Colors.white[50],
    },
  },
  typography: {
    ...BaseTheme.typography,
    fontWeightBold: 600,
  },
  overrides: {
    ...BaseTheme.overrides,
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: Colors.white[50],
        color: Colors.gray[800],
      },
      colorSecondary: {
        backgroundColor: Colors.gray[800],
        color: Colors.white[50],
      },
    },
    MuiExpansionPanel: {
      root: {
        '&::before': {
          backgroundColor: 'transparent',
        },
      },
      rounded: {
        '&:last-child': {
          borderBottomLeftRadius: '2px',
          borderBottomRightRadius: '2px',
        },
        '&:first-child': {
          borderTopLeftRadius: '2px',
          borderTopRightRadius: '2px',
        },
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        backgroundColor: Colors.white[100],
      },
    },
    MuiInputLabel: {
      root: {
        color: Colors.gray[600],
      },
    },
    MuiInput: {
      root: {
        color: Colors.black[700],
      },
      underline: {
        '&::before': {
          borderBottom: `1px solid ${Colors.gray[200]}`,
        },
        // & is a reference to the class name
        // more &'s increases specificity to override the three not()
        // '&:hover:not($disabled):not($focused):not($error):before':
        '&&&&:hover:before': {
          borderBottom: `2px solid ${Colors.blue[200]}`,
        },
      },
    },
    MuiSlider: {
      rail: {
        color: Colors.blue[50],
        borderRadius: '1px',
        height: '4px',
        opacity: '100%',
      },
      track: {
        color: Colors.blue[600],
        borderRadius: '1px',
        height: '4px',
      },
      thumb: {
        color: Colors.blue[600],
        boxShadow: '-1px 2px 2px rgba(0, 0, 0, 0.2)',
        marginTop: '-9px',
        height: '22px',
        width: '22px',
        marginLeft: '0px',
      },
      valueLabel: {
        'left': '-5px',
        'backgroundColor': 'transparent',
        'top': -22,
        'fontSize': 15,
        '& *': {
          background: 'transparent',
          color: '#000',
        },
      },
      mark: {
        backgroundColor: 'transparent',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#556167',
      },
    },
    MuiLinearProgress: {
      root: {
        height: '23px',
        border: `2px solid ${Colors.blue[100]}`,
        boxSizing: 'border-box',
        boxShadow: '0px 0px 10px rgba(0, 54, 94, 0.08)',
        borderRadius: '2px',
      },
      bar: {},
      colorPrimary: {
        backgroundColor: Colors.blue[50],
      },
      barColorPrimary: {
        background: `linear-gradient(90deg, ${Colors.blue[300]} 0%, ${Colors.blue[500]} 100%)`,
        boxShadow: 'inset 1px -1px 10px rgba(0, 0, 0, 0.1)',
      },
    },
    MuiIconButton: {
      sizeSmall: {
        padding: '1px',
      },
    },
    MuiTypography: {
      h1: {
        color: Colors.black[600],
        fontWeight: 600,
        fontSize: '42px',
      },
      h2: {
        color: Colors.black[600],
        fontWeight: 600,
        fontSize: '32px',
      },
      h3: {
        color: Colors.black[600],
        fontWeight: 600,
        fontSize: '24px',
      },
      h4: {
        color: Colors.black[600],
        fontWeight: 600,
        fontSize: '18px',
      },
      subtitle1: {
        color: Colors.gray[800],
        fontSize: '16px',
        lineHeight: '22px',
      },
      subtitle2: {
        fontWeight: 700,
        letterSpacing: '0.07em',
        fontSize: '10px',
        color: Colors.gray[800],
        textTransform: 'uppercase',
      },
    },
  },
};
