import React, { useState } from 'react';
import { LogTransmission, Transmission } from '../../domain/transmission';
import { PureTransmissionTable } from './pure-transmission-table';
import { EatonSnackBar, SnackBarType } from '../snack-bar';
import { useAuthenticatedRequest } from '../reusable-components/hooks/use-authenticated-request';
import { useAuthDispatch } from '../reusable-components';
import { ErrorPage } from '../../pages/error/Error';
import { EatonAlertDialog } from '../alert-dialog';

export function ListTransmissions() {
  const { authenticatedRequest } = useAuthDispatch();
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showError, setShowError] = useState<boolean>(false);

  const [
    loadingTransmissions,
    transmissions,
    requeryFunction,
  ] = useAuthenticatedRequest<Transmission[]>({
    requestConfig: {
      method: 'GET',
      url: `api/transmission/`,
    },
  });

  const deleteTransmission = async (id: number) => {
    const response = await authenticatedRequest({
      url: `api/transmission/${id}`,
      method: 'DELETE',
    });
    if (response.status < 300) {
      requeryFunction();
    } else {
      setShowError(true);
    }
  };

  const toggleTransmissionStatus = async (id: number) => {
    const response = await authenticatedRequest({
      url: `api/transmission/${id}`,
      method: 'PATCH',
    });

    if (response.status < 300) {
      requeryFunction();
    } else {
      setShowError(true);
    }
  };

  const updateTransmissionRankList = async (transmissionsList: Transmission[]) => {
    const transmissionRanks = transmissionsList.map(t => ({ Id: t.id, Rank: t.rank }));
    const response = await authenticatedRequest({
      url: `api/transmission/UpdateList`,
      method: 'PUT',
      data: transmissionRanks,
    });

    if (response.status < 300) {
      requeryFunction();
    } else {
      console.error('Error updating transmission rank list:', response);
      setShowError(true);
    }
  };

  if (!loadingTransmissions && transmissions === undefined) {
    return <ErrorPage />;
  }
  const getTransmissionLogList = async (id: number): Promise<{ currentTransmissionLog: LogTransmission, previousTransmissionLog: LogTransmission }> => {
    const response = await authenticatedRequest({
      url: `api/transmission/logs/${id}`,
      method: 'GET',
    });

    if (response.ok && Array.isArray(response.body)) {
      const logs: LogTransmission[] = response.body as LogTransmission[]; // Explicitly type logs as Transmission[]
      const currentTransmissionLog = logs[0]; 
      const previousTransmissionLog = logs[1];
      
      return { currentTransmissionLog, previousTransmissionLog };
    } else {
      console.error('Error getting transmission logs:', response);
      setShowError(true);
      throw new Error('Error getting transmission logs');
    }
};
  return (
    <div>
      <EatonAlertDialog
        open={isAlertDialogOpen}
        onClose={() => setIsAlertDialogOpen(false)}
        onAgree={() => {
          setIsAlertDialogOpen(false);
        }}
        alertTitle="Confirmation"
        alertContent={alertMessage}
        agreeButtonText="Continue"
      />
      <EatonSnackBar
        type={SnackBarType.Error}
        message="Unknown error occured while attempting deletion."
        showSnack={showError}
        onClose={() => setShowError(false)}
      />
      <PureTransmissionTable
        transmissions={transmissions || []}
        deleteFunction={deleteTransmission}
        loading={loadingTransmissions}
        toggleTransmissionFunction={toggleTransmissionStatus}
        updateTransmissionRanks={updateTransmissionRankList}
        getTransmissionLog={getTransmissionLogList}
      />
    </div>
  );
}
