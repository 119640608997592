import { makeStyles, Theme } from '@material-ui/core';
import * as Colors from '@pxblue/colors';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    success: {
      backgroundColor: Colors.green[800],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
  };
});
