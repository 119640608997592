import React from 'react';
import { useStyles } from './styles';
import { AccountDetailsState, AccountCreationState } from '.';
import {
  TextField,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import { OrganizationType } from '../../domain/account';
import { EatonButton, EatonLinkButton } from '../button';
import { VocationCheckList } from '../../domain/vocationCheckList';
import { CountryList } from '../../domain/countryList';

export const PureAccountInformationPrompt = (props: {
  state: AccountDetailsState;
  firstName: string;
  setFirstName: (name: string) => void;
  lastName: string;
  setLastName: (name: string) => void;
  email: string;
  organizationName: string;
  setOrganizationName: (name: string) => void;
  organizationType: OrganizationType | '';
  vocationCheckList: VocationCheckList | '';
  countryList: CountryList | '';
  setOrganizationType: (name: OrganizationType) => void;
  setVocationCheckList: (name: VocationCheckList) => void;
  setCountryList: (name: CountryList) => void;
  sendAccountInfo: () => void;
}) => {
  const classes = useStyles();

  return (
    <form
      className={classes.createAccountDetails}
      onSubmit={(e) => {
        e.preventDefault();
        props.sendAccountInfo();
      }}
    >
      <Typography variant="h3">Account Information</Typography>
      <span className={classes.nameFields}>
        <TextField
          className={[
            classes.createAccountField,
            classes.accountinformationField,
            classes.firstNameField,
          ].join(' ')}
          id="firstname"
          data-testid="firstname"
          label="First Name"
          value={props.firstName}
          onChange={(event) => {
            props.setFirstName(event.target.value);
          }}
          inputProps={{
            maxLength: 255,
          }}
        />
        <TextField
          className={[
            classes.createAccountField,
            classes.accountinformationField,
          ].join(' ')}
          id="lastname"
          label="Last Name"
          data-testid="lastname"
          value={props.lastName}
          onChange={(event) => {
            props.setLastName(event.target.value);
          }}
          inputProps={{
            maxLength: 255,
          }}
        />
      </span>
      <Typography
        className={[classes.emailHeading, classes.accountinformationField].join(
          ' ',
        )}
      >
        Email Address
      </Typography>
      <Typography>{props.email}</Typography>
      <FormControl
        className={[
          classes.createAccountField,
          classes.accountinformationField,
        ].join(' ')}
      >
        <InputLabel id="organizationtype">Organization Type</InputLabel>
        <Select
          labelId="organizationtype"
          id="organizationtype"
          data-testid="organization-type"
          onChange={(event) => {
            props.setOrganizationType(event.target.value as OrganizationType);
          }}
          value={props.organizationType}
        >
          {Object.entries(OrganizationType).map((type) => (
            <MenuItem data-testid={type[0]} key={type[0]} value={type[1]}>
              {type[1]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        className={[
          classes.createAccountField,
          classes.accountinformationField,
        ].join(' ')}
        id="organizationname"
        label="Organization Name"
        data-testid="orgname"
        onChange={(event) => {
          props.setOrganizationName(event.target.value);
        }}
        value={props.organizationName}
        inputProps={{
          maxLength: 255,
        }}
      />
      <FormControl
        className={[
          classes.createAccountField,
          classes.accountinformationField,
        ].join(' ')}
      >
        <InputLabel id="vocationCheckList">Select most common application type</InputLabel>
        <Select
        labelId="vocationCheckList"
        id="vocationCheckList"
        data-testid="vocation-CheckList"
        onChange={(event) => {
          props.setVocationCheckList(event.target.value as VocationCheckList);
        }}
        value={props.vocationCheckList}
        >
          {Object.entries(VocationCheckList).map((type) => (
            <MenuItem data-testid={type[0]} key={type[0]} value={type[1]}>
              {type[1]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        className={[
          classes.createAccountField,
          classes.accountinformationField,
        ].join(' ')}
      >
        <InputLabel id="countryList">Select country where you are located</InputLabel>
        <Select
        labelId="countryList"
        id="countryList"
        data-testid="country-List"
        onChange={(event) => {
          props.setCountryList(event.target.value as CountryList);
        }}
        value={props.countryList}
        >
          {Object.entries(CountryList).map((type) => (
            <MenuItem data-testid={type[0]} key={type[0]} value={type[1]}>
              {type[1]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <EatonButton
        type="submit"
        disabled={
          props.state === AccountCreationState.SubmittingAccountDetails ||
          props.firstName.trim() === '' ||
          props.lastName.trim() === '' ||
          props.organizationName.trim() === '' ||
          props.organizationType === '' ||
          props.vocationCheckList === '' ||
          props.countryList === ''
          }
        data-testid="create-account"
        className={classes.submitButton}
        loadingSpinner={
          props.state === AccountCreationState.SubmittingAccountDetails
        }
      >
        Create Account
      </EatonButton>
      <EatonLinkButton
        variant="outlined"
        disabled={props.state === AccountCreationState.SubmittingAccountDetails}
        to="/"
        className={classes.submitButton}
      >
        No, Thanks
      </EatonLinkButton>
      <Typography
        className={[classes.disclaimer, classes.accountinformationField].join(
          ' ',
        )}
      >
        <br />
        By filling out and submitting the form, you agree that the personal data that you provide can be shared with Eaton Cummins Automated Transmission Technologies LLC in the United States of America and with Eaton Cummins Automated Transmission Technologies LLC channel partners. Any use of your personal data will take place in compliance with the relevant and applicable data privacy laws and the Eaton Cummins Automated Transmission Technologies LLC Privacy Policy.
      </Typography>
      {props.state === AccountCreationState.AccountDetailsFailed && (
        <Typography className={classes.errorText}>
          <br />
          Something went wrong. Please try again.
        </Typography>
      )}
    </form>
  );
};
