import React, { useState } from 'react';
import { EatonSnackBar, SnackBarType } from '../snack-bar';
import { LogTransmission, Transmission } from '../../domain/transmission';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  Tooltip,
  IconButton,
  Typography,
  TableFooter,
  Paper,
  CircularProgress,
  Checkbox,
  Select,
  MenuItem,
  Grid,
  TableCell
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { StyledTableDataRow, StyledTableCell, useStyles } from './styles';
import { cssClassName } from '../pure-survey';
import { EatonAlertDialog, EatonAlertDialogForTransmissionLog } from '../alert-dialog';
import { Link } from 'react-router-dom';
import { EatonButton, EatonLinkButton } from '../button';
import { HelpIconActive } from '../../icons/help-icon-active';
import { bool, number } from 'prop-types';
type TransmissionLogResponse = {
  currentTransmissionLog: LogTransmission;
  previousTransmissionLog: LogTransmission;
};
type OwnProps = {
  updateTransmissionRanks: (transmissionsList: Transmission[]) => Promise<void>;
  transmissions: Transmission[];
  deleteFunction: (id: number) => Promise<void>;
  loading: boolean;
  toggleTransmissionFunction: (id: number) => Promise<void>;
  getTransmissionLog: (id: number) => Promise<TransmissionLogResponse>;
};

export function PureTransmissionTable(props: OwnProps) {
  const [selectedRow, setSelectedRow] = useState<number | undefined>(undefined);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openDialogForRank, setOpenDialogForRank] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [transmissionsList, setTransmissions] = useState(props.transmissions);
  const [isDropdownChanged, setDropdownChanged] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [transmissionsLog, setTransmissionsLog] = useState<{ previous: LogTransmission | null, current: LogTransmission | null }>({ previous: null, current: null });
  const [openNewDialog, setOpenNewDialog] = useState<boolean>(false);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [dialogContent, setDialogContent] = useState<JSX.Element | null>(null);
  // Assuming setLoading function updates the dialog content to show/hide the loader
  const updateLoadingState = (isLoading: boolean) => {
    if (isLoading) {
      setDialogContent(
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          {/* Replace "LoaderComponent" with your actual loader component or element */}
          <CircularProgress />
        </div>
      );
    }
  };
  const getEmptyTable = () => {
    return (
      <div style={{ padding: '50px', textAlign: 'center' }}>
        <Typography variant="h6">No Transmissions Found</Typography>
        <EatonLinkButton
          to="/transmission-data/add/"
          className={classes.emptyTableLink}
        >
          <AddIcon className={classes.emptyTableButton} />
          Add New Transmission
        </EatonLinkButton>
      </div>
    );
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>, index: number) => {
    let updatedTransmissions = [...props.transmissions];
    updatedTransmissions[index].rank = event.target.value as number;
    setTransmissions(updatedTransmissions);
    setDropdownChanged(true);
  };


  const setRankofTransmissions = () => {
    const transmissionRanks = transmissionsList.map(t => ({ Id: t.id, Rank: t.rank }));
    const duplicateRanks = transmissionRanks.reduce((acc: { [key: number]: typeof transmissionRanks }, curr) => {
      acc[curr.Rank] = acc[curr.Rank] ? [...acc[curr.Rank], curr] : [curr];
      return acc;
    }, {});

    const duplicates = Object.values(duplicateRanks).filter(rankings => rankings.length > 1);

    if (duplicates.length > 0) {
      const duplicateTransmissions = duplicates.reduce((acc, val) => acc.concat(val), []);
      const duplicateNames = duplicateTransmissions.map(transmission => {
        const original = transmissionsList.find(t => t.id === transmission.Id);
        return original ? original.name : '';
      });
      setAlertMessage(`The following transmissions have the same rank: ${duplicateNames.join(', ')}. Do you want to continue?`);
    } else {
      setAlertMessage(`Are you sure you want to update Transmission Ranks?`);
    }
    setOpenDialogForRank(true);
  };

  const isSelectedRow = (id: number): boolean => id === selectedRow;

  const onAgreeDialog = async () => {
    if (selectedRow) {
      await props.deleteFunction(selectedRow);
      setSelectedRow(undefined);
    }
    setOpenDialog(false);
  };

  const onAgreeSetRankDialog = async () => {
    await props.updateTransmissionRanks(transmissionsList);
    setOpenDialogForRank(false);
    setDropdownChanged(false);
    setOpenSnackbar(true);
  };
  const RenderObject = ({ currentObject, previousObject = {}, isRecursiveCall = false }: { currentObject: any, previousObject: { [key: string]: any }, isRecursiveCall?: boolean }) => {
    const currentTorquesCount = currentObject['modelTorques']?.length || 0;
    const previousTorquesCount = previousObject['modelTorques']?.length || 0;
    const maxTorquesCount = Math.max(currentTorquesCount, previousTorquesCount);
    const modelTorqueHeight = maxTorquesCount * 320.09;
    const currentDescriptionWords = currentObject['description'] ? currentObject['description'].split(/\s+/).length : 0;
    const previousDescriptionWords = previousObject['description'] ? previousObject['description'].split(/\s+/).length : 0;
    const maxDescriptionWords = Math.max(currentDescriptionWords, previousDescriptionWords);
    const rowHeightforDescription = maxDescriptionWords * 3.37;
    const currentUrlCharacters = currentObject['learnMoreUrl'] ? currentObject['learnMoreUrl'].length : 0;
    const previousUrlCharacters = previousObject['learnMoreUrl'] ? previousObject['learnMoreUrl'].length : 0;
    const maxUrlCharacters = Math.max(currentUrlCharacters, previousUrlCharacters);
    const rowHeightForUrl = maxUrlCharacters * 0.653;

    const findModelTorqueByName = (modelTorques: any[], name: string) => {
      return modelTorques.find(torque => torque.name === name);
    };

    return (
      <Table>
        <TableBody>
          {Object.entries(currentObject).map(([key, value], index) => {
            if (key === 'id' || key === 'transmissionJson' || key === "image" || key === "isDisabled") {
              return null;
            }

            const formattedKey = key.replace(/([A-Z])/g, ' $1').trim();
            const capitalizedKey = formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
            const previousValue = previousObject[key];
            let hasChanged = false;

            if (isRecursiveCall && (key === 'name' || key === 'maxTorque')) {
              hasChanged = false;
            } else {
              hasChanged = previousValue !== undefined && previousValue !== value;
            }

            if (typeof value === 'object' && value !== null) {
              if (key === 'modelTorques') {
                return (
                  <React.Fragment key={index}>
                    <TableRow style={{ height: modelTorqueHeight }}>
                      <TableCell style={{ whiteSpace: 'nowrap' }}><b>{capitalizedKey}:</b></TableCell>
                      <TableCell style={{ verticalAlign: 'top' }}>
                        {(value as any[]).map((torque: any, torqueIndex: number) => {
                          const previousTorque = findModelTorqueByName(previousValue, torque.name);
                          const torqueHasChanged = previousTorque !== undefined && JSON.stringify(previousTorque) !== JSON.stringify(torque);
                          return (
                            <TableRow
                              className='modelTorqueRow'
                              key={torqueIndex}
                            >
                              <TableCell style={torqueHasChanged ? { color: 'red' } : {}}>
                                <RenderObject
                                  currentObject={torque}
                                  previousObject={previousTorque || {}}
                                  isRecursiveCall={true}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              } else {
                return (
                  <TableRow key={index}>
                    <TableCell style={{ whiteSpace: 'nowrap' }}><b>{capitalizedKey}:</b></TableCell>
                    <TableCell >{<RenderObject currentObject={value} previousObject={previousValue} />}</TableCell>
                  </TableRow>
                );
              }
            } else {
              if (key === 'description') {
                return (
                  <TableRow key={index} style={{ height: rowHeightforDescription }}>
                    <TableCell style={{ whiteSpace: 'nowrap' }}><b>{capitalizedKey}:</b></TableCell>
                    <TableCell style={{ ...hasChanged ? { color: 'red', whiteSpace: 'normal' } : { whiteSpace: 'normal' }, wordWrap: 'break-word', wordBreak: 'break-all' }}>
                      {typeof value === 'string' || typeof value === 'number' ? value : 'default'}
                    </TableCell>
                  </TableRow>
                );
              } else if (key === 'isDeleted') {
                return (
                  <TableRow key={index}>
                    <TableCell style={{ whiteSpace: 'nowrap' }}><b>{capitalizedKey}:</b></TableCell>
                    <TableCell style={hasChanged ? { color: 'red' } : {}}>
                      {value ? 'Yes' : 'No'}
                    </TableCell>
                  </TableRow>
                );
              } else if (key === 'timeStamp') {
                const formattedValue = (new Date(value as string).toDateString() === 'Mon Jan 01 0001') ? '' : new Date(value as string).toDateString();
                return (
                  <TableRow key={index}>
                    <TableCell style={{ whiteSpace: 'nowrap' }}><b>{capitalizedKey}:</b></TableCell>
                    <TableCell>
                      {formattedValue}
                    </TableCell>
                  </TableRow>
                );
              } else if (key === 'learnMoreUrl') {
                return (
                  <TableRow key={index} style={rowHeightForUrl >= 80 ? { height: rowHeightForUrl } : { height: '80px' }}>
                    <TableCell style={{ whiteSpace: 'nowrap' }}><b>{capitalizedKey}:</b></TableCell>
                    <TableCell style={{ ...hasChanged ? { color: 'red', whiteSpace: 'normal' } : { whiteSpace: 'normal' }, wordWrap: 'break-word', wordBreak: 'break-all' }}>
                      {typeof value === 'string' || typeof value === 'number' ? value : 'default'}
                    </TableCell>
                  </TableRow>
                );
              } else {
                return (
                  <TableRow key={index}>
                    <TableCell style={{ whiteSpace: 'nowrap' }}><b>{capitalizedKey}:</b></TableCell>
                    <TableCell style={hasChanged ? { color: 'red', whiteSpace: 'normal' } : { whiteSpace: 'normal' }}>
                      {typeof value === 'string' ? value.split(' ').join(' ') : (typeof value === 'number' ? value : 'default')}
                    </TableCell>
                  </TableRow>
                );
              }
            }
          })}
        </TableBody>
      </Table>
    );
  };

  const getTransmissionLogs = async (id: number) => {
    if (id) {
      setOpenNewDialog(true); // Open the dialog first
      updateLoadingState(true); // Then start the loader using the updated function
      const log = await props.getTransmissionLog(id);
      if (log.previousTransmissionLog || log.currentTransmissionLog) {
        setDialogContent(
          <Grid container spacing={2} direction="row" justify="space-between" alignItems="stretch" style={{ width: '100%', margin: '0 auto' }}>
            <Grid item xs={12} md={6} style={{ flex: 2, padding: '8px', boxSizing: 'border-box', overflow: 'auto' }}>
              <Grid container direction="column">
                {log.previousTransmissionLog.updatedBy &&
                  <Typography component="div" variant="body1">Updated By: {log.previousTransmissionLog.updatedBy}</Typography>}
                {log.previousTransmissionLog.timeStamp &&
                  <Typography component="div" variant="body1">
                    Timestamp: {new Date(log.previousTransmissionLog.timeStamp).toLocaleString()}
                  </Typography>}
              </Grid>
              <Typography variant="h5" style={{ fontWeight: 'bold' }}>Current Record</Typography>
              {log.currentTransmissionLog.transmissionModel && <RenderObject currentObject={log.currentTransmissionLog.transmissionModel} previousObject={log.previousTransmissionLog.transmissionModel} />}
            </Grid>
            <Grid item xs={12} md={6} style={{ flex: 2, padding: '8px', boxSizing: 'border-box', overflow: 'auto' }}>
              <Grid container direction="column">
                <Typography component="div" variant="body1"> </Typography>
                <Typography component="div" variant="body1"> </Typography>
              </Grid>
              <Typography variant="h5" style={{ fontWeight: 'bold' }}>Previous Record</Typography>
              {log.previousTransmissionLog.transmissionModel && <RenderObject currentObject={log.previousTransmissionLog.transmissionModel} previousObject={log.currentTransmissionLog.transmissionModel} />}
            </Grid>
          </Grid>
        );
      } else {
        setDialogContent(<Typography variant="h6">No Transmission Log Found</Typography>);
      }
      updateLoadingState(false); // Stop the loader after data is populated using the updated function
    }
  };
  return (
    <Paper component="div" square={false} elevation={3} className={classes.paperBody}>
      <EatonAlertDialog
        open={openDialog}
        onClose={() => { setOpenDialog(false); setSelectedRow(undefined); }}
        onAgree={onAgreeDialog}
        agreeButtonText="Continue"
        alertTitle="Confirm Delete"
        alertContent="Are you sure you want to permanently delete this Transmission family?"
      />
      <EatonAlertDialog
        open={openDialogForRank}
        onClose={() => { setOpenDialogForRank(false); setTransmissions([]) }}
        onAgree={() => {
          setOpenDialogForRank(false);
          onAgreeSetRankDialog()
        }}
        agreeButtonText="Continue"
        alertTitle="Confirmation"
        alertContent={alertMessage}
      />
      <EatonAlertDialogForTransmissionLog
        open={openNewDialog}
        onClose={() => setOpenNewDialog(false)}
        alertTitle="Transmission Log History"
        alertContent={
          loading ?
            <CircularProgress /> :
            dialogContent
        }
      />
      <EatonSnackBar
        type={SnackBarType.Success}
        message="Ranks updated successfully"
        showSnack={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
      {props.loading && <CircularProgress className={classes.progressBar} />}
      {!props.loading &&
        props.transmissions &&
        props.transmissions.length < 1 &&
        !props.loading &&
        getEmptyTable()}
      {!props.loading && props.transmissions && props.transmissions.length > 0 && (
        <Table
          style={{ tableLayout: 'auto' }}
          className="table table-striped"
          aria-labelledby="tabelLabel"
          size="medium"
        >
          <TableHead>
            <StyledTableDataRow>
              <StyledTableCell className={classes.tableCellActions}>
                Actions
              </StyledTableCell>
              <StyledTableCell className={classes.tableCellDisableEnable}>
                Disable
              </StyledTableCell>
              <StyledTableCell className={classes.tableCellId}>
                Id
              </StyledTableCell>
              <StyledTableCell className={classes.tableCellName}>
                Name
              </StyledTableCell>
              <StyledTableCell className={classes.tableCellRanking}>
                Ranking
              </StyledTableCell>
              <StyledTableCell className={classes.tableCellRanking}>
                <EatonButton
                  disabled={!isDropdownChanged}
                  onClick={async () => {
                    setRankofTransmissions();
                  }}
                >
                  Set Rank
                </EatonButton>
              </StyledTableCell>
            </StyledTableDataRow>
          </TableHead>
          <TableBody>
            {props.transmissions.map((transmission: Transmission, index) => (
              <StyledTableDataRow
                key={transmission.id}
                className={
                  isSelectedRow(transmission.id) ? classes.tableCellActive : classes.tableCellInactive + ' ' + ((transmission.isDisabled == true) ? classes.tableCellDisabled : classes.tableCellInactive)
                }
                data-testid={`transmission-row-${cssClassName(
                  transmission.name,
                )}`}
              >
                <StyledTableCell style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Tooltip title="Edit">
                    <IconButton
                      component={Link}
                      to={`/transmission-data/edit/${transmission.id}`}
                      data-testid={`${cssClassName(transmission.name)}-edit`}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      onClick={async () => {
                        setSelectedRow(transmission.id);
                        setOpenDialog(true);
                      }}
                      data-testid={`${cssClassName(transmission.name)}-delete`}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Transmission Log">
                    <IconButton

                      onClick={async () => {
                        getTransmissionLogs(transmission.id);
                        // setOpenNewDialog(true);
                      }}
                    >
                      <ListAltIcon /> {/* Replace NewIcon with your actual icon */}
                    </IconButton>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell>
                  <Tooltip title="Selection will disable the Transmission">
                    <Checkbox
                      checked={transmission.isDisabled == true ? true : false}
                      onChange={async () => {
                        await props.toggleTransmissionFunction(transmission.id);
                      }}
                    />
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell>{transmission.id}</StyledTableCell>
                <StyledTableCell className={classes.tableCellName}>
                  {transmission.name}
                </StyledTableCell>
                <StyledTableCell key={index} className={classes.tableCellRanking}>
                  <Select
                    value={transmission.rank}
                    onChange={(event) => handleChange(event, index)}
                  >
                    {props.transmissions.map((item, index) => (
                      <MenuItem key={index} value={index + 1}>
                        {index + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledTableCell>
                <StyledTableCell className={classes.tableCellName}>
                </StyledTableCell>
              </StyledTableDataRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <StyledTableCell
                colSpan={4}
                align="right"
                className={classes.tableCellInactive}
              >
                {`Results: ${props.transmissions.length}`}
              </StyledTableCell>
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </Paper>
  );
}
