import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { chunk } from 'lodash';

export const VehicleApplication = () => {
  const classes = useStyles();

  const Application = (props: {
    title: string;
    image: string;
    details: ApplicationDetails;
  }) => {
    return (
      <div>
        <Typography variant="h3" className={classes.subtitle}>
          {props.title}
        </Typography>
        <img src={props.image} className={classes.image} />
        <Typography variant="h4" className={classes.description}>
          Vocational Description
        </Typography>
        <div>
          <ul>
            {props.details.description.map((descriptionItem, index) => {
              return (
                <li key={`${props.title}-${index}`}>
                  <Typography>
                    {descriptionItem}
                  </Typography>
                </li>
              );
            })}
          </ul>
        </div>
        <Typography variant="h4">
          Typical Vehicle Types
        </Typography>
        <div className={classes.vehicleType}>
          <table className={classes.table}>
            <tbody>
              {chunk(props.details.vehicleTypes, 4).map((typeChunk, index) => {
                return (
                  <tr key={`${props.title}-table-row-${index}`}>
                    {typeChunk.map((type) => {
                      return (
                        <td key={type} className={classes.tableData}>
                          <Typography>
                            {type}
                          </Typography>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Typography variant="h2" id="VehicleApplication" className={classes.subtitle}>
        Vehicle Application
      </Typography>
      <Application
        title="Agriculture"
        image="../../help-images/agriculture.png"
        details={agriculture}
      />
      <Application
        title="Construction"
        image="../../help-images/construction.png"
        details={construction}
      />
      <Application
        title="Fire Service"
        image="../../help-images/fire-service.png"
        details={fireService}
      />
      <Application
        title="Heavy Haul"
        image="../../help-images/heavy-haul.png"
        details={heavyHaul}
      />
      <Application
        title="Linehaul"
        image="../../help-images/linehaul.png"
        details={linehaul}
      />
      <Application
        title="Logging"
        image="../../help-images/logging.png"
        details={logging}
      />
      <Application
        title="Oil Field"
        image="../../help-images/oil-field.png"
        details={oilField}
      />
      <Application
        title="Pick-Up and Delivery"
        image="../../help-images/pick-up-delivery.png"
        details={pickUpDelivery}
      />
      <Application
        title="Recreational"
        image="../../help-images/recreational.png"
        details={recreational}
      />
      <Application
        title="Refuse"
        image="../../help-images/refuse.png"
        details={refuse}
      />
      <Application
        title="Rescue"
        image="../../help-images/rescue.png"
        details={rescue}
      />
      <Application
        title="Transit Coach"
        image="../../help-images/transit-coach.png"
        details={transitCoach}
      />
    </div>
  );
};

type ApplicationDetails = {
  description: string[];
  vehicleTypes: string[];
}

const pickUpDelivery: ApplicationDetails = {
  description: [
    'Pick-Up and Delivery service consists of the movement of freight typically within a 100 mile radius in cities and/or suburban areas.',
    'Operation on road surfaces of concrete, asphalt, and maintained gravel.',
    'Three (3) to thirty (30) miles between starts/stops (typical).',
    'Annual mileage less than 50,000 miles (80,000 Km)',
    '100% load going / up to 40% load return (typical).',
    '40% load going / 100% load return.',
  ],
  vehicleTypes: [
    'Beverage Truck',
    'City Delivery',
    'Flatbed Truck',
    'Grain Hauler (grain hopper / bottom dump trailers)',
    'Moving Van',
    'Municipal Truck',
    'Refrigerated Truck',
    'Stake Truck',
    'Step Van',
    'Tanker Truck',
    'Wrecker',
  ],
};

const linehaul: ApplicationDetails = {
  description: [
    'Linehaul is moving different types of freight in high mileage operation (over 50,000 miles (80,000 km) / year).',
    'Operation on road surfaces of good to excellent concrete or asphalt.',
    '80% of distances are more than 30 miles between starting and stopping with remaining 20% being within 30 miles between starting and stopping.',
    'Typical vehicle configurations are 4x2, 6x2, and 6x4 tractor / trailer combinations and some straight trucks.',
  ],
  vehicleTypes: [
    'Auto Hauler',
    'Bulk Hauler',
    'Doubles',
    'Flatbed Trailer',
    'General Freight',
    'Grain Hauler (grain hopper/bottom dump trailers)',
    'Livestock Hauler',
    'Moving Van',
    'Refrigerated Freight',
    'Tanker',
    'Triples',
  ],
};

const construction: ApplicationDetails = {
  description: [
    `Movement of material to and from a job site.`,
    `Vehicles used in transfer / relocation on a maximum grade of 8%, typically greater than 10 miles per trip.`,
    `90% of loaded operation on prepared road surfaces of concrete, asphalt, gravel, crushed rock or hard packed dirt and up to 10% of loaded operation into sandy or muddy work areas. Vehicles operating less than 90% on prepared road surfaces of concrete, asphalt, gravel, crushed rock or hard packed dirt refer to Specialized Applications “Off-Highway” coverage.    `,
  ],
  vehicleTypes: [
    'Asphalt Truck',
    'Block Truck',
    'Concrete Pumper',
    'Dump Truck',
    'Flatbed Truck',
    'Landscape Truck',
    'Mixer',
    'Roll-off',
    'Semi End Dump',
    'Snowplow',
    'Tank Truck',
    'Utility Truck',
  ],
};

const heavyHaul: ApplicationDetails = {
  description: [
    'Movement of heavy equipment or materials at legal maximums or special permit loadings, typically at GCW in excess of 140,000 lbs. (63,500 kg).',
    'Operation on road surfaces of concrete, asphalt, and maintained gravel.',
    'High horsepower engines and auxiliary gear boxes might be used.',
    'Vehicles may be equipped with two retarders.',
    '100% Loaded going and empty return.',
  ],
  vehicleTypes: [
    'Equipment Hauler',
    'Lowboy',
    'Steel Hauling',
  ],
};

const logging: ApplicationDetails = {
  description: [
    `Movement of logs, chips, and pulp between logging sites and / or mills.`,
    `High horsepower engines and multiple retarders are typically used in this vocation.`,
    `3 to 30 miles between starts and stops (typically).`,
    `90% of loaded operation on road surfaces of concrete, asphalt, maintained gravel, crushed rock or hard packed dirt and up to 10% of loaded operation into sandy or muddy job sites. Vehicles operating less than 90% on prepared road surfaces of concrete, asphalt, gravel, crushed rock or hard packed dirt refer to Specialized Applications “Off-Highway” coverage.`,
    `100% loaded going and empty return.`,
  ],
  vehicleTypes: [
    'Excluding Log Skidders',
    'Log Hauler',
    'Tractor / Trailer',
    'Tractor with Pole Trailer',
  ],
};

const oilField: ApplicationDetails = {
  description: [
    `Movement of production related products, supplies, and tools between job sites.`,
    `Movement of processing equipment and laboratories on job sites.`,
    `Low mileage operation on road surfaces made of concrete, asphalt, maintained gravel, crushed rock, or hard-packed dirt.`,
  ],
  vehicleTypes: [
    'Cementing Vehicle',
    'Demolition',
    'Drill Rig',
    'Fracturing Truck',
    'Geophysical Exploration',
    'Tanker',
    'Winch Truck',
  ],
};

const refuse: ApplicationDetails = {
  description: [
    `Vehicles used for residential refuse / recycle pickup, on an adverse grade up to 20%, typically a high number of stops per mile.`,
    `Or vehicles operated in commercial / industrial pickup, on an adverse grade up to 20%, typically a low number of stops per mile.`,
    `Or vehicles used in transfer / relocation on a maximum grade of 8%, typically greater than 10 miles per trip.`,
    `90% of loaded operation on road surfaces of concrete, asphalt, or maintained gravel and up to 10% of loaded operation into landfill, transfer, or recycling sites. Vehicles operating less than 90% refer to Specialized Applications “Off-Highway” coverage.`,
  ],
  vehicleTypes: [
    'Front / Rear / Side Loader',
    'Residential / Commercial Pickup',
    'Roll-off (For trucks built prior to Nov. 1, 2004)',
    'Scrap Truck',
    'Sewer / Septic / Vacuum',
    'Transfer Vehicle',
  ],
};

const recreational: ApplicationDetails = {
  description: [
    'Vehicles generally used for non-commercial transportation and as traveling domiciles for families.',
    'Loaded full-time.',
    'May pull small passenger car, boat, or pick-up truck.',
    'Typically, vehicle routes exceed 30 miles between starts and stops.',
    'Annual mileage generally less than 30,000 miles.',
    'Typical operation is on paved roads and short distances within campgrounds and parks.',
    'Normally a truck or bus-based chassis.',
  ],
  vehicleTypes: [
    'Motor Homes Class A',
    'Recreational Vehicle',
  ],
};

const transitCoach: ApplicationDetails = {
  description: [
    'Transporting people and sometimes light freight between cities and/or suburban areas.',
    'Transporting people in and around city or suburban areas.',
  ],
  vehicleTypes: [
    'Cross Country Coach',
    'Integral Coach',
    'Tour Bus / Coach',
  ],
};

const agriculture: ApplicationDetails = {
  description: [
    'Vehicles used in the agriculture industry. Use typically involves transportation of agricultural & dairy products to/from/on/around farm sites',
    'Includes operation off of improved surfaces while on the farm.',
  ],
  vehicleTypes: [
    'Feed Trucks',
    'Milk Tankers for Farm Pickup',
    'Semi End Dump',
  ],
};

const fireService: ApplicationDetails = {
  description: [
    'Pumpers, tankers, or aerial ladders used in fighting structural fires.',
    'Low mileage operation.',
  ],
  vehicleTypes: [
    'Aerial Ladder Truck',
    'Aerial Platform',
    'Ambulance',
    'Pumper',
    'Tanker Truck',
  ],
};

const rescue: ApplicationDetails = {
  description: [
    'Specialized vehicles for rapid acceleration to crash sites away from hydrant hookups.',
    'Airport rescue is the most common.',
    'Low mileage operation.',
  ],
  vehicleTypes: [
    'Airport Rescue Fire (ARF)',
    'Crash Fire Rescue (CFR)',
    'Emergency Service',
    'Rapid Intervention Vehicle (RIV)',
  ],
};
