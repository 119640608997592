import { makeStyles, Theme } from '@material-ui/core';
import * as Colors from '@pxblue/colors';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    loading: {
      width: '100%',
      alignContent: 'center',
    },
    recentSpecsContainer: {
      paddingBottom: '64px',
      textAlign: 'center',
    },
    splashItemsContainer: {},
    titleText: {
      display: 'inline',
      marginBottom: '16px',
      textAlign: 'left',
    },
    subtitleText: {
      fontSize: '14px',
      fontWeight: 'normal',
      fontStyle: 'italic',
      color: Colors.black[300],
      paddingLeft: '16px',
    },
  };
});
