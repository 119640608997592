import React, { useContext, createContext, useState } from 'react';
import { Typography, MenuItem, Select } from '@material-ui/core';
import { useStyles } from './styles';
import { Unit } from '../../domain/unit-converstions';
import Cookie from 'js-cookie';

export const PureUnitSwitcher = (props: {
  mode: Unit;
  onChange: (value: Unit) => void;
}) => {
  const classes = useStyles();

  return (
    <div className={classes.unitSwitcher}>
      <Typography className={classes.units}>Unit of Measurement: </Typography>
      <Select
        data-testid="unit-switcher"
        value={props.mode}
        inputProps={{
          'data-testid': 'unit-switcher',
        }}
        onChange={(event) => {
          props.onChange(event.target.value as Unit);
        }}
      >
        {Object.entries(Unit).map((entry) => (
          <MenuItem data-testid={entry[1]} key={entry[1]} value={entry[0]}>
            {entry[1]}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export const UnitContext = createContext({
  units: Unit.Imperial,
  setUnits: (value: Unit) => {},
});

export const UnitProvider = (props: React.PropsWithChildren<{}>) => {
  const existingUnits = Cookie.get('EATON_TSP_UNITS');
  const [units, setUnits] = useState(
    existingUnits === Unit.Metric ? Unit.Metric : Unit.Imperial,
  );
  const setUnitsAndCookie = (value: Unit) => {
    setUnits(value);
    Cookie.set('EATON_TSP_UNITS', value);
  };
  return (
    <UnitContext.Provider value={{ units, setUnits: setUnitsAndCookie }}>
      {props.children}
    </UnitContext.Provider>
  );
};

export const UnitSwitcher = () => {
  const unitContext = useContext(UnitContext);

  return (
    <PureUnitSwitcher
      mode={unitContext.units}
      onChange={unitContext.setUnits}
    />
  );
};
