import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    contentLabel: {
      marginBottom: '23px',
      color: theme.palette.text.primary,
    },
    confirmationDialogContainer: {
      marginTop: '32px',
      maxWidth: '670px',
    },
    confirmationDialogHeader: {
      marginBottom: '16px',
    },
    confirmationDialogBody: {
      marginBottom: '8px',
    },
    confirmationDialogBodyBold: {
      marginBottom: '7px',
      fontWeight: 600,
    },
  };
});
