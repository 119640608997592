export enum VocationCheckList {
  Linehaul = 'Linehaul',
  PickupAndDelivery = 'Pick up & Delivery',
  Distributor = 'Distributor',
  Construction = 'Construction',
  Logging = 'Logging',
  HeavyHaul = 'Heavy Haul',
  Mining = 'Mining',
  OilField = 'Oil Field',
  Refuse = 'Refuse',
  FireAndRescue = 'Fire & Rescue',
}