import React from 'react';
import {
  Typography,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { useAuthenticatedRequest } from '../reusable-components/hooks/use-authenticated-request';
import { useStyles } from './styles';
import { sortBy, reverse } from 'lodash';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { cssClassName } from '../pure-survey';
import { Link } from 'react-router-dom';
import { EatonLinkButton } from '../button';
import { SavedSurveyResult } from '../../domain/saved-result';
import { format, parseISO } from 'date-fns';

type Row = {
  id: string;
  name: string;
  transmission: string;
  createdAtUTC: string;
  lastEditedUTC: string;
};

function descendingComparator<T extends string | Date>(
  a: T,
  b: T,
  date: boolean = false,
) {
  let valueA;
  let valueB;

  if (date) {
    valueA = Date.now() - new Date(a).valueOf();
    valueB = Date.now() - new Date(b).valueOf();
  } else {
    valueA = a;
    valueB = b;
  }

  if (valueB < valueA) {
    return -1;
  }
  if (valueB > valueA) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
  isDate: boolean,
): (a: { [key in Key]: string }, b: { [key in Key]: string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a[orderBy], b[orderBy], isDate)
    : (a, b) => -descendingComparator(a[orderBy], b[orderBy], isDate);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: keyof Row;
  label: string;
}

const headCells: HeadCell[] = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'transmission',
    label: 'Transmission',
  },
  {
    id: 'createdAtUTC',
    label: 'Date Created',
  },
  {
    id: 'lastEditedUTC',
    label: 'Last Edited',
  },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Row,
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Row) => (
    event: React.MouseEvent<unknown>,
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.headerCell}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              IconComponent={ArrowDropUpIcon}
            >
              <Typography variant="subtitle2">{headCell.label}</Typography>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export function EnhancedTable(props: {
  paginate: boolean;
  rows: Row[];
  useUTC?: boolean;
  _test?: boolean;
}) {
  const { rows } = props;
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Row>('lastEditedUTC');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Row,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedRows = stableSort(
    rows,
    getComparator(
      order,
      orderBy,
      orderBy === 'lastEditedUTC' || orderBy === 'createdAtUTC',
    ),
  );
  const showRows = props.paginate
    ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : sortedRows;

  const LinkCell = (
    linkCellProps: React.PropsWithChildren<{ to: string; className: string }>,
  ) => (
    <TableCell
      className={[linkCellProps.className, classes.specCell].join(' ')}
    >
      <Link to={linkCellProps.to} className={classes.specCellLink}>
        <div className={classes.specCellContents}>{linkCellProps.children}</div>
      </Link>
    </TableCell>
  );
  return (
    <div className={classes.root}>
      <TableContainer>
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {showRows.map((row, index) => {
              const rowLink = `/spec/${row.id}`;
              return (
                <TableRow
                  className={classes.row}
                  key={`${cssClassName(row.name)}-${index}`}
                  tabIndex={-1}
                  data-testid={props._test ? undefined : `${row.name}-${index}`}
                >
                  <LinkCell className={classes.nameCell} to={rowLink}>
                    {row.name}
                  </LinkCell>
                  <LinkCell to={rowLink} className={classes.transmissionCell}>
                    {row.transmission}
                  </LinkCell>
                  <LinkCell className={classes.specCell} to={rowLink}>
                    {props.useUTC
                      ? row.createdAtUTC
                      : format(parseISO(row.createdAtUTC), 'MM-dd-yyyy')}
                  </LinkCell>
                  <LinkCell
                    to={rowLink}
                    className={[classes.specCell, classes.editedCell].join(' ')}
                  >
                    <div className={classes.editedCellContainer}>
                      {props.useUTC
                        ? row.lastEditedUTC
                        : format(
                            parseISO(row.lastEditedUTC),
                            'MM-dd-yyyy hh:mm',
                          )}
                      <KeyboardArrowRightIcon
                        color="primary"
                        className={classes.chevron}
                      />
                    </div>
                  </LinkCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {props.paginate && (
        <TablePagination
          backIconButtonProps={{
            color: 'primary',
            className: classes.forwardBackIcon,
          }}
          nextIconButtonProps={{
            color: 'primary',
            className: classes.forwardBackIcon,
          }}
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
}

export const PureSpecTable = (props: {
  specs: SavedSurveyResult[];
  limit?: number;
  actionLabel: string;
  actionLink: string;
  useUTC?: boolean;
  _test?: boolean;
}) => {
  const classes = useStyles();

  if (props.specs.length === 0) {
    return (
      <div className={classes.noSpecs}>
        <Typography>
          Looks like you haven&apos;t created any specs yet.
        </Typography>
        <EatonLinkButton
          variant="outlined"
          className={classes.actionButton}
          to="/survey"
          data-testid="create-first-spec-button"
        >
          Create your first spec
        </EatonLinkButton>
      </div>
    );
  }

  const rows = reverse(
    sortBy(
      props.specs.map((spec) => ({
        id: spec.id.toString(),
        name: spec.title,
        // transmission: JSON.parse(spec.result).topChoice.name,
        transmission: spec.result,
        // Doing the conversion of these dates now makes the sorting simpler.
        createdAtUTC: `${spec.createdAtUTC}Z`,
        lastEditedUTC: `${spec.lastEditedUTC}Z`,
      })),
      (row) => row.lastEditedUTC,
    ),
  );

  return (
    <div className={classes.tableContainer}>
      <EnhancedTable
        useUTC={props.useUTC}
        paginate={props.limit === undefined}
        rows={props.limit ? rows.slice(0, props.limit) : rows}
        _test={props._test}
      />
      <EatonLinkButton
        data-testid="create-new-spec-button"
        className={classes.actionButton}
        to={props.actionLink}
      >
        {props.actionLabel}
      </EatonLinkButton>
    </div>
  );
};

export const SpecTable = (props: {
  actionLabel: string;
  actionLink: string;
  limit?: number;
}) => {
  const [loading, specs] = useAuthenticatedRequest<SavedSurveyResult[]>({
    requestConfig: {
      method: 'GET',
      url: `/api/surveyresult`,
    },
  });

  const classes = useStyles();

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }

  if (!Array.isArray(specs)) {
    return (
      <div className={classes.loading}>
        <Typography>Something went wrong. Please reload.</Typography>
      </div>
    );
  }

  return (
    <PureSpecTable
      actionLabel={props.actionLabel}
      actionLink={props.actionLink}
      limit={props.limit}
      specs={specs}
    />
  );
};
