import React from 'react';
import {
  TransmissionForm,
  TransmissionFormData,
} from '../pure-transmission-form/transmission-form';
import { useAuthDispatch } from '../reusable-components';
import { appendFormData } from '../reusable-components/hooks/use-authenticated-request';

type OwnProps = {};

export function AddTransmissionForm(props: OwnProps) {
  const { authenticatedRequest } = useAuthDispatch();

  const sendFormData = async (
    transmission: Omit<TransmissionFormData, 'id'>,
  ) => {
    const data = new FormData();

    const transmissionData = appendFormData(transmission, data);

    const result = await authenticatedRequest({
      url: 'api/transmission',
      data: transmissionData,
      method: 'POST',
    });

    if (result.status === 201) {
      return 'success';
    } else {
      return 'fail';
    }
  };

  return (
    <>
      <TransmissionForm
        header="Add Transmission"
        onSubmit={sendFormData}
        transmission={undefined}
      />
    </>
  );
}
