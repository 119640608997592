import React, { useState } from 'react';
import { TextField } from '@material-ui/core';

type Props = {
  email: string;
  setEmail: (email: string) => void;
  error?: boolean;
  disable?: boolean;
  otherErrorText?: string | JSX.Element;
  validEmail?: (emailAddress: string | null | undefined) => boolean;
  className?: string;
}

export function validateEmail(email: string) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"\+]+(\.[^<>()\[\]\\.,;:\s@"\+]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const EmailField = (props: Props) => {
  const [showInvalidEmailError, setShowInvalidEmailError] = useState(false);
  const isValidEmail = (props.validEmail || validateEmail)(props.email);

  const existingError = showInvalidEmailError || props.error;

  return (
  <TextField
    id="email"
    data-testid="email"
    className={props.className}
    label="Email Address"
    error={existingError}
    value={props.email}
    inputProps={{ maxLength: 320 }}
    onBlur={() => {
      setShowInvalidEmailError(!isValidEmail);
    }}
    onChange={(evt): void => {
      if (isValidEmail) {
        setShowInvalidEmailError(false);
      }
      props.setEmail(evt.target.value);
    }}
    helperText={
      showInvalidEmailError
        ? 'Please enter a valid email address.'
        : props.error && props.otherErrorText
    }
    disabled={props.disable}
  />);
};
