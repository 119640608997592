import React from 'react';
import { DefaultPage } from '../../components/default-page';
import { AllSpecs } from '../../components/all-specs';

export const MySpecsPage = () => {
  return (
    <DefaultPage background="white">
      <AllSpecs />
    </DefaultPage>
  );
};
