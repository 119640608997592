import React, { ReactElement, useState } from 'react';
import { useStyles } from './styles';
import { Typography, CircularProgress } from '@material-ui/core';
import { DefaultPage } from '../default-page';
import { EatonButton, EatonLinkButton } from '../button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { SurveyPageProps } from './survey-shell';
import { PureSurveyFooter } from '../pure-survey-footer';
import { ProgressBar } from '../progress-bar';
import { UnitSwitcher } from '../unit-switcher';
import { EatonAlertDialog } from '../alert-dialog';
import { useHistory } from 'react-router-dom';

type Props = {
  currentPage: number;
  onNextClicked: () => void;
  onBackClicked: () => void;
  cancelLink: string;
  loading: boolean;
};

export type PropsWithSurveyPageChildren<P> = P & {
  children: Array<ReactElement<SurveyPageProps>>;
};

export const PureSurveyShell = (props: PropsWithSurveyPageChildren<Props>) => {

  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const classes = useStyles();

  const progressCurrent = props.currentPage + 1;

  const currentChild = React.Children.toArray(props.children)[
    props.currentPage
  ];
  const nextButtonEnabled = currentChild.props.isFilled;

  const totalPages = props.children.length;

  const history= useHistory();

  const onAgreeHandler=()=>{
    history.push('/');
  }

  const lhsButtons = (
    <>
      {props.currentPage !== 0 && (
        <EatonButton
          variant="outlined"
          color="secondary"
          data-testid="back-button"
          className={classes.backButton}
          onClick={props.onBackClicked}
          disabled={props.loading}
        >
          <ChevronLeftIcon style={{ marginLeft: '-8px' }} />
          Back
        </EatonButton>
      )}
      
      <EatonAlertDialog
        open={isAlertDialogOpen}
        onClose={() => setIsAlertDialogOpen(false)}
        onAgree={onAgreeHandler}
        alertTitle="Confirmation"
        alertContent="You have not saved your responses are you sure you want to leave this site? If yes then press Continue"
        agreeButtonText="Continue"
      />

      <EatonButton
        variant="outlined"
        color="secondary"
        className={classes.backButton}
        onClick={()=>setIsAlertDialogOpen(true)}
        disabled={props.loading}
      >
        Cancel
      </EatonButton>
    </>
  );

  const rhsButtons = (
    <>
      {props.currentPage === totalPages - 1 ? (
        <EatonButton
          data-testid="result-button"
          disabled={!nextButtonEnabled || props.loading}
          onClick={props.onNextClicked}
        >
          See My Result
          {props.loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </EatonButton>
      ) : (
        <EatonButton
          data-testid="next-button"
          disabled={!nextButtonEnabled}
          onClick={props.onNextClicked}
        >
          Next
          <ChevronRightIcon style={{ marginRight: '-8px' }} />
        </EatonButton>
      )}
    </>
  );

  return (
    <>
      <DefaultPage>
        <div className={classes.header}>
          <Typography variant="h1">Create a New Transmission Spec</Typography>
          <UnitSwitcher />
        </div>
        <ProgressBar currentPage={progressCurrent} totalPages={totalPages} />
        <div
          className={classes.questions}
          data-testid={`page-${props.currentPage}`}
        >
          {currentChild}
        </div>
      </DefaultPage>
      <PureSurveyFooter lhs={lhsButtons} rhs={rhsButtons} />
    </>
  );
};
