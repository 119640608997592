import React from 'react';
import { DefaultPage } from '../../components/default-page';
import { ResetPassword } from '../../components/reset-password';

export const ResetPasswordPage = () => {
  return (
    <DefaultPage>
      <ResetPassword />
    </DefaultPage>
  );
};
