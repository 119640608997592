import React from 'react';
import { Transmission } from '../../domain/transmission';
import {
  TransmissionForm,
  TransmissionFormData,
} from '../pure-transmission-form/transmission-form';
import { useAuthDispatch } from '../reusable-components';
import { appendFormData } from '../reusable-components/hooks/use-authenticated-request';

type OwnProps = {
  transmission: Transmission;
};

export function EditTransmissionForm(props: OwnProps) {
  const { authenticatedRequest } = useAuthDispatch();
  const sendFormData = async (
    transmission: Omit<TransmissionFormData, 'id'>,
  ) => {
    const data = new FormData();

    const transmissionData = appendFormData(transmission, data);

    const result = await authenticatedRequest({
      url: `api/transmission/${props.transmission.id}`,
      method: 'PUT',
      data: transmissionData,
    });
    if (result.status === 204) {
      return 'success';
    } else {
      return 'fail';
    }
  };

  return (
    <TransmissionForm
      header="Edit Transmission"
      onSubmit={sendFormData}
      transmission={props.transmission}
    />
  );
}
