import { makeStyles, Theme } from '@material-ui/core';
import * as Colors from '@pxblue/colors';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    passwordFieldContainer: {
      display: 'flex',
      flexFlow: 'column',
    },
    passwordField: {
      marginTop: '32px',
      width: '370px',
    },
    passwordRegisterError: {
      color: theme.palette.error.main,
      fontSize: '12px',
      marginTop: '4px',
    },
    passwordVerification: {
      marginTop: '8px',
      width: '370px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    passwordVerificationColumn: {
      display: 'inline-block',
    },
    passwordVerificationRow: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '8px',
    },
    passwordVerificationText: {
      fontSize: '14px',
      paddingLeft: '8px',
    },
    unmetPasswordRequirementText: {
      color: Colors.black[300],
    },
  };
});
