import React, { useState, useEffect } from 'react';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
} from '@material-ui/core';
import { ExpandIcon } from '../../icons/expand-icon';
import { useStyles } from './styles';
import { MinimizeIcon } from '../../icons/minimize-icon';
import {
  Survey,
  ManeuverToLabelMappings,
  EngineMakeToLabelMappings,
  Maneuver,
} from '../../domain/survey';
import { isArray, startCase, findKey, kebabCase } from 'lodash';
import {
  TerrainToLabelMappings,
  ImageQuestionMapping,
  RoadTypeToLabelMappings,
} from '../../domain/image-mappings';
import { ImageQuestionDetails } from '../pure-survey/image-question';
import { UnitSwitcher } from '../unit-switcher';
import {
  ImperialUnit,
  UnitType,
  Unit,
  UnitMapping,
} from '../../domain/unit-converstions';

export const Drawer = (
  drawerProps: React.PropsWithChildren<{ header: string; startExpanded: boolean }>,
) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<boolean>(drawerProps.startExpanded);
  const onChange = () => setExpanded(!expanded);
  useEffect(() => {
    setExpanded(drawerProps.startExpanded);
  }, [drawerProps.startExpanded]);

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={onChange}
      className={classes.panelContainer}
      data-testid={`${kebabCase(drawerProps.header)}-drawer`}
    >
      <ExpansionPanelSummary className={classes.panelSummary}>
        {expanded ? (
          <MinimizeIcon className={classes.icon} />
        ) : (
          <ExpandIcon className={classes.icon} />
        )}
        <Typography variant="h4" className={classes.responseHeader}>
          {drawerProps.header}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ display: 'flex', flexFlow: 'column' }}>
        {drawerProps.children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

type StandardEnum = {
  [id: string]: string;
};

type Props = {
  survey: Survey;
  unit: Unit;
  startExpanded: boolean;
};

export const SurveyResponses = (props: Props) => {
  const classes = useStyles();

  function SimpleSurveyPart(simplePartProps: { title: string; body: string }) {
    return (
      <div className={classes.contentColumnItem}>
        <Typography className={classes.surveyTitle}>
          {simplePartProps.title}
        </Typography>

        <Typography className={classes.surveyBody}>
          {simplePartProps.body}
        </Typography>
      </div>
    );
  }

  function LabelMappedSurveyPart<T extends string>(simplePartProps: {
    title: string;
    body: T[] | T;
    labelMapping: { [key in T]: string } | ImageQuestionMapping<T>;
    subtitleMapping?: boolean;
  }) {
    const Item = (itemProps: { body: T }) => {
      const rawLabel: ImageQuestionDetails | string =
        simplePartProps.labelMapping[itemProps.body];
      const label = typeof rawLabel === 'string'
        ? rawLabel
        : simplePartProps.subtitleMapping
          ? `${rawLabel.title} ${rawLabel.subtitle || ''}`
          : rawLabel.title;
      return <Typography className={classes.surveyBody}>{label}</Typography>;
    };

    return (
      <div className={classes.contentColumnItem}>
        <Typography className={classes.surveyTitle}>
          {simplePartProps.title}
        </Typography>
        {isArray(simplePartProps.body) ? (
          simplePartProps.body.map((lineItem, index) => (
            <Item key={`${lineItem}-simple-${index}`} body={lineItem} />
          ))
        ) : (
          <Item body={simplePartProps.body} />
        )}
      </div>
    );
  }

  const MultiSurveyPart = (multiPartProps: {
    title: string;
    parts: Array<{ subTitle: string; content: string | number }>;
    unit: Unit;
  }) => (
    <div className={classes.contentColumnItem}>
      <Typography className={classes.surveyTitle}>
        {multiPartProps.title}
      </Typography>
      {multiPartProps.parts.map((lineItem, index) => (
        <div key={`${lineItem}-multi-${index}`}>
          <div className={classes.inlineText}>
            <Typography
              variant="subtitle2"
              className={classes.multiPartSubtitle}
            >
              {lineItem.subTitle}:&nbsp;
            </Typography>
            <Typography>{lineItem.content}</Typography>
          </div>
          <br />
        </div>
      ))}
    </div>
  );

  const convertAndPrint = (unitType: UnitType, value: ImperialUnit) => {
    return props.unit === Unit.Imperial
      ? `${value.toLocaleString()} ${UnitMapping[unitType].imperial}`
      : `${UnitMapping[unitType].toMetric(value).toLocaleString()} ${
          UnitMapping[unitType].metric
        }`;
  };

  return (
    <Drawer header="Your Responses" startExpanded={props.startExpanded}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '16px', marginTop: '6px' }}>
        <UnitSwitcher />
      </div>
      <div className={classes.panelContent}>
        <div className={classes.contentColumn}>
          <SimpleSurveyPart
            title="Application"
            body={startCase(props.survey.application)}
          />
          <SimpleSurveyPart
            title="Max GCW"
            body={convertAndPrint('weight', props.survey.maxWeight)}
          />
          <LabelMappedSurveyPart
            title="Terrain"
            body={props.survey.terrain}
            labelMapping={TerrainToLabelMappings}
            subtitleMapping
          />
        </div>
        <div className={classes.contentColumn}>
          <LabelMappedSurveyPart<Maneuver | 'NoManeuvers'>
            title="Maneuvers"
            body={
              props.survey.maneuvers.length > 0
                ? props.survey.maneuvers
                : 'NoManeuvers'
            }
            labelMapping={ManeuverToLabelMappings}
          />
          <LabelMappedSurveyPart
            title="Road Type"
            body={props.survey.roadTypes}
            labelMapping={RoadTypeToLabelMappings}
          />
          <SimpleSurveyPart title="PTOs" body={startCase(props.survey.pto)} />
        </div>
        <div className={classes.contentColumn}>
          <MultiSurveyPart
            title="Engine Rating"
            unit={props.unit}
            parts={[
              {
                subTitle: 'Horsepower',
                content: convertAndPrint('power', props.survey.engineHp),
              },
              {
                subTitle: 'Torque',
                content: convertAndPrint('torque', props.survey.engineTorque),
              },
              {
                subTitle: 'Engine Make',
                content: EngineMakeToLabelMappings[props.survey.engineMake],
              },
            ]}
          />
          <MultiSurveyPart
            title="Vehicle Speed"
            unit={props.unit}
            parts={[
              {
                subTitle: 'Average Cruise',
                content: convertAndPrint('speed', props.survey.cruiseSpeed),
              },
              {
                subTitle: 'Max',
                content: convertAndPrint('speed', props.survey.maxSpeed),
              },
            ]}
          />
        </div>
        <div className={classes.contentColumn}>
          <MultiSurveyPart
            title="Performance Goal"
            unit={props.unit}
            parts={[
              {
                subTitle: '1',
                content: startCase(
                  findKey(props.survey.preferenceRanking, (n) => n === 1) || '',
                ),
              },
              {
                subTitle: '2',
                content: startCase(
                  findKey(props.survey.preferenceRanking, (n) => n === 2) || '',
                ),
              },
              {
                subTitle: '3',
                content: startCase(
                  findKey(props.survey.preferenceRanking, (n) => n === 3) || '',
                ),
              },
              {
                subTitle: '4',
                content: startCase(
                  findKey(props.survey.preferenceRanking, (n) => n === 4) || '',
                ),
              },
              {
                subTitle: '5',
                content: startCase(
                  findKey(props.survey.preferenceRanking, (n) => n === 5) || '',
                ),
              },
            ]}
          />
        </div>
      </div>
    </Drawer>
  );
};
