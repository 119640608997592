import React from 'react';
import { PureAccountCreatedView } from '../../components/create-account/pure-account-created-view';

export const AccountCreatedPage = (props: {}) => {
  return <PureAccountCreatedView existingUser={false} />;
};

export const AccountUpdatedPage = (props: {}) => {
  return <PureAccountCreatedView existingUser={true} />;
};
