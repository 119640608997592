import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    helpTitle: {
      marginTop: '52px',
      marginBottom: '48px',
    },
    helpContainer: {
      display: 'flex',
    },
    topicContainer: {
      backgroundColor: theme.palette.primary.light,
      paddingLeft: '15px',
      paddingRight: '24px',
      paddingTop: '16px',
      paddingBottom: '16px',
      width: 'fit-content',
      height: 'fit-content',
      marginRight: '30px',
    },
    topicTitle: {
      marginBottom: '16px',
    },
    topicLinks: {
      display: 'flex',
      flexDirection: 'column',
    },
    topicLink: {
      marginBottom: '16px',
    },
    helpInfo: {
      width: 'min-content',
    },
    dividerLine: {
      borderTop: '1px solid #D5D8DA',
      width: '870px',
    },
    basicTextTitle: {
      marginTop: '32px',
      marginBottom: '32px',
    },
    basicTextTitleNoTopMargin: {
      marginBottom: '32px',
    },
    basicTextBody: {
      marginBottom: '32px',
    },
  };
});
