import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    terrainOptionContainer: {
      display: 'inline-flex',
      flexWrap: 'wrap',
    },
    contentLabel: {
      marginBottom: '23px',
      color: theme.palette.text.primary,
    },
    confirmationDialogContainer: {
      marginBottom: '32px',
      maxWidth: '670px',
      display: 'flex',
      flexFlow: 'column',
    },
    confirmationDialogHeader: {
      marginBottom: '16px',
    },
    mountainDialogHeader: {
      marginBottom: '12px',
    },
    confirmationDialogBody: {
      marginBottom: '8px',
    },
    confirmationDialogBodyBold: {
      fontWeight: theme.typography.fontWeightBold,
    },
    mountainOption: {
      alignItems: 'start',
      marginBottom: '8px',
    },
    mountainLabel: {
      paddingTop: '8px',
    },
    subtitle: {
      marginBottom: '8px',
    },
  };
});
