import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    radioGroupColumns: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
    },
    radioGroupSingle: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
    },
    column: {
      display: 'inline',
      width: '270px',
      marginRight: '30px',
    },
  };
});
