import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { PureEmailPrompt } from './pure-email-prompt';
import { PureVerificationCodePrompt } from './pure-verification-code-prompt';
import { PureAccountInformationPrompt } from './pure-account-information-prompt';
import { OrganizationType } from '../../domain/account';
import { EatonLinkButton } from '../button';
import { useLogin, useAuthDispatch } from '../reusable-components';
import { useHistory } from 'react-router-dom';
import { validateEmail } from '../email-field';
import { VocationCheckList } from '../../domain/vocationCheckList';
import { CountryList } from '../../domain/countryList';

export const PureCreateAccountHeader = () => {
  const classes = useStyles();
  return (
    <div className={classes.createAccountHeaderContainer}>
      <div className={classes.createAccountHeader}>
        <div className={classes.createAccountDescription}>
          <Typography className={classes.createAccountHeading} variant='h1'>
            Create Account
          </Typography>
          <Typography className={classes.createAccountSubheading} variant='h3'>
            Save your specs. Reference them later.
          </Typography>
          <Typography>
            Creating an account will allow you to save and download your
            results. You can access them at any point and even add notes to
            them.
          </Typography>
        </div>
        <div className={classes.createAccountSignIn}>
          <Typography variant='h3' style={{ marginBottom: '24px' }}>
            Already have an account?
          </Typography>
          <EatonLinkButton to='/login'>Sign In</EatonLinkButton>
        </div>
      </div>
    </div>
  );
};

export type EmailStates =
  | AccountCreationState.AwaitingEmailEntry
  | AccountCreationState.SendingEmailVerification
  | AccountCreationState.EmailExists
  | AccountCreationState.EmailFailed;

export type VerificationStates =
  | AccountCreationState.AwaitingVerificationAndPasswordEntry
  | AccountCreationState.ResendingCode
  | AccountCreationState.SubmittingVerificationAndPassword
  | AccountCreationState.InvalidVerificationCode
  | AccountCreationState.AccountAlreadyVerified
  | AccountCreationState.VerificationOrPasswordFailed;

export type AccountDetailsState =
  | AccountCreationState.AwaitingAccountDetails
  | AccountCreationState.SubmittingAccountDetails
  | AccountCreationState.AccountDetailsFailed;

export enum OtherStates {
  NotImplemented,
}

export enum AccountCreationState {
  // Email
  AwaitingEmailEntry,
  SendingEmailVerification,
  EmailExists,
  EmailFailed,

  // Email verification for a new user
  AwaitingVerificationAndPasswordEntry,
  ResendingCode,
  SubmittingVerificationAndPassword,
  InvalidVerificationCode,
  AccountAlreadyVerified,
  VerificationOrPasswordFailed,

  // Account details
  AwaitingAccountDetails,
  SubmittingAccountDetails,
  AccountDetailsFailed,

  // Other
  NotImplemented,
}

export const CreateAccount = () => {
  const [componentState, setStateAndExisting] = useState<{
    state: AccountCreationState;
    existingUser: boolean;
  }>({ state: AccountCreationState.AwaitingEmailEntry, existingUser: false });
  const setState = (newState: AccountCreationState) => {
    setStateAndExisting({
      state: newState,
      existingUser: componentState.existingUser,
    });
  };
  const router = useHistory();

  const { authenticatedRequest } = useAuthDispatch();
  
  // E-mail page
  const [email, setEmail] = useState<string>('');
  const sendEmailVerification = () => {
    if (validateEmail(email)) {
      if (
        componentState.state ===
        AccountCreationState.AwaitingVerificationAndPasswordEntry
      ) {
        setState(AccountCreationState.ResendingCode);
      } else {
        setState(AccountCreationState.SendingEmailVerification);
      }
      const submit = async () => {
        const result = await authenticatedRequest({
          method: 'POST',
          url: `/api/registration/register/${email}`,
        });
        if (result.status === 201) {
          setState(AccountCreationState.AwaitingVerificationAndPasswordEntry);
        } else if (result.status === 200) {
          setStateAndExisting({
            state: AccountCreationState.AwaitingVerificationAndPasswordEntry,
            existingUser: true,
          });
        } else if (result.status === 409) {
          setState(AccountCreationState.EmailExists);
        } else {
          setState(AccountCreationState.EmailFailed);
        }
      };
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      submit();
    }
  };

  // Verification Code and Password page
  const [password, setPassword] = useState<string>('');
  const [verificationCode, setVerificationCode] = useState<string>('');
  const sendVerificationCodeAndPassword = () => {
    setState(AccountCreationState.SubmittingVerificationAndPassword);

    if (componentState.existingUser) {
      const data = {
        email,
        verificationCode,
      };
      const submit = async () => {
        const result = await authenticatedRequest({
          url: `/api/registration/verifyexisting`,
          data,
          method: 'POST',
        });
        if (result.status === 200) {
          router.push('/updated');
        } else if (
          result.status === 400 &&
          JSON.stringify(result.body).match(/verification code/i)
        ) {
          // Bad verification code.
          setState(AccountCreationState.InvalidVerificationCode);
          return;
        } else if (result.status === 405) {
          setState(AccountCreationState.AccountAlreadyVerified);
          return;
        }
        // Something else went wrong
        setState(AccountCreationState.VerificationOrPasswordFailed);
      };
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      submit();
    } else {
      const data = {
        email,
        verificationCode,
        password,
      };
      const submit = async () => {
        const result = await authenticatedRequest({
          method: 'POST',
          url: `/api/registration/verifynew`,
          data,
        });
        if (result.status === 201) {
          try {
            const response = await authenticatedRequest({
              method: 'POST',
              url: '/api/security/token',
              data: {
                user: email,
                password,
              },
            });
            if (response.status !== 200) {
              setState(AccountCreationState.VerificationOrPasswordFailed);
            } else {
              setState(AccountCreationState.AwaitingAccountDetails);
            }
          } catch (err) {
            setState(AccountCreationState.VerificationOrPasswordFailed);
          }
          return;
        } else if (
          result.status === 400 &&
          JSON.stringify(result.body).match(/verification code/i)
        ) {
          // Bad verification code.
          setState(AccountCreationState.InvalidVerificationCode);
          return;
        } else if (result.status === 405) {
          setState(AccountCreationState.AccountAlreadyVerified);
          return;
        }
        // Something else went wrong
        setState(AccountCreationState.VerificationOrPasswordFailed);
      };
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      submit();
    }
  };

  // Account Information
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [organizationName, setOrganizationName] = useState<string>('');
  const [organizationType, setOrganizationType] = useState<
    OrganizationType | ''
  >('');
  const [vocationCheckList, setVocationCheckList] = useState<
    VocationCheckList | ''
  >('');
  const [countryList, setCountryList] = useState<
  CountryList | ''
>('');
  //const login = useLogin();
  const sendAccountInfo = () => {
    setState(AccountCreationState.SubmittingAccountDetails);
    const data = {
      email: email.trim(),
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      organizationName: organizationName.trim(),
      organizationType,
      vocationCheckList,
      countryList,
    };
    const submit = async () => {
      const result = await authenticatedRequest({
        method: 'POST',
        url: `/api/registration/accountdetails`,
        data,
      });
      if (result.status === 200) {
        //await login({ email, password });
    
        router.push('/created');
      } else {
        // Something went wrong
        setState(AccountCreationState.AccountDetailsFailed);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    submit();
  };

  const getCurrentForm = () => {
    switch (componentState.state) {
      case AccountCreationState.AwaitingEmailEntry:
      case AccountCreationState.SendingEmailVerification:
      case AccountCreationState.EmailExists:
      case AccountCreationState.EmailFailed:
        return (
          <PureEmailPrompt
            email={email}
            setEmail={setEmail}
            onSendVerification={sendEmailVerification}
            emailSubmissionState={componentState.state}
          />
        );
      case AccountCreationState.AwaitingVerificationAndPasswordEntry:
      case AccountCreationState.ResendingCode:
      case AccountCreationState.SubmittingVerificationAndPassword:
      case AccountCreationState.InvalidVerificationCode:
      case AccountCreationState.AccountAlreadyVerified:
      case AccountCreationState.VerificationOrPasswordFailed:
        return (
          <PureVerificationCodePrompt
            state={componentState.state}
            setCode={setVerificationCode}
            code={verificationCode}
            verifyCode={sendVerificationCodeAndPassword}
            resendEmail={sendEmailVerification}
            existingUser={componentState.existingUser}
            password={password}
            setPassword={setPassword}
            back={() => {
              setState(AccountCreationState.AwaitingEmailEntry);
            }}
          />
        );
      case AccountCreationState.AwaitingAccountDetails:
      case AccountCreationState.SubmittingAccountDetails:
      case AccountCreationState.AccountDetailsFailed:
        return (
          <PureAccountInformationPrompt
            state={componentState.state}
            {...{
              email,
              firstName,
              lastName,
              organizationName,
              organizationType,
              vocationCheckList,
              countryList,
              setFirstName,
              setLastName,
              setOrganizationName,
              setOrganizationType,
              setVocationCheckList,
              setCountryList,
              sendAccountInfo,
            }}
          />
        );
    }
  };

  const classes = useStyles();

  return (
    <>
      <PureCreateAccountHeader />
      <div className={classes.anotherContainer}>
        <div className={classes.createAccountDetailsContainer}>
          {getCurrentForm()}
        </div>
      </div>
    </>
  );
};
