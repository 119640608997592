import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    pdfButton: {
      marginRight: '16px',
    },
    transmissionResult: {
      marginBottom: '32px',
    },
    surveyResponses: {
      marginBottom: '64px',
    },
    saveSpecTitleField: {
      marginBottom: '48px',
      marginTop: '48px',
      width: '100%',
    },
    inputText: {
      fontSize: '42px',
    },
    saveSpecRichTextEditorContainer: {
      width: '769px',
      paddingBottom: '32px',
    },
    saveSpecRichTextEditorEditorField: {
      height: '176px',
    },
    errorText: {
      color: theme.palette.error.main,
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    saveSpecSubHeader: {
      marginBottom: '32px',
    },
    transmissionButtonLink: {
      color: theme.palette.common.white,
      textDecoration: 'none',
    },
  };
});
