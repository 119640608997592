import { makeStyles, Theme } from '@material-ui/core';
import { Gutters } from '../../../styles/gutters';

export const FooterHeight = '48px';

export const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    minHeight: FooterHeight,
    backgroundColor: theme.palette.grey[800],
    bottom: 0,
    position: 'absolute',
    width: '100%',
  },
  footerToolbar: {
    ...Gutters(theme),
    minHeight: FooterHeight,
    paddingTop: '8px',
    paddingBottom: '4px',
    minWidth: '960px',
    maxWidth: '1500px',
    marginRight: 'auto',
    marginLeft: 'auto',
    color: theme.palette.getContrastText(theme.palette.grey[800]),
  },
  footerToolbarItem: {
    display: 'flex',
    width: 'auto',
    alignItems: 'center',
  },
  footerText: {
    fontSize: '12px',
    display: 'flex',
  },
  footerLinkList: {
    [theme.breakpoints.down('sm')]: {
      paddingRight: '20px',
      paddingLeft: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: '10px',
      paddingLeft: '10px',
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: '45px',
      paddingLeft: '45px',
    },
    display: 'flex',
  },
  footerLink: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  footerLinkText: {
    fontSize: '12px',
    color: theme.palette.getContrastText(theme.palette.grey[800]),
    textDecoration: 'underline',
    paddingBottom: '4px',
  },
  contactUs: {
    display: 'inline-block',
  },
  helpIcon: {
    paddingRight: '4px',
    overflow: 'visible',
    width: '22px',
    height: '22px',
  },
}));
