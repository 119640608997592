import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    panelContainer: {
      boxShadow: 'none',
      border: `1px solid ${theme.palette.grey[100]}`,
      boxSizing: 'border-box',
    },
    panelSummary: {
      height: '57px',
      paddingLeft: '15px',
      boxShadow: `0px 1px 0px ${theme.palette.grey[100]}`,
    },
    icon: {
      marginRight: '8px',
      width: '28px',
      height: '28px',
    },
    responseHeader: {
      alignItems: 'center',
      display: 'inline-flex',
    },
    panelContent: {
      paddingTop: '16px',
      paddingBottom: '0px',
      paddingRight: '16px',
      paddingLeft: '52px',
      display: 'flex',
      flexFlow: 'row',
    },
    contentColumn: {
      display: 'flex',
      flexDirection: 'column',
      paddingRight: '32px',
      width: '100%',
      minWidth: 'fit-content',
    },
    contentColumnItem: {
      marginBottom: '32px',
    },
    surveyTitle: {
      fontWeight: 600,
      marginBottom: '8px',
    },
    surveyBody: {
      marginBottom: '8px',
    },
    inlineText: {
      display: 'inline-flex',
      alignItems: 'baseline',
      marginBottom: '8px',
    },
    multiPartSubtitle: {
      marginBottom: '6px',
    },
  };
});
