export enum CountryList {
  Afghanistan = "Afghanistan",
  Albania = "Albania",
  Algeria = "Algeria",
  AllEurope = "All Europe",
  Andorra = "Andorra",
  Angola = "Angola",
  AntiguaAndBarbuda = "Antigua and Barbuda",
  Argentina = "Argentina", 
  Armenia = "Armenia",
  Aruba = "Aruba",
  Australia = "Australia",
  Austria = "Austria",
  Azerbaijan = "Azerbaijan",
  Bahamas = "Bahamas",
  Bahrain = "Bahrain",
  Bangladesh = "Bangladesh",
  Barbados = "Barbados",
  Belarus = "Belarus",
  Belgium = "Belgium", 
  Belize = "Belize",
  Benin = "Benin",
  Bhutan = "Bhutan",
  Bolivia = "Bolivia",
  BosniaAndHerzegovina = "Bosnia and Herzegovina",
  Botswana = "Botswana", 
  Brazil = "Brazil",
  BritishVirginIslands = "British Virgin Islands",
  Brunei = "Brunei",
  Bulgaria = "Bulgaria",
  BurkinaFaso = "Burkina Faso",
  BurmaMyanmar = "Burma (Myanmar)",
  Burundi = "Burundi",
  Cambodia = "Cambodia",
  Cameroon = "Cameroon",
  Canada = "Canada",
  CapeVerde = "Cape Verde",
  CaymanIslands = "Cayman Islands",
  CentralAfricanRepublic = "Central African Republic",
  Chad = "Chad",
  Chile = "Chile",
  China = "China",
  Colombia = "Colombia",
  Comoros = "Comoros",
  Congo = "Congo",
  CongoDemocraticRepublicofthe ="Congo, Democratic Republic of the",
  CostaRica = "Costa Rica",
  Croatia = "Croatia",
  CtedIvoire = "C�te d'Ivoire",
  Cuba = "Cuba",
  Curacao = "Curacao",
  Cyprus = "Cyprus",
  CzechRepublic = "Czech Republic",
  Denmark = "Denmark",
  Djibouti = "Djibouti", 
  Dominica = "Dominica",
  DominicanRepublic = "Dominican Republic",
  EastTimor = "East Timor",
  Ecuador = "Ecuador",
  Egypt = "Egypt",
  ElSalvador = "El Salvador",
  EnglandUK = "England (U.K.)",
  EquatorialGuinea = "Equatorial Guinea",
  Eritrea = "Eritrea",
  Estonia = "Estonia",
  Ethiopia = "Ethiopia", 
  Europe = "Europe",
  Fiji = "Fiji",
  Finland = "Finland",
  France = "France",
  Gabon = "Gabon",
  Gambia = "Gambia",
  Georgia = "Georgia",
  Germany = "Germany",
  Ghana = "Ghana",
  Greece = "Greece",
  Grenada = "Grenada",
  Guam = "Guam",
  Guatemala = "Guatemala",
  Guinea = "Guinea",
  GuineaBissau = "Guinea-Bissau",
  Guyana = "Guyana",
  Haiti = "Haiti",
  Holland = "Holland",
  Honduras = "Honduras",
  Hungary = "Hungary",
  Iceland = "Iceland",
  India = "India",
  Indonesia = "Indonesia",
  Iran = "Iran",
  Iraq = "Iraq",
  Ireland = "Ireland",
  Israel = "Israel",
  Italy = "Italy",
  IvoryCoast = "Ivory Coast",
  Jamaica = "Jamaica",
  Japan = "Japan",
  Jordan = "Jordan",
  Kazakhstan = "Kazakhstan",
  Kenya = "Kenya", 
  Kiribati = "Kiribati",
  KoreaNorth = "Korea, North",
  KoreaSouth = "Korea, South", 
  Kuwait = "Kuwait",
  Kyrgyzstan = "Kyrgyzstan",
  Laos = "Laos",
  Latvia = "Latvia",
  Lebanon = "Lebanon",
  Lesotho = "Lesotho",
  Liberia = "Liberia",
  Libya = "Libya",
  Liechtenstein = "Liechtenstein",
  Lithuania = "Lithuania",
  Luxembourg = "Luxembourg",
  Macedonia = "Macedonia",
  Madagascar = "Madagascar",
  Malawi = "Malawi",
  Malaysia = "Malaysia",
  Maldives = "Maldives",
  Mali = "Mali",
  Malta = "Malta",
  MarshallIslands = "Marshall Islands",
  Mauritania = "Mauritania",
  Mauritius = "Mauritius",
  Mexico = "Mexico",
  Micronesia = "Micronesia",
  Moldova = "Moldova",
  Monaco = "Monaco",
  Mongolia = "Mongolia",
  Morocco = "Morocco",
  Mozambique = "Mozambique",
  Myanmar = "Myanmar",
  Namibia = "Namibia",
  Nauru = "Nauru",
  Nepal = "Nepal",
  NewZealand = "New Zealand",
  Nicaragua = "Nicaragua", 
  Niger = "Niger",
  Nigeria = "Nigeria",
  NONUNITEDSTATESTERRITORY = "NONUNITEDSTATESTERRITORY",
  NorthernIreland = "Northern Ireland (U.K.)",
  Norway = "Norway",
  Oman = "Oman",
  Pakistan = "Pakistan",
  Palau = "Palau",
  Palestinian_State = "Palestinian State*",
  Panama = "Panama",
  PapuaNewGuinea = "Papua New Guinea",
  Paraguay = "Paraguay",
  Peru = "Peru",
  Philippines = "Philippines",
  Poland = "Poland",
  Portugal = "Portugal",
  PuertoRico = "Puerto Rico",
  Qatar = "Qatar",
  Romania = "Romania",
  Russia = "Russia",
  Rwanda = "Rwanda",
  Samoa = "Samoa",
  SanMarino = "San Marino",
  SaudiArabia = "Saudi Arabia",
  ScotlandUK = "Scotland (U.K.)",
  Senegal = "Senegal",
  SerbiaAndMontenegro = "Serbia and Montenegro",
  Seychelles = "Seychelles",
  SierraLeone = "Sierra Leone",
  Singapore = "Singapore",
  Slovakia = "Slovakia",
  Slovenia = "Slovenia",
  SolomonIslands = "Solomon Islands",
  Somalia = "Somalia",
  SouthAfrica = "South Africa",
  Spain = "Spain",
  SriLanka = "Sri Lanka",
  StKittsandNevis = "St. Kitts and Nevis",
  StLucia = "St. Lucia",
  StMartin = "St. Martin",
  StVincentAndTheGrenadines = "St. Vincent and The Grenadines",
  STomndPripe = "S�Tom�nd Pr�ipe",
  Sudan = "Sudan",
  Suriname = "Suriname",
  Swaziland = "Swaziland",
  Sweden = "Sweden",
  Switzerland = "Switzerland",
  Syria = "Syria",
  Taiwan = "Taiwan",
  Tajikistan = "Tajikistan",
  Tanzania = "Tanzania",
  Test1 = "Test1",
  Thailand = "Thailand",
  TheNetherlands = "The Netherlands",
  ThePhilippines = "The Philippines",
  Togo = "Togo",
  Tonga = "Tonga",
  TrinidadAndTobago  = "Trinidad and Tobago",
  Tunisia = "Tunisia",
  Turkey = "Turkey",
  Turkmenistan = "Turkmenistan",
  Tuvalu = "Tuvalu",
  USVirginIslands = "U.S. Virgin Islands",
  Uganda = "Uganda",
  Ukraine = "Ukraine",
  UnitedArabEmirates = "United Arab Emirates",
  UnitedStates = "United States",
  Uruguay = "Uruguay",
  US_Virgin_Islands = "US Virgin Islands",
  Uzbekistan = "Uzbekistan", 
  Vanuatu = "Vanuatu",
  VaticanCity = "Vatican City (Holy See)",
  Venezuela = "Venezuela",
  Vietnam = "Vietnam",
  WalesUK = "Wales (U.K.)",
  WesternSahara = "Western Sahara*",
  Yemen = "Yemen", 
  Yugoslavia = "Yugoslavia", 
  Zaire = "Zaire",
  Zambia = "Zambia", 
  Zimbabwe = "Zimbabwe",
}