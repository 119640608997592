import React from 'react';

export const DragHandle = (props: { className?: string; white?: boolean }) => (
  <svg
    className={props.className || ''}
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 8.5C12 8.22656 11.7734 8 11.5 8H0.5C0.226563 8 0 8.22656 0 8.5V9.5C0 9.77344 0.226563 10 0.5 10H11.5C11.7734 10 12 9.77344 12 9.5V8.5ZM12 4.5C12 4.22656 11.7734 4 11.5 4H0.5C0.226563 4 0 4.22656 0 4.5V5.5C0 5.77344 0.226563 6 0.5 6H11.5C11.7734 6 12 5.77344 12 5.5V4.5ZM12 0.5C12 0.226562 11.7734 -2.38419e-07 11.5 -2.38419e-07H0.5C0.226563 -2.38419e-07 0 0.226562 0 0.5V1.5C0 1.77344 0.226563 2 0.5 2H11.5C11.7734 2 12 1.77344 12 1.5V0.5Z"
      fill={`${props.white ? '#FFFFFF' : '#556167'}`}
    />
  </svg>
);

export const LeftArrow = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0312 7.03125V9H3.84375L9.42188 14.625L8.01562 16.0312L0 8.01562L8.01562 0L9.42188 1.40625L3.84375 7.03125H16.0312Z"
      fill="#007BC1"
    />
  </svg>
);
